import AppLink from 'react-native-app-link'
import { Linking, Alert } from 'react-native'

export const OpenTelegram = (url) => {
    console.log('url', url)

    if (!url) url = 'tg://resolve?domain=kimiapp_bot'
    // https://github.com/FiberJW/react-native-app-link

    if (url.indexOf('http') !== -1) {
        window.open(url)
    } else {
        AppLink.maybeOpenURL(url, {
            appName: 'Telegram',
        })
    }
}

export const OpenPhone = async (phone) => {
    const url = `tel:${phone}`
    const supported = await Linking.canOpenURL(url)

    if (supported) {
        await Linking.openURL(url)
    } else {
        Alert.alert(`Ошибка`)
    }
}
