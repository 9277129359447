import React, { useState } from 'react'
import { View, Text } from 'react-native'
import { Formik } from 'formik'
import { connect } from 'react-redux'

import { SelectField } from '../Fields'
import api from '../../services/api'
import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'
import { getDateList, getTimeList } from '../../helpers/dataHelper'
import { ErrorAlert, SuccessAlert } from '../../helpers/apiMessageHelper'

const TicketsChangeForm = ({ onSubmit }) => {
    const [periodDate, setPeriodDate] = useState(null)
    const [periodTime, setPeriodTime] = useState(null)

    const dateList = getDateList()
    const timeList = getTimeList()

    let formValues = {
        start_period_date: '',
        end_period_date: '',
        start_period_time: '',
        end_period_time: '',
    }

    return (
        <View style={{ width: '100%' }}>
            <Text style={[s.h3, style.h3]}>
                Изменить дату и время оказания услуги
            </Text>

            <Formik initialValues={formValues} onSubmit={onSubmit}>
                {({ handleSubmit, values }) => (
                    <div>
                        <div className="row mb-4">
                            <div className="ticket-form-group col-12 col-md-6 col-xl-6">
                                <SelectField
                                    label="Дата оказания"
                                    selectedValue={periodDate}
                                    items={dateList}
                                    placeholder="Выберите дату оказания..."
                                    icon="calendar"
                                    onValueChange={(value) => {
                                        if (value) {
                                            values.start_period_date =
                                                value.split(' – ')[0]
                                            values.end_period_date =
                                                value.split(' – ')[1]
                                        } else {
                                            values.start_period_date = ''
                                            values.end_period_date = ''
                                        }
                                        setPeriodDate(value)
                                    }}
                                />
                            </div>
                            <div className="ticket-form-group col-12 col-md-6 col-xl-6">
                                <SelectField
                                    label="Время оказания"
                                    selectedValue={periodTime}
                                    items={timeList}
                                    placeholder="Выберите время оказания..."
                                    icon="time"
                                    onValueChange={(value) => {
                                        if (value) {
                                            values.start_period_time =
                                                value.split(' – ')[0] + ':00'
                                            values.end_period_time =
                                                value.split(' – ')[1] + ':00'
                                        } else {
                                            values.start_period_time = ''
                                            values.end_period_time = ''
                                        }
                                        setPeriodTime(value)
                                    }}
                                />
                            </div>
                            <div className="">
                                <button
                                    className="btn btn-lg btn-danger w-100"
                                    onClick={handleSubmit}
                                >
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </View>
    )
}

let mapStateToProps = () => ({})

let mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: (values) => {
            let newValues = {}

            for (var key in values) {
                if (values[key]) {
                    newValues[key] = values[key]
                }
            }

            newValues.ticket_status_id = ownProps?.data?.ticket_status_id

            api.patch(`tickets/${ownProps.ticketId}`, newValues)
                .then(() => {
                    SuccessAlert()
                    ownProps.onClose(true)
                })
                .catch((error) => {
                    ErrorAlert(error)
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketsChangeForm)
