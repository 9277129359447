import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { FaqViewContainer } from '../../containers/Faq/FaqViewContainer'

export const FaqViewPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader back={'/faq'} title="Справка" />
            <FaqViewContainer {...props} />
        </div>
    )
}
