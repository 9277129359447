import React from 'react'
import { OpenPhone } from '../../helpers/linkHelper'
import { TimingItem } from '../../components/Elements/TimingItem'

export const ManagmentView = ({ data, actualAddress, timings }) => {
    return (
        <div>
            <div className="row area-block mb-3">
                <div className="col-12 col-md-6 col-xl-6">
                    {data.contact_phone ? (
                        <div>
                            <h5>Телефон:</h5>
                            <p>
                                <i className="icn icn-phone-danger"></i>
                                <a
                                    onClick={() =>
                                        OpenPhone(data.contact_phone)
                                    }
                                >
                                    {data.contact_phone}
                                </a>
                            </p>
                        </div>
                    ) : null}

                    {data.contact_phone_fax ? (
                        <div>
                            <h5>Факс:</h5>
                            <p>
                                <i className="icn icn-phone-danger"></i>
                                <span>{data.contact_phone_fax}</span>
                            </p>
                        </div>
                    ) : null}

                    {actualAddress && (
                        <div>
                            <h5>Адрес:</h5>
                            <p>
                                <i className="icn icn-pointer-2-danger"></i>
                                {actualAddress}
                            </p>
                        </div>
                    )}
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                    {timings && timings.length ? (
                        <div>
                            {timings.map((item) => (
                                <TimingItem key={item.id} item={item} />
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
