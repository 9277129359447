import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    SUB_QUANTITY,
    ADD_QUANTITY,
    CHANGE_QUANTITY,
    EMPTY_CART,
    SET_CART,
    GET_CART,
} from '../types/CartTypes'

import api from '../../services/api'
import { ErrorAlert, SuccessAlert } from '../../helpers/apiMessageHelper'
import { store } from '../../App'

export const addToCart = (payload) => {
    api.post(`me/baskets`, {
        service_option_id: payload.id,
        unit_count: 1,
    })
        .catch((error) => {
            ErrorAlert(error)
        })
        .then((resp) => {
            if (resp) {
                SuccessAlert('Услуга добавлена в корзину')
            }
            getCart()
        })

    return {
        type: ADD_TO_CART,
        payload,
    }
}
export const removeFromCart = (payload) => {
    removeFromCartApi(payload)

    return {
        type: REMOVE_FROM_CART,
        payload,
    }
}
export const subtractQuantity = (payload) => {
    return {
        type: SUB_QUANTITY,
        payload,
    }
}
export const addQuantity = (payload) => {
    return {
        type: ADD_QUANTITY,
        payload,
    }
}
export const changeQuantity = (payload, quantity) => {
    if (payload.id) {
        api.patch(`me/baskets/${payload.id}`, {
            unit_count: parseInt(quantity),
        })
            .catch((error) => {
                ErrorAlert(error)
            })
            .then(() => {
                getCart()
            })
    }

    return {
        type: CHANGE_QUANTITY,
        payload,
        quantity,
    }
}
export const emptyCart = () => {
    return {
        type: EMPTY_CART,
    }
}
export const setCart = (payload) => {
    return {
        type: SET_CART,
        payload,
    }
}
export const getCart = () => {
    getCartApi()

    return {
        type: GET_CART,
    }
}

const getCartApi = () => {
    api.get(`me/baskets`, {
        extend: 'service_option,service_option.service,service_option.service.preview_file',
    }).then(({ data }) => {
        store.dispatch(setCart(data))
    })
}

const removeFromCartApi = (payload) => {
    const basket = store.getState().cart?.basket

    if (basket && basket.length) {
        let items = basket.filter(
            (item) => item.service_option.service_id == payload.id
        )

        if (items.length) {
            api.delete(`me/baskets/${items[0].id}`)
                .then(getCart)
                .catch((error) => ErrorAlert(error))
        }
    }
}
