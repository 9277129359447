import React from 'react'
import { View } from 'react-native'
import { Formik } from 'formik'
import { SearchField } from '../Fields'

export const Search = ({ placeholder, onSubmit }) => (
    <Formik initialValues={{ title: '' }} onSubmit={onSubmit}>
        {({ handleChange, handleBlur, handleSubmit, values }) => (
            <View>
                <SearchField
                    placeholder={placeholder}
                    value={values.title}
                    onChangeText={handleChange('title')}
                    onBlur={handleBlur('title')}
                    onSubmitEditing={handleSubmit}
                />
            </View>
        )}
    </Formik>
)
