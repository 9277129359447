import React from 'react'
import { TimingItem } from '../../components/Elements/TimingItem'
import { NavLink } from 'react-router-dom'

export const HouseView = ({ area, areaAddress, worktime }) => {
    return (
        <div>
            <h1>{area.number}</h1>

            <div className="row area-block mb-3">
                <div className="col-12 col-md-6 col-xl-4">
                    <h5>Телефон:</h5>
                    <p>
                        <i className="icn icn-phone-danger"></i>
                        <a href={`tel:${area.contact_phone}`}>
                            {area.contact_phone}
                        </a>
                    </p>

                    <h5>Адрес:</h5>
                    <p>
                        <i className="icn icn-pointer-2-danger"></i>
                        {areaAddress}
                    </p>

                    <h5>E-mail:</h5>
                    <p className="mb-4">
                        <i className="icn icn-mail-danger"></i>
                        <a href={`mailto:${area.contact_email}`}>
                            {area.contact_email}
                        </a>
                    </p>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                    {worktime && worktime.length ? (
                        <div>
                            {worktime.map((item) => (
                                <TimingItem key={item.id} item={item} />
                            ))}
                        </div>
                    ) : null}

                    <h5>Начальник участка:</h5>
                    <p>{area.chief_name}</p>
                </div>
                {/* <div className="col-6 col-xxl-4">
                    <img src="/images/map-example.jpg" />
                </div> */}
            </div>

            <NavLink
                className="btn btn-lg btn-outline-danger"
                to={`/area/${area.id}/houses`}
            >
                Дома в обслуживании
            </NavLink>
        </div>
    )
}
