import React, { useState } from 'react'
import { View, Pressable, StyleSheet, Image } from 'react-native'

import starOutlineIcon from '../../assets/icons/star_outline.png'
import starFillIcon from '../../assets/icons/star_fill.png'

export const StarSelect = ({ count = 5, value = 0, onChange }) => {
    const [star, setStar] = useState(value)
    const stars = Array.from(Array(count).keys())

    const onPress = (val) => {
        val = val + 1
        if (val === star) val = 0

        setStar(val)

        if (onChange) onChange(val)
    }

    return (
        <View style={styles.base}>
            {stars.map((item) => {
                const sourceImage =
                    parseInt(item + 1) <= star ? starFillIcon : starOutlineIcon
                return (
                    <Pressable
                        onPress={() => onPress(item)}
                        key={item}
                        style={styles.link}
                    >
                        <Image style={styles.icon} source={sourceImage} />
                    </Pressable>
                )
            })}
        </View>
    )
}

const styles = StyleSheet.create({
    base: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    icon: {
        width: 32,
        height: 32,
    },
    link: {
        marginHorizontal: 6,
    },
})
