import React from 'react'
// import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { ServicesOrderContainer } from '../../containers/Services/ServicesOrderContainer'

export const ServicesOrderPage = (props) => {
    return (
        <div className="white-box">
            <ServicesOrderContainer {...props} />
        </div>
    )
}
