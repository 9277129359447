import React from 'react'
import { ActivityIndicator } from 'react-native'
import { connect } from 'react-redux'
import { InfoView } from '../../components/Contacts/InfoView'
import { DataAddressToString } from '../../helpers/dataHelper'

const Container = ({ company }) => {
    if (!company) return <ActivityIndicator color="#d21100" />

    const data = company

    const legalAddress = DataAddressToString([
        data.legal_address_city,
        data.legal_address_street,
        data.legal_address_house,
        data.legal_address_building,
        data.legal_address_structure,
        data.legal_address_office,
    ])

    const mailingAddress = DataAddressToString([
        data.mailing_address_city,
        data.mailing_address_street,
        data.mailing_address_house,
        data.mailing_address_building,
        data.mailing_address_structure,
        data.mailing_address_office,
    ])

    return (
        <InfoView
            data={data}
            legalAddress={legalAddress}
            mailingAddress={mailingAddress}
        />
    )
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
    city_id: state.user.city_id,
    company: state.user.company,
})

let mapDispatchToProps = () => {
    return {}
}

export const InfoContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
