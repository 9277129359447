import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { useHistory } from 'react-router'

export const ProfileBlock = ({ profile, onLogout }) => {
    const history = useHistory()

    return (
        <div className="row profile-block">
            <div className="col">
                <div className="profile-block-info">
                    <i className="icn icn-user me-2"></i>
                    <div>
                        {profile.name}
                        <br />
                        <span className="opacity-70">{profile.phone}</span>
                        <br />
                        <span className="opacity-70">{profile.email}</span>
                    </div>
                </div>
            </div>
            <div className="col-2 text-end">
                <NavLink to="/profile/edit">
                    <i className="icn icn-setting"></i>
                </NavLink>
            </div>
            <div className="col-2 text-end">
                <a
                    onClick={async () => {
                        await onLogout()
                        history.push(`/auth/login`)
                    }}
                >
                    <i className="icn icn-logout"></i>
                </a>
            </div>
        </div>
    )
}
