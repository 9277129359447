import {
    SET_STATUS_LIST,
    GET_STATUS_LIST,
    SET_ORDER_STATUS_LIST,
    GET_ORDER_STATUS_LIST,
} from '../types/StatusTypes'

import api from '../../services/api'
// import { ErrorAlert, SuccessAlert } from '../../helpers/apiMessageHelper'
import { store } from '../../App'

export const setStatusList = (payload) => {
    return {
        type: SET_STATUS_LIST,
        payload,
    }
}

export const getStatusList = () => {
    api.get(`/collection/ticket-statuses`).then(({ data }) => {
        store.dispatch(setStatusList(data.items))
    })

    return {
        type: GET_STATUS_LIST,
    }
}

export const setOrderStatusList = (payload) => {
    return {
        type: SET_ORDER_STATUS_LIST,
        payload,
    }
}

export const getOrderStatusList = () => {
    api.get(`/collection/order-statuses`).then(({ data }) => {
        store.dispatch(setOrderStatusList(data.items))
    })

    return {
        type: GET_ORDER_STATUS_LIST,
    }
}
