import React from 'react'

const ListItem = ({ title, icon, children }) => {
    return (
        <div>
            <h5>{title}:</h5>
            <p>
                {icon && <i className={`icn icn-${icon}-danger`}></i>}
                <span>{children}</span>
            </p>
        </div>
    )
}

export const InfoView = ({ data, legalAddress }) => {
    return (
        <div>
            <h4 className="mb-4">{data.organizer_name}</h4>

            <div className="row area-block mb-3">
                <div className="col-12 col-md-6 col-xl-4">
                    {!!legalAddress && (
                        <div className="mb-3">
                            <ListItem title="Юридический адрес" icon="pointer">
                                {legalAddress}
                            </ListItem>
                        </div>
                    )}

                    {data.mailing_address && (
                        <div className="mb-3">
                            <ListItem title="Почтовый адрес" icon="pointer">
                                {data.mailing_address}
                            </ListItem>
                        </div>
                    )}

                    {data.admin_email && (
                        <div className="mb-3">
                            <ListItem title="Почта">
                                {data.admin_email}
                            </ListItem>
                        </div>
                    )}

                    {data.admin_phone && (
                        <div className="mb-3">
                            <ListItem title="Телефон администратора">
                                {data.admin_phone}
                            </ListItem>
                        </div>
                    )}

                    <div className="mb-3">
                        <ListItem title="Генеральный директор">
                            {data.chief_name}
                        </ListItem>
                    </div>

                    <div className="mb-3">
                        <ListItem title="Телефон директора">
                            {data.director_phone}
                        </ListItem>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                    <div className="mb-3">
                        <ListItem title="ИНН">{data.organizer_inn}</ListItem>
                    </div>
                    <div className="mb-3">
                        <ListItem title="КПП">
                            {data.organizer_account_number}
                        </ListItem>
                    </div>
                    <div className="mb-3">
                        <ListItem title="ОГРН">{data.organizer_orgn}</ListItem>
                    </div>
                </div>
            </div>

            {data.km_title && data.km_text ? (
                <div className="mb-3">
                    <h4 className="mb-4">{data.km_title}</h4>
                    <div style={{ whiteSpace: 'pre-wrap' }}>{data.km_text}</div>
                </div>
            ) : null}

            {data.company_detail && data.company_detail.length ? (
                <ul className="custom-list mb-4">
                    {data.company_detail.map((item) => (
                        <li className="custom-list-item" key={item.id}>
                            <a
                                href={item.contact_url}
                                target="_blank"
                                className="custom-list-link"
                                rel="noreferrer"
                            >
                                {item.contact_name}
                                <i className="fa-solid fa-up-right-from-square"></i>
                            </a>
                        </li>
                    ))}
                </ul>
            ) : null}

            <ul className="custom-list">
                {data.document_link && (
                    <li className="custom-list-item">
                        <a
                            href={data.document_link}
                            target="_blank"
                            className="custom-list-link"
                            rel="noreferrer"
                        >
                            Документы
                            <i className="fa-solid fa-up-right-from-square"></i>
                        </a>
                    </li>
                )}

                {data.license_link && (
                    <li className="custom-list-item">
                        <a
                            href={data.license_link}
                            className="custom-list-link"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Лицензия
                            <i className="fa-solid fa-up-right-from-square"></i>
                        </a>
                    </li>
                )}
            </ul>
        </div>
    )
}
