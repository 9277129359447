import {
    AUTH_USER,
    REGISTRATION_USER,
    RECOVERY_USER,
    SET_PROFILE,
    LOGOUT_USER,
    SET_CITY,
    SET_CITY_LIST,
    SET_COMPANY,
    SET_SETTINGS,
} from './types/UserTypes'

import AsyncStorage from '../services/storage'

const INITIAL_STATE = {
    access_token: null,
    remember_token: null,
    registration_token: null,
    registration_phone: null,
    profile: {},
    company: {},
    cities: [],
    city: null, // Казань
    city_id: null, // Казань
    settings: {},
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REGISTRATION_USER:
            if (action.payload.registration_phone)
                return {
                    ...state,
                    registration_token: action.payload.registration_token,
                    registration_phone: action.payload.registration_phone,
                }

            return {
                ...state,
                registration_token: action.payload.registration_token,
            }
        case RECOVERY_USER:
            return {
                ...state,
                remember_token: action.payload,
            }
        case AUTH_USER:
            // var { access_token, refresh_token } = action.payload

            // AsyncStorage.setItem('@access_token', access_token)
            // AsyncStorage.setItem('@refresh_token', refresh_token)

            var access_token = action?.payload?.access_token
            var refresh_token = action?.payload?.refresh_token

            AsyncStorage.setItem(
                '@access_token',
                access_token ? access_token : null
            )
            AsyncStorage.setItem(
                '@refresh_token',
                refresh_token ? refresh_token : null
            )

            return {
                ...state,
                access_token: action.payload,
            }
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload,
            }
        case SET_CITY:
            return {
                ...state,
                city_id: action.payload,
                city: action.payload,
            }
        case SET_CITY_LIST:
            return {
                ...state,
                cities: action.payload,
            }
        case SET_COMPANY:
            return {
                ...state,
                company: action.payload,
            }
        case LOGOUT_USER:
            AsyncStorage.removeItem('@access_token')
            AsyncStorage.removeItem('@refresh_token')

            return {
                ...state,
                access_token: null,
                profile: {},
            }
        case SET_SETTINGS:
            var list = action.payload
            var listNew = {}

            if (list && list.length) {
                list.map((l) => {
                    listNew[l.slug] = l.value
                })
            }

            return {
                ...state,
                settings: listNew,
            }
        default:
            return state
    }
}

export default userReducer
