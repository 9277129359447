import React, { useState } from 'react'
import { connect } from 'react-redux'

import moment from 'moment'
// import 'moment/min/moment-with-locales'
import 'moment/locale/ru.js'
moment.locale('ru')

import api from '../../services/api'
import {
    DataURIToBlob,
    getDateList,
    getTimeList,
} from '../../helpers/dataHelper'
import { ErrorAlert } from '../../helpers/apiMessageHelper'
import { TicketsForm } from '../../components/Tickets/TicketsForm'
import { Platform } from 'react-native'

const countList = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
]

const dateList = getDateList()
const timeList = getTimeList()

const uploadTicketsFiles = async (ticketId, uploadForm) => {
    const responseUpload = await api.upload(uploadForm)
    const responseFiles = responseUpload?.data?.data

    if (responseFiles && responseFiles.length) {
        responseFiles.map(async (f) => {
            await api.post(`/tickets/${ticketId}/files/${f.id}`)
        })
    }
}

const Container = ({
    order,
    service_id,
    service_option_id,
    services,
    profile,
    city_id,
    onSubmit,
}) => {
    let formValues = {
        id: null,
        unit_count: null,
        start_period_date: '',
        end_period_date: '',
        start_period_time: '',
        end_period_time: '',
        address_id: null,
        contact_name: '',
        contact_phone: '',
        description: '',
        address: '',
        promocode: '',
        city_id: city_id,
    }

    if (order) {
        // Заполнение полей заявки
        for (var key in formValues) {
            if (key in order) {
                formValues[key] = order[key]
            }
        }
    }

    if (service_option_id) formValues.service_option_id = service_option_id
    if (service_id) formValues.service_id = service_id

    if (services) {
        formValues.items = services.map((s) => s.id)
        console.log('formValues', formValues)
        // formValues.items = services.map((s) => {
        //     return {
        //         service_option_id: s.service_option_id
        //             ? s.service_option_id
        //             : s.id,
        //         unit_count: s.unit_count ? s.unit_count : s.count,
        //     }
        // })
    }

    const { addresses, name, phone } = profile

    const [periodDate, setPeriodDate] = useState(null)
    const [periodTime, setPeriodTime] = useState(null)
    const [files, setFiles] = useState([])
    const [addContact, setAddContact] = useState(false)

    const accountsList = addresses
        ? addresses.map((item) => {
              return {
                  label: item.address,
                  value: item.id.toString(),
              }
          })
        : []

    return (
        <TicketsForm
            id={order ? order.id : null}
            name={name}
            phone={phone}
            onSubmit={onSubmit}
            formValues={formValues}
            files={files}
            setFiles={setFiles}
            periodDate={periodDate}
            setPeriodDate={setPeriodDate}
            periodTime={periodTime}
            setPeriodTime={setPeriodTime}
            accountsList={accountsList}
            addContact={addContact}
            setAddContact={setAddContact}
            countList={countList}
            timeList={timeList}
            dateList={dateList}
            hideQuantity={services || order ? true : false}
        />
    )
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
    city_id: state.user.city_id,
})

let mapDispatchToProps = () => {
    return {
        onSubmit: async (values, files, contactProfile, onSubmitSuccess) => {
            let uploadForm = new FormData()
            let hasFiles = false

            if (files && files.length) {
                await files.map((file, index) => {
                    let fileLocalUri = DataURIToBlob(file.localUri)

                    if (Platform.OS === 'ios')
                        fileLocalUri = {
                            uri: file.localUri.replace('file://', ''),
                            type: 'image/jpg',
                            name: `image_${index}.jpg`,
                        }

                    if (Platform.OS === 'android')
                        fileLocalUri = {
                            uri: file.localUri,
                            type: 'image/jpg',
                            name: `image_${index}.jpg`,
                        }

                    uploadForm.append(`file_key_${index + 1}`, fileLocalUri)
                    hasFiles = true
                })
            }

            if (!values.contact_name) {
                values.contact_name = contactProfile.contact_name
            }

            if (!values.contact_phone) {
                values.contact_phone = contactProfile.contact_phone
            }

            if (values.id) {
                api.patch(`tickets/${values.id}`, values)
                    .then(async (response) => {
                        let ticketId = response?.data?.id

                        if (ticketId) {
                            if (hasFiles) {
                                uploadTicketsFiles(ticketId, uploadForm)
                            }

                            onSubmitSuccess()
                        } else {
                            ErrorAlert()
                        }
                    })
                    .catch((error) => {
                        ErrorAlert(error)
                    })
            } else {
                const apiPath =
                    values.items && values.items.length
                        ? `me/baskets/create-order`
                        : `tickets`

                api.post(apiPath, values)
                    .then(async (response) => {
                        let ticketId = response?.data?.id

                        if (ticketId) {
                            if (hasFiles) {
                                uploadTicketsFiles(ticketId, uploadForm)
                            }

                            onSubmitSuccess()
                        } else {
                            ErrorAlert()
                        }
                    })
                    .catch((error) => {
                        ErrorAlert(error)
                    })
            }
        },
    }
}

export const TicketsFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
