import React from 'react'
import { View, Text } from 'react-native'
import { Formik } from 'formik'
import { connect } from 'react-redux'

import { TextareaField } from '../Fields'
import api from '../../services/api'
import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'
import { Btn } from '../Btn'

const ReviewsForm = ({ ticketId, cityId, onSubmit, profile, rate = 1 }) => {
    let formValues = {
        city_id: cityId ? cityId : 4,
        source_id: 1,
        contact_name: profile.name,
        contact_phone: profile.phone,
        text: '',
        ticket_id: ticketId,
        rate: rate ? rate : 1,
    }

    return (
        <View style={{ width: '100%' }}>
            <Text style={[s.h3, style.h3]}>Оставить отзыв</Text>

            <Formik initialValues={formValues} onSubmit={onSubmit}>
                {({ handleChange, handleBlur, handleSubmit, values }) => (
                    <View>
                        <TextareaField
                            label="Текст отзыва"
                            value={values.text}
                            onChangeText={handleChange('text')}
                            onBlur={handleBlur('text')}
                        />
                        <Btn onPress={handleSubmit} color="btnDanger">
                            Отправить
                        </Btn>
                    </View>
                )}
            </Formik>
        </View>
    )
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
    cityId: state.user.city,
})

let mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: (values) => {
            api.post(`tickets/${ownProps.ticketId}/reviews`, values).finally(
                () => {
                    ownProps.onClose(true)
                }
            )
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsForm)
