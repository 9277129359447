import React from 'react'
import { connect } from 'react-redux'
import { IndexCard, IndexCardDanger } from '../../components/Index/IndexCard'
import moment from 'moment'

const Container = ({ type, profile }) => {
    const accountPayment = profile.default_account
    let deadline = moment().format('DD MMMM YYYY')

    if (type === 'danger')
        return (
            <IndexCardDanger
                title="Оплата ЖКХ"
                text={
                    accountPayment
                        ? accountPayment.address
                        : 'У вас нет задолжностей'
                }
                cost="0 ₽"
            />
        )
    return (
        <IndexCard
            title="Подать показания счетчиков"
            text="Необходимо подать до"
            caption={deadline}
        />
    )
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
})

let mapDispatchToProps = () => {
    return {}
}

export const IndexCardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
