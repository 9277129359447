import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    ADD_QUANTITY,
    SUB_QUANTITY,
    CHANGE_QUANTITY,
    EMPTY_CART,
    SET_CART,
} from './types/CartTypes'

const MAX_COUNT = 5

const INITIAL_STATE = {
    services: [],
    countAll: 0,
    sumAll: 0,
    basket: [],
}

const calculateSum = (services) => {
    return services.reduce((sum, el) => sum + el.price * el.count, 0)
}

const calculateCount = (services) => {
    return services.reduce((countAll, el) => countAll + el.count, 0)
}

const CartReducer = (state = INITIAL_STATE, action) => {
    const { payload } = action
    let { services } = state
    var indx

    switch (action.type) {
        case ADD_TO_CART:
            indx = services.findIndex((el) => el.id === payload.id)

            if (indx === -1) {
                services.push({ ...payload, count: payload.count ?? 1 })
            } else {
                if (services[indx].count < MAX_COUNT) services[indx].count++
            }

            return {
                ...state,
                services,
                sumAll: calculateSum(services),
                countAll: calculateCount(services),
            }
        case REMOVE_FROM_CART:
            indx = services.findIndex((el) => el.id === payload.id)

            if (indx > -1) {
                services.splice(indx, 1)
            }

            // api.post(`me/baskets`, {
            //     service_option_id: payload.id,
            //     unit_count: 1,
            // }).catch((error) => {
            //     ErrorAlert(error)
            // })

            return {
                ...state,
                services,
                sumAll: calculateSum(services),
                countAll: calculateCount(services),
            }
        case ADD_QUANTITY:
            services = state.services.map((el) =>
                el.id === payload.id ? { ...el, count: el.count + 1 } : el
            )

            return {
                ...state,
                services,
                sumAll: calculateSum(services),
                countAll: calculateCount(services),
            }
        case SUB_QUANTITY:
            services = state.services.map((el) =>
                el.id === payload.id
                    ? {
                          ...payload,
                          count: el.count !== 1 ? el.count - 1 : 1,
                      }
                    : el
            )

            return {
                ...state,
                services,
                sumAll: calculateSum(services),
                countAll: calculateCount(services),
            }
        case CHANGE_QUANTITY:
            if (action.quantity && action.quantity <= MAX_COUNT)
                services = state.services.map((el) =>
                    el.id === payload.id
                        ? {
                              ...payload,
                              count: parseInt(action.quantity),
                          }
                        : el
                )
            return {
                ...state,
                services,
                sumAll: calculateSum(services),
                countAll: calculateCount(services),
            }
        case EMPTY_CART:
            return {
                ...state,
                services: [],
                sumAll: 0,
                countAll: 0,
            }
        case SET_CART:
            return {
                ...state,
                basket: payload,
                countAll: payload.reduce(
                    (count, el) => count + el.unit_count,
                    0
                ),
                sumAll: payload.reduce(
                    (sum, el) => sum + el.service_option.price * el.unit_count,
                    0
                ),
            }
        default:
            return state
    }
}
export default CartReducer
