import * as React from 'react'
import { connect } from 'react-redux'
import { logoutUser } from '../../store/actions/UserActions'
import api from '../../services/api'
import { ProfileBlock } from '../../components/Profile/ProfileBlock'

const Container = (props) => {
    return <ProfileBlock {...props} />
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
})

let mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => {
            dispatch(logoutUser())
            api.logout()
        },
    }
}

export const ProfileBlockContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
