import React, { useState, useEffect } from 'react'
import { ActivityIndicator } from 'react-native'
import { FaqView } from '../../components/Faq/FaqView'
import api from '../../services/api'

export const FaqViewContainer = ({ match, route }) => {
    const [response, setResponse] = useState({})
    const { id } = match ? match.params : route.params

    const fetch = () => {
        api.fetchById(`faqs`, id, {
            // 'extend' : 'preview_file'
        }).then((response) => {
            setResponse(response.data)
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    const { data } = response

    if (!data) return <ActivityIndicator color="#d21100" />
    return <FaqView data={data} />
}
