import React, { useState, useEffect } from 'react'
import { MarketplaceBlock } from '../../components/Marketplace/MarketplaceBlock'
import api from '../../services/api'
import { connect } from 'react-redux'

const PAGE_SIZE = 6 // eslint-disable-line

const Container = ({ city_id }) => {
    const [response, setResponse] = useState({})

    const fetch = (filter = {}) => {
        api.fetch(`service-categories`, {
            'paginator[limit]': PAGE_SIZE,
            'paginator[page]': 1,
            extend: 'preview_file,service_count',
            ...filter,
            // ...cityFilter,
        }).then((response) => {
            setResponse(response.data)
        })
    }

    // useEffect(() => {
    //     fetch()
    // }, [])

    useEffect(() => {
        if (city_id)
            fetch({
                'filter[services.city_id]': `EQ(${city_id})`,
            })
    }, [city_id])

    const { items } = response

    return <MarketplaceBlock items={items} />
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
    company: state.user.company,
    city_id: state.user.city,
    cities: state.user.cities,
})

let mapDispatchToProps = () => {
    return {}
}

export const MarketplaceBlockContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
