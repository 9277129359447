import React from 'react'
import { View } from 'react-native'
import { Formik } from 'formik'
import { InputField, InputPassword } from '../Fields'
import { Btn } from '../Btn'
import { useHistory } from 'react-router'

export const LoginForm = ({ formValues, onSubmit }) => {
    const history = useHistory()

    const onSubmitSuccess = () => {
        history.push(`/`)
    }

    return (
        <Formik
            initialValues={formValues}
            onSubmit={(formData) => onSubmit(formData, onSubmitSuccess)}
        >
            {({ handleChange, handleBlur, handleSubmit, values }) => (
                <View>
                    <InputField
                        label="Телефон"
                        placeholder="+7 ( 9 _ _ ) _ _ _ - _ _ - _ _"
                        value={values.username}
                        onChangeText={handleChange('username')}
                        onBlur={handleBlur('username')}
                        type="phone-pad"
                    />

                    <InputPassword
                        label="Пароль"
                        placeholder="••••••"
                        value={values.password}
                        onChangeText={handleChange('password')}
                        onBlur={handleBlur('password')}
                    />

                    <Btn onPress={handleSubmit} color="btnDanger">
                        Войти
                    </Btn>
                </View>
            )}
        </Formik>
    )
}
