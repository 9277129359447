import React from 'react'
import moment from 'moment'

export const NewsView = ({ data }) => {
    return (
        <div className="mb-5">
            {data.preview_file && (
                <img src={data.preview_file.path} className="news-view-img" />
            )}

            <p>{moment(data.created_at).format('DD.MM.YYYY')}</p>
            <h4 className="mb-4">{data.title}</h4>
            <p>{data.text}</p>
        </div>
    )
}
