import * as React from 'react'

export const FaqCard = ({ item, onPress }) => (
    <div className="faq-item">
        <a className="faq-item-link" onClick={onPress}>
            {item.title}
            <i className="icn icn-arrow-right-danger"></i>
        </a>
    </div>
)
