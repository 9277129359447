import React, { useState } from 'react'
import { AnimateCounter } from '../Commons/AnimateCounter'
import ScrollAnimation from 'react-animate-on-scroll'

export const IndexCard = ({ title, text, cost, caption }) => {
    return (
        <div className="index-card bg-dark bg-opacity-90">
            {/* <div className="index-card-image">
            <img src={img_index_block2} alt="" />
        </div> */}
            <ScrollAnimation animateIn="fadeIn">
                <div className="index-card-icon bg-dark text-white">
                    <ion-icon
                        name="speedometer-outline"
                        size="large"
                    ></ion-icon>
                </div>

                {title && <div className="index-card-title">{title}</div>}
                {text && <div className="index-card-text">{text}</div>}
                {cost && (
                    <div className="index-card-cost">
                        {/* {cost} */}
                        <AnimateCounter number={`3600`} duration="4" /> ₽
                    </div>
                )}
                {caption && <div className="text-danger">{caption}</div>}
            </ScrollAnimation>
        </div>
    )
}

export const IndexCardDanger = ({ title, text, cost, caption }) => {
    const [showDigits, isShowDigits] = useState(false)

    return (
        <div className="index-card bg-danger bg-opacity-90">
            {/* <div className="index-card-image">
            <img src={img_index_block1} alt="" />
        </div> */}
            <ScrollAnimation
                animateIn="fadeIn"
                afterAnimatedIn={function afterAnimatedIn() {
                    isShowDigits(true)
                }}
            >
                <div className="index-card-icon bg-danger text-white">
                    <ion-icon name="wallet-outline" size="large"></ion-icon>
                </div>

                {title && <div className="index-card-title">{title}</div>}
                {text && <div className="index-card-text">{text}</div>}
                {cost && (
                    <div className="index-card-cost">
                        {/* {cost} */}
                        {showDigits && (
                            <AnimateCounter number={cost} duration="3" />
                        )}
                        {'  ₽'}
                    </div>
                )}
                {caption && <div className="text-danger">{caption}</div>}
            </ScrollAnimation>
        </div>
    )
}
