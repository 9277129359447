import React, { useState, useEffect } from 'react'
import {
    Image,
    View,
    Text,
    StyleSheet,
    ScrollView,
    ActivityIndicator,
} from 'react-native'
import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'
import api from '../../services/api'

export const TicketsFiles = ({ ticketId }) => {
    const [response, setResponse] = useState({})

    const fetch = () => {
        api.get(`/tickets/${ticketId}/files`, {
            extend: 'service,ticket_status',
        }).then((response) => {
            setResponse(response.data)
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    const { items, _meta } = response

    if (!items) return <ActivityIndicator color="#d21100" />

    return (
        <View>
            <Text style={[s.mb2, s.h5, style.h5]}>
                Приложено {_meta.totalCount} фотографии
            </Text>

            <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
            >
                {items.map((item, index) => {
                    return (
                        <View key={index} style={s.pr2}>
                            <Image
                                key={index}
                                source={{ uri: item.path }}
                                style={styles.image}
                            />
                        </View>
                    )
                })}
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    image: {
        width: 104,
        height: 104,
        borderRadius: 24,
    },
})
