import React, { useState, useEffect } from 'react'
import api from '../../services/api'
import { MarketplaceList } from '../../components/Marketplace/MarketplaceList'
import { ActivityIndicator } from 'react-native'
import { connect } from 'react-redux'

import getEnv from '../../helpers/envHelper'
const { REACT_APP_PAGE_SIZE } = getEnv()

const Container = ({ match, city_id, route }) => {
    const [category, setCategory] = useState(null)
    const [response, setResponse] = useState({})
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const { id } = match ? match.params : route.params
    const cityFilter = city_id ? { 'filter[city_id]': `EQ(${city_id})` } : {}

    const fetch = (filter = {}, isLoadMore = false) => {
        api.fetchById(`service-categories`, id).then((fetchResponse) => {
            setCategory(fetchResponse.data)
        })

        api.fetch(`services`, {
            'paginator[limit]': REACT_APP_PAGE_SIZE,
            'paginator[page]': page,
            extend: 'preview_file,unit_type,rate,rate_count',
            ...filter,
            ...cityFilter,
        })
            .then((fetchResponse) => {
                let responseData = fetchResponse.data,
                    responseItems = response.items ? response.items : []

                if (isLoadMore)
                    responseData.items = responseItems.concat(
                        responseData.items
                    )

                // console.log('fetchResponse', fetchResponse.data._meta)

                setResponse(responseData)
                // setResponse(fetchResponse.data)
                setPageCount(fetchResponse.data._meta.pageCount)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const loadMore = async () => {
        if (!isLoading) {
            let nextPage = page + 1
            if (nextPage > pageCount) return false

            setIsLoading(true)
            setPage(nextPage)

            await fetch(
                {
                    'paginator[page]': nextPage,
                    'filter[service_category_id]': `EQ(${id})`,
                    'filter[city_id]': `EQ(${city_id})`,
                },
                true
            )
        }
    }

    // const onSearch = (formData) => {
    //     setPage(1)
    //     fetch({
    //         'filter[service_category_id]': `EQ(${id})`,
    //         'filter[title]': `LIKE(${formData.title})`,
    //         'filter[city_id]': `EQ(${city_id})`,
    //     })
    // }

    const onSearch = (formData) => {
        setPage(1)

        if (formData && formData.title)
            fetch({
                'paginator[page]': 1,
                'filter[title]': `LIKE(${formData.title})`,
                'filter[service_category_id]': `EQ(${id})`,
                'filter[city_id]': `EQ(${city_id})`,
            })
        else
            fetch({
                'paginator[page]': 1,
                'filter[service_category_id]': `EQ(${id})`,
                'filter[city_id]': `EQ(${city_id})`,
            })
    }

    // useEffect(() => {
    //     fetch({
    //         'filter[service_category_id]': `EQ(${id})`,
    //     })
    // }, [])

    useEffect(() => {
        if (city_id)
            fetch({
                'filter[service_category_id]': `EQ(${id})`,
                'filter[city_id]': `EQ(${city_id})`,
            })
    }, [city_id])

    if (!category) return <ActivityIndicator color="#d21100" />

    return (
        <MarketplaceList
            onSearch={onSearch}
            response={response}
            category={category.data}
            loadMore={loadMore}
        />
    )
}

let mapStateToProps = (state) => ({
    city_id: state.user.city,
})

let mapDispatchToProps = () => {
    return {}
}

export const ServicesCategoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
