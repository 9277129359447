import React from 'react'
import { connect } from 'react-redux'
import { PointsBlock } from '../../components/Points/PointsBlock'

const Container = ({ profile }) => {
    const percent = 60
    const bonusNeed = 100

    return (
        <PointsBlock
            profile={profile}
            percent={percent}
            bonusNeed={bonusNeed}
        />
    )
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
})

let mapDispatchToProps = () => {
    return {}
}

export const PointsBlockContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
