import React from 'react'
import { Formik } from 'formik'

import moment from 'moment'
// import 'moment/min/moment-with-locales'
import 'moment/locale/ru.js'

import {
    InputField,
    SelectField,
    SwitchField,
    TextareaField,
    GalleryField,
} from '../Fields'
import { useHistory } from 'react-router'
moment.locale('ru')

export const TicketsForm = ({
    id,
    name,
    phone,
    onSubmit,
    formValues,
    files,
    setFiles,
    periodDate,
    setPeriodDate,
    periodTime,
    setPeriodTime,
    accountsList,
    addContact,
    setAddContact,
    countList,
    timeList,
    dateList,
    hideQuantity,
}) => {
    const history = useHistory()

    const onSubmitSuccess = () => {
        history.push(`/tickets`)
    }

    return (
        <div>
            <Formik
                initialValues={formValues}
                onSubmit={(values) =>
                    onSubmit(
                        {
                            ...values,
                            items: formValues?.items,
                        },
                        files,
                        {
                            contact_name: name,
                            contact_phone: phone,
                        },
                        onSubmitSuccess
                    )
                }
            >
                {({ handleChange, handleBlur, handleSubmit, values }) => (
                    <div className="ticket-form">
                        <div className="row mb-4">
                            {!hideQuantity ? (
                                <div className="ticket-form-group col-12 col-md-6 col-xl-4">
                                    <SelectField
                                        label="Количество"
                                        selectedValue={values.unit_count}
                                        items={countList}
                                        placeholder="Выберите количество..."
                                        onValueChange={handleChange(
                                            'unit_count'
                                        )}
                                    />
                                </div>
                            ) : null}

                            <div className="ticket-form-group col-12 col-md-6 col-xl-4">
                                <SelectField
                                    label="Дата оказания"
                                    selectedValue={periodDate}
                                    items={dateList}
                                    placeholder="Выберите дату оказания..."
                                    icon="calendar"
                                    onValueChange={(value) => {
                                        if (value) {
                                            values.start_period_date =
                                                value.split(' – ')[0]
                                            values.end_period_date =
                                                value.split(' – ')[1]
                                        } else {
                                            values.start_period_date = ''
                                            values.end_period_date = ''
                                        }
                                        setPeriodDate(value)
                                    }}
                                />
                            </div>
                            <div className="ticket-form-group col-12 col-md-6 col-xl-4">
                                <SelectField
                                    label="Время оказания"
                                    selectedValue={periodTime}
                                    items={timeList}
                                    placeholder="Выберите время оказания..."
                                    icon="time"
                                    onValueChange={(value) => {
                                        if (value) {
                                            values.start_period_time =
                                                value.split(' – ')[0] + ':00'
                                            values.end_period_time =
                                                value.split(' – ')[1] + ':00'
                                        } else {
                                            values.start_period_time = ''
                                            values.end_period_time = ''
                                        }
                                        setPeriodTime(value)
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="ticket-form-group col-12 col-md-6 col-xl-4">
                                {/* <SelectField
                                    label="Адрес оказания услуги"
                                    selectedValue={values.account_id}
                                    items={accountsList}
                                    placeholder="Выберите адрес..."
                                    icon="pointer"
                                    onValueChange={(value) => {
                                        if (value) {
                                            let accountItem =
                                                accountsList.filter(
                                                    (a) => a.value === value
                                                )
                                            if (accountItem.length)
                                                values.address =
                                                    accountItem[0].label
                                        }
                                        values.account_id = value
                                        handleChange('account_id')
                                    }}
                                /> */}

                                <SelectField
                                    label="Адрес оказания услуги"
                                    selectedValue={values.address_id}
                                    items={accountsList}
                                    placeholder="Выберите адрес..."
                                    icon2="location-outline"
                                    onValueChange={(value) => {
                                        if (value) {
                                            let accountItem =
                                                accountsList.filter(
                                                    (a) => a.value === value
                                                )
                                            if (accountItem.length)
                                                values.address =
                                                    accountItem[0].label
                                        }
                                        values.address_id = value
                                        handleChange('address_id')
                                    }}
                                />
                            </div>
                            <div className="ticket-form-group form-group col-12 col-md-6 col-xl-4">
                                <TextareaField
                                    label="Подробное описание проблемы"
                                    placeholder="Опишите проблему"
                                    value={values.description}
                                    onChangeText={handleChange('description')}
                                    onBlur={handleBlur('description')}
                                />
                            </div>
                        </div>

                        <div className="ticket-form-user  mb-4">
                            <h5>Заявитель</h5>
                            <p>{name}</p>
                            <div>
                                <i className="icn icn-phone-danger"></i>
                                <b>{phone}</b>
                            </div>
                        </div>

                        <div className="section-block mb-4">
                            <div className="ticket-form-group container">
                                <div className={addContact ? `mb-4` : `mb-0`}>
                                    <SwitchField
                                        label="Добавить контактное лицо"
                                        onValueChange={(value) =>
                                            setAddContact(value)
                                        }
                                    />
                                </div>

                                {addContact && (
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-xl-4">
                                            <div className="ticket-form-group form-group mb-3">
                                                <InputField
                                                    label="Ф.И.О."
                                                    placeholder="Иванов Иван Иванович"
                                                    value={values.contact_name}
                                                    onChangeText={handleChange(
                                                        'contact_name'
                                                    )}
                                                    onBlur={handleBlur(
                                                        'contact_name'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4">
                                            <div className="ticket-form-group form-group mb-3">
                                                <InputField
                                                    label="Телефон"
                                                    placeholder="+7 (999) 999-99-99"
                                                    value={values.contact_phone}
                                                    type="phone-pad"
                                                    onChangeText={handleChange(
                                                        'contact_phone'
                                                    )}
                                                    onBlur={handleBlur(
                                                        'contact_phone'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-4"></div>
                                        <div className="col-4">
                                            <button className="btn btn-lg btn-danger w-100">
                                                Сохранить
                                            </button>
                                        </div> */}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="mb-4">
                            <h5>Фотографии</h5>
                            {/*
                            <div className="gallery-block">
                                <figure>
                                    <img
                                        src="/images/gallery-example-1.png"
                                        alt=""
                                    />
                                </figure>
                                <figure>
                                    <img
                                        src="/images/gallery-example-2.png"
                                        alt=""
                                    />
                                </figure>
                            </div> */}

                            <div className="form-photo">
                                <GalleryField onChange={setFiles} />
                            </div>
                        </div>

                        {id ? null : (
                            <div className="mb-4">
                                <InputField
                                    label="Промокод"
                                    placeholder="Введите промокод"
                                    value={values.promocode}
                                    onChangeText={handleChange('promocode')}
                                    onBlur={handleBlur('promocode')}
                                />
                            </div>
                        )}

                        <div className="row">
                            <div className="col-12 col-md-6 col-xl-4">
                                <button
                                    className="btn btn-lg btn-danger w-100"
                                    onClick={handleSubmit}
                                >
                                    {id ? `Сохранить` : `Далее`}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </div>
    )
}
