import * as React from 'react'
import { SelectFieldSmall } from '../Fields'

export const MarketplaceCard = ({
    item,
    price,
    onPress,
    removeFromCart,
    changeQuantity,
    quantity,
}) => {
    const quantityList = [1, 2, 3, 4, 5].map((item) => {
        return {
            label: item.toString() + ' шт.',
            value: item,
        }
    })

    return (
        <div className="service-card" onClick={onPress}>
            <div
                className="service-card-preview"
                style={{
                    backgroundImage: item.preview_file
                        ? `url(${item.preview_file.path})`
                        : null,
                }}
            ></div>

            {removeFromCart ? (
                <span
                    onClick={() => removeFromCart(item)}
                    className="service-card-remove"
                >
                    <i className={`fa-solid fa-times`}></i>
                </span>
            ) : null}

            {item.old_per_unit_price && (
                <div className="service-card-badge badge rounded-pill bg-danger">
                    Супер цена!
                </div>
            )}

            <div className="service-card-body">
                <p>
                    <b>{item.title}</b>
                </p>
                <p>{item.short_description}</p>
            </div>

            <div className="service-card-footer">
                {quantity ? (
                    <div>
                        {changeQuantity ? (
                            <SelectFieldSmall
                                items={quantityList}
                                onValueChange={(value) =>
                                    changeQuantity(item, value)
                                }
                                selectedValue={quantity}
                            />
                        ) : (
                            <div>{quantity} шт.</div>
                        )}
                    </div>
                ) : (
                    <div className="service-card-rating">
                        {item.rate}{' '}
                        <span className="opacity-50">({item.rate_count})</span>
                    </div>
                )}

                {price ? (
                    <div className="service-card-price">
                        <b>{price}₽</b>
                    </div>
                ) : (
                    <div>
                        {item.old_per_unit_price ? (
                            <div className="service-card-price">
                                <s>{item.old_per_unit_price}₽</s> от{' '}
                                <b className="text-danger">
                                    {item.per_unit_price}₽
                                </b>
                            </div>
                        ) : (
                            <div className="service-card-price">
                                от <b>{item.per_unit_price}₽</b>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
