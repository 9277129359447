import * as React from 'react'
import { connect } from 'react-redux'
import { OpenPhone } from '../../helpers/linkHelper'

const SosBannerBlock = ({ company }) => {
    const phone =
        company && company.dispatching_phone ? company.dispatching_phone : ''

    // console.log('company', company)
    return (
        <div className="sos-banner">
            <a className="sos-banner-phone" onClick={() => OpenPhone(phone)}>
                {phone}
            </a>
            <div className="sos-banner-text">Круглосуточная диспетчерская</div>
        </div>
    )
}

let mapStateToProps = (state) => ({
    company: state.user.company,
})

let mapDispatchToProps = () => {
    return {}
}

export const SosBanner = connect(
    mapStateToProps,
    mapDispatchToProps
)(SosBannerBlock)
