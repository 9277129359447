import * as React from 'react'
import { Text, View } from 'react-native'
import { connect } from 'react-redux'
import { OpenPhone } from '../../helpers/linkHelper'

import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'
import { Btn } from '../Btn'

const TicketsCancel = ({ company }) => {
    const phone = company && company.contact_phone ? company.contact_phone : ''

    return (
        <View>
            <Text style={[style.h1, s.textDanger, s.mb3]}>Отмена заявки</Text>

            <Text style={[s.textCenter, style.p]}>Номер для связи</Text>
            <Text style={[style.h1, s.mb5]}>{phone}</Text>

            <Btn color="btnDanger" onPress={() => OpenPhone(phone)}>
                Позвонить
            </Btn>
        </View>
    )
}

let mapStateToProps = (state) => ({
    company: state.user.company,
})

let mapDispatchToProps = () => {
    return {}
}

export const TicketsCancelModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketsCancel)
