import React from 'react'
import { FlatList } from 'react-native'

import { style } from '../../styles/mainStyles'
import { HousesCard } from './HousesCard'

const renderItem = ({ item }) => <HousesCard item={item} />

export const HousesList = ({ response }) => {
    const { items } = response

    return (
        <FlatList
            data={items}
            renderItem={renderItem}
            keyExtractor={(item) => item.id.toString()}
            style={style.box}
        />
    )
}
