import React from 'react'
import logo from '../assets/images/logo.svg'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { OpenTelegram } from '../helpers/linkHelper'
import useVisible from '../helpers/useVisible'

const NavbarComponent = ({ profile, settings }) => {
    const site = process.env.REACT_APP_SITE // eslint-disable-line
    let { new_notifications_count, addresses } = profile

    const { ref, isVisible, setIsVisible } = useVisible(false)

    if (addresses && addresses.length)
        addresses = addresses.filter((item) => item.account)

    return (
        <nav className="navbar">
            <div className="container-fluid">
                <div className="navbar-logo">
                    <a className="navbar-brand" href="/">
                        <img src={logo} height="57" width="137" alt="" />
                    </a>
                </div>

                <div className="navbar-buttons">
                    <div>
                        <a
                            onClick={() => setIsVisible(!isVisible)}
                            className="btn bg-primary text-white"
                        >
                            <i className="fa-brands fa-telegram"></i>
                            <span>Обсудить вопросы с соседями</span>
                        </a>

                        {addresses && isVisible && (
                            <div className="navbar-buttons-menu" ref={ref}>
                                {addresses.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <a
                                                className="btn bg-primary"
                                                onClick={() =>
                                                    OpenTelegram(
                                                        item.account?.house
                                                            ?.telegram_link
                                                    )
                                                }
                                            >
                                                <span className="text-white">
                                                    {item.address}
                                                </span>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>

                    {settings && settings.telegram_bot ? (
                        <a
                            className="btn bg-danger text-white"
                            onClick={() => OpenTelegram(settings.telegram_bot)}
                        >
                            <i className="fa-solid fa-volume-high"></i>
                            <span>Написать в свою УК</span>
                        </a>
                    ) : null}
                </div>

                <div className="navbar-buttons">
                    <NavLink to="/notifications" className="btn bg-white">
                        <i className="fa-solid fa-bell"></i>
                        {new_notifications_count &&
                        parseInt(new_notifications_count) ? (
                            <span className="btn-sup">
                                {new_notifications_count}
                            </span>
                        ) : null}
                    </NavLink>

                    <NavLink to="/faq" className="btn bg-white">
                        <i className="fa-solid fa-circle-question"></i>
                    </NavLink>

                    <div className="navbar-site">
                        <a
                            href={site}
                            target="_blank"
                            className="btn btn-white"
                            rel="noreferrer"
                        >
                            Перейти на сайт
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

let mapStateToProps = (state) => ({
    company: state.user.company,
    profile: state.user.profile,
    settings: state.user.settings,
})

let mapDispatchToProps = () => {
    return {}
}
const Navbar = connect(mapStateToProps, mapDispatchToProps)(NavbarComponent)
export { Navbar }
