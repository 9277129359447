import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../../assets/images/logo.svg'
import { LoginContainer } from '../../containers/Auth/LoginContainer'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { AuthRedirect } from '../../components/Auth/AuthRedirect'

export const LoginPage = () => {
    return (
        <div className="login-box">
            <AuthRedirect />

            <div className="mb-3 login-box-logo">
                <img src={logo} height="57" width="137" alt="" />
            </div>

            <div className="mb-4 pt-2 text-center">
                <h4>Авторизация</h4>
                <PageHeader title="Авторизация" />
            </div>

            <div className="mb-4">
                <LoginContainer />
            </div>

            <div className="text-center">
                <p>
                    <NavLink to="/auth/recovery">Забыли пароль?</NavLink>
                </p>
                <p>
                    <NavLink to="/auth/registration" className="text-danger">
                        <b>Зарегистрироваться</b>
                    </NavLink>
                </p>
            </div>
        </div>
    )
}
