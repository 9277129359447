import React from 'react'
import moment from 'moment'

export const NotificationsCard = ({ item }) => {
    const createdAt = moment(item.created_at).format('DD.MM.YYYY hh:mm')

    return (
        <div className="notification-card">
            <h5 className="notification-card-title">
                {item.read_at ? (
                    ''
                ) : (
                    <span className="ellipse bg-secondary"></span>
                )}{' '}
                {item.title}
            </h5>
            <p className="notification-card-text">{item.text}</p>
            <small className="notification-card-caption">{createdAt}</small>
        </div>
    )
}
