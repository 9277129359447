import React from 'react'
import { HashRouter, Route } from 'react-router-dom'

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import MainReducer from './store/MainReducer'
import RootNavigation from './navigation/RootNavigator'

import PreviewPage from './pages/PreviewPage'
import { ThemeProvider } from './contexts/ThemeProvider'

const store = createStore(MainReducer)

function App() {
    return (
        <Provider store={store}>
            <HashRouter>
                <Route component={ScrollToTop} />
                <PreviewPage />

                <ThemeProvider>
                    <RootNavigation />
                </ThemeProvider>
            </HashRouter>
        </Provider>
    )
}

const ScrollToTop = () => {
    window.scrollTo(0, 0)
    return null
}

export { store }
export default App
