import { SET_STATUS_LIST, SET_ORDER_STATUS_LIST } from './types/StatusTypes'

const INITIAL_STATE = {
    statuses: [],
    orderStatuses: [],
}

const formatStatusList = (items) => {
    return items.map((item) => {
        let color = {
            color: 'bgWarning',
            colorWeb: 'bg-warning',
        }

        let cans = {
            canAddCart: false,
            canEdit: false,
            canCancel: true,
            canPay: false,
            canPayMaster: false,
            canConfirmation: false,
            canAct: false,
            canReview: false,
        }

        //     { title: 'Новая', slug: 'new' },
        //     { title: 'В обработке 1', slug: 'process_1' },
        //     { title: 'В обработке 2', slug: 'process_2' },
        //     { title: 'Выезд мастера', slug: 'departure_master' },
        //     { title: 'Консультация мастера', slug: 'consultation_master' },
        //     { title: 'Оплачено', slug: 'paid' },
        //     { title: 'В работе', slug: 'work' },
        //     { title: 'Подтверждение работ', slug: 'confirmation_work' },
        //     { title: 'Перенос клиентом', slug: 'transfer_client' },
        //     { title: 'Переоформленная', slug: 're-registered' },
        //     { title: 'Отказ клиентом 1', slug: 'client_cancel_1' },
        //     { title: 'Отказ клиентом 2', slug: 'client_cancel_2' },
        //     { title: 'Отказ мастером', slug: 'master_cancel' },

        // Действия
        switch (item.slug) {
            case 'new':
            case 'process_2':
            case 'departure_master':
            case 'transfer_client':
            case 're-registered':
                cans = {
                    ...cans,
                    canAddCart: true,
                    canEdit: true,
                }
                break

            case 'process_1':
                cans = {
                    ...cans,
                    canAddCart: true,
                    canEdit: true,
                    canPayMaster: true,
                }
                break

            case 'work':
                cans = {
                    ...cans,
                    canConfirmation: true,
                    canAct: true,
                }
                break

            case 'paid':
                cans = {
                    ...cans,
                    canAct: true,
                }
                break

            case 'consultation_master':
                cans = {
                    ...cans,
                    canPay: true,
                }
                break

            case 'solved':
            case 'confirmation_work':
                cans = {
                    ...cans,
                    canCancel: false,
                    canReview: true,
                }
                break

            case 'client_cancel_1':
            case 'client_cancel_2':
            case 'master_cancel':
                cans = {
                    ...cans,
                    canCancel: false,
                }
                break
        }

        // Цвета
        switch (item.slug) {
            case 'paid':
            case 'confirmation_work':
                color = {
                    color: 'bgSuccess',
                    colorWeb: 'bg-success',
                    textWeb: 'text-white',
                    text: 'textWhite',
                }
                break
            case 'client_cancel_1':
            case 'client_cancel_2':
            case 'master_cancel':
                color = {
                    color: 'bgDanger',
                    colorWeb: 'bg-danger',
                    textWeb: 'text-white',
                    text: 'textWhite',
                }
                break
        }

        item.title = item?.title_user ? item.title_user : item.title

        return {
            ...item,
            ...color,
            ...cans,
        }
    })
}

const StatusReducer = (state = INITIAL_STATE, action) => {
    const { payload } = action

    switch (action.type) {
        case SET_STATUS_LIST:
            return {
                ...state,
                statuses: formatStatusList(payload),
            }
        case SET_ORDER_STATUS_LIST:
            return {
                ...state,
                orderStatuses: formatStatusList(payload),
            }
        default:
            return state
    }
}
export default StatusReducer
