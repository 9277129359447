import React, { useEffect } from 'react'
import { ActivityIndicator } from 'react-native'
import { connect } from 'react-redux'
import { ContactsView } from '../../components/Contacts/ContactsView'
import api from '../../services/api'
import { setCompany } from '../../store/actions/UserActions'

const Container = ({ company, city_id, getCompany }) => {
    useEffect(() => {
        getCompany(city_id)
    }, [])

    if (!company) return <ActivityIndicator color="#d21100" />

    const data = company
    return <ContactsView data={data} />
}

let mapStateToProps = (state) => ({
    city_id: state.user.city_id,
    company: state.user.company,
})

let mapDispatchToProps = (dispatch) => {
    return {
        getCompany: (cityId) => {
            api.fetch(`companies`, {
                'per-page': 1,
                extend: 'timings,provider_type,city,company_detail,infos',
                'filter[city_id]': `EQ(${cityId})`,
            }).then((response) => {
                const { items } = response.data
                if (items && items.length) dispatch(setCompany(items[0]))
            })
        },
    }
}

export const ContactsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
