import { store } from '../App'

export const getTicketStatusById = (id) => {
    const { statuses } = store.getState().status

    if (!statuses || !statuses.length) return null

    const statusFilter = statuses.filter((s) => id === s.id)

    if (statusFilter && statusFilter.length) {
        return statusFilter[0]
    }

    return null
}

export const getTicketStatusBySlug = (slug) => {
    const { statuses } = store.getState().status

    if (!statuses || !statuses.length) return null

    const statusFilter = statuses.filter((s) => slug === s.slug)

    if (statusFilter && statusFilter.length) {
        return statusFilter[0]
    }

    return null
}

export const getOrderStatusById = (id) => {
    const { orderStatuses } = store.getState().status

    if (!orderStatuses || !orderStatuses.length) return null

    const statusFilter = orderStatuses.filter((s) => id === s.id)

    if (statusFilter && statusFilter.length) {
        return statusFilter[0]
    }

    return null
}

// Старые статусы
export const ticketStatuses = {
    1: {
        id: 1,
        slug: 'new',
        title: 'Новая',
        color: 'bgWarning',
        colorWeb: 'bg-warning',
    },
    3: {
        id: 3,
        slug: 'approved',
        title: 'Подтверждена',
        color: 'bgWarning',
        colorWeb: 'bg-warning',
    },
    4: {
        id: 4,
        slug: 'solved',
        title: 'Решена',
        color: 'bgSuccess',
        colorWeb: 'bg-success',
        textWeb: 'text-white',
    },
    5: {
        id: 5,
        slug: 'paid',
        title: 'Оплачена',
        color: 'bgSuccess',
        colorWeb: 'bg-success',
    },
    6: {
        id: 6,
        slug: 'cancelled_by_client',
        title: 'Отказ клиента',
        color: 'bgDanger',
        colorWeb: 'bg-danger',
        textWeb: 'text-white',
    },
    20: {
        id: 20,
        slug: 'new',
        title: 'Новая',
        color: 'bgWarning',
        colorWeb: 'bg-warning',
    },
}
