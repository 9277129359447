import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import api from '../../services/api'
import {
    setProfile,
    setCity,
    setCompany,
} from '../../store/actions/UserActions'
import {
    ErrorAlert,
    SuccessAlert,
    MessageAlert,
} from '../../helpers/apiMessageHelper'
import { ProfileEdit } from '../../components/Profile/ProfileEdit'

const setDefaultAccounts = (accounts, default_account_id) => {
    if (accounts && accounts.length) {
        accounts.map((item) => {
            item.is_default =
                default_account_id && item.id === default_account_id
                    ? true
                    : false
            return item
        })
    }

    return accounts
}

const Container = ({ profile, getProfile, onChangeEmail, onChangeName }) => {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        getProfile()
    }, [])

    const { email, name, default_address_id } = profile
    let { addresses } = profile

    addresses = setDefaultAccounts(addresses, default_address_id)

    const onChangeNameFunction = async (formData) => {
        await onChangeName(formData)
        getProfile()
    }
    const onChangeEmailFunction = async (formData) => {
        let { email } = formData
        const isValid = /^[^@]+@\w+(\.\w+)+\w$/.test(email)

        if (isValid) {
            await onChangeEmail(formData)
            getProfile()
        } else {
            MessageAlert('Ошибка', 'Введите корректный e-mail адрес')
        }
    }

    const onChangeAcceptPush = (data) => {
        api.post(`me/change-accept-push`, data)
            .catch((error) => ErrorAlert(error))
            .finally(() => {
                getProfile()
                addresses = setDefaultAccounts(
                    profile.addresses,
                    default_address_id
                )
            })
    }

    const onChangeDefaultAccount = (data) => {
        if (!data) return

        const { default_address_id } = data

        if (default_address_id)
            api.post(`/me/change-default-address`, data)
                .catch((error) => ErrorAlert(error))
                .finally(() => {
                    getProfile()
                    addresses = setDefaultAccounts(
                        profile.addresses,
                        default_address_id
                    )
                })
    }

    const changeFields = [
        {
            field: 'name',
            formValues: { name },
            placeholder: 'Укажите Ф.И.О.',
            title: 'Ф.И.О.',
            onSubmit: onChangeNameFunction,
        },
        {
            field: 'email',
            formValues: { email },
            placeholder: 'Укажите E-mail',
            title: 'E-mail',
            onSubmit: onChangeEmailFunction,
            type: 'email-address',
        },
    ]

    return (
        <ProfileEdit
            profile={profile}
            accounts={addresses}
            changeFields={changeFields}
            getProfile={getProfile}
            onChangeDefaultAccount={onChangeDefaultAccount}
            onChangeAcceptPush={onChangeAcceptPush}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
        />
    )
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
})

let mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => {
            api.profile().then((response) => {
                dispatch(setProfile(response.data.data))

                let cityId = response?.data?.data?.city_id

                if (cityId) {
                    dispatch(setCity(cityId))

                    api.fetch(`companies`, {
                        'per-page': 1,
                        'filter[city_id]': `EQ(${cityId})`,
                        extend: 'timings,provider_type,city',
                    }).then((response) => {
                        const { items } = response.data

                        if (items && items.length)
                            dispatch(setCompany(items[0]))
                    })
                }
            })
        },
        onChangeEmail: (formData) => {
            api.post(`me/change-email`, formData)
                .then(() => {
                    SuccessAlert()
                })
                .catch((error) => {
                    ErrorAlert(error)
                })
        },
        onChangeName: (formData) => {
            api.post(`me/change-name`, formData)
                .then(() => {
                    SuccessAlert()
                })
                .catch((error) => {
                    ErrorAlert(error)
                })
        },
        onDetachAccount: (formData) => {
            api.post(`me/detach-account`, formData).catch((error) => {
                ErrorAlert(error)
            })
        },
    }
}

export const ProfileEditContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
