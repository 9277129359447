import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { SosModal } from './Contacts/SosModal'
import Modal from 'react-modal'
import api from '../services/api'
import { connect } from 'react-redux'

const links = [
    {
        title: 'Главная',
        // icon: 'home',
        icon: 'house',
        to: '/',
    },
    {
        title: 'Заявки',
        icon: 'inbox',
        // icon: 'mail',
        to: '/tickets',
    },
    // {
    //     title: 'Услуги',
    //     icon: 'setting',
    //     to: '/services',
    // },
    {
        title: 'Корзина',
        titleMobile: 'Корзина',
        icon: 'cart-shopping',
        to: '/cart',
    },
    {
        title: 'Витрина услуг',
        titleMobile: 'Услуги',
        icon: 'cog',
        to: '/services',
    },
    // {
    //     title: 'Уведомления',
    //     icon: 'notification',
    //     to: '/notifications',
    // },
    {
        title: 'Контакты',
        icon: 'phone',
        to: '/contacts',
    },
    {
        title: 'Профиль',
        icon: 'user',
        to: '/profile',
    },
    // {
    //     title: 'Новости',
    //     to: '/news',
    // },
    // {
    //     title: 'FAQ',
    //     to: '/faq',
    // },
]

const MenuContainer = ({ cartQuantity }) => {
    const [isVisible, setIsVisible] = useState(false)
    const [ticketCount, setTicketCount] = useState(0)

    const fetch = () => {
        api.fetch(`tickets`, {
            'paginator[limit]': 1,
            'paginator[page]': 1,
            'filter[ticket_status_id]': 'EQ(1)',
        }).then((response) => {
            const { _meta } = response.data
            if (_meta && _meta.totalCount) setTicketCount(_meta.totalCount)
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    return (
        <div>
            <div className="nav-box">
                <ul className="nav flex-column d-none d-md-block">
                    {links.map((item, index) => (
                        <li key={index} className="nav-item">
                            <NavLink to={item.to} className="nav-link" exact>
                                {/* <i
                                    className={`icn icn-${item.icon}-secondary`}
                                ></i> */}
                                <i className={`fa-solid fa-${item.icon}`}></i>
                                {item.title}{' '}
                                {item.to === `/tickets` && ticketCount ? (
                                    <sup className="badge-count">
                                        {ticketCount}
                                    </sup>
                                ) : null}
                                {item.to === `/cart` && cartQuantity ? (
                                    <sup className="badge-count">
                                        {cartQuantity}
                                    </sup>
                                ) : null}
                            </NavLink>
                        </li>
                    ))}
                    <li className="nav-item">
                        <a
                            className="nav-link text-danger"
                            onClick={() => setIsVisible(true)}
                        >
                            {/* <i className={`icn icn-info-danger`}></i> */}
                            <i className="fa-solid fa-circle-info"></i>
                            SOS
                        </a>
                    </li>
                </ul>

                <ul className="nav flex-column d-sm-none">
                    {links.map((item, index) => (
                        <li key={index} className="nav-item">
                            <Link to={item.to} className="nav-link">
                                <i className={`fa-solid fa-${item.icon}`}></i>
                                {item.titleMobile
                                    ? item.titleMobile
                                    : item.title}
                                {/* <sup className="badge-count">2</sup> */}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <Modal
                isOpen={isVisible}
                onRequestClose={() => setIsVisible(false)}
            >
                <div>
                    <a
                        onClick={() => setIsVisible(false)}
                        className="modal-close-link"
                    ></a>
                    <SosModal />
                </div>
            </Modal>
        </div>
    )
}

let mapStateToProps = (state) => ({
    cartQuantity: state.cart.countAll,
})

let mapDispatchToProps = () => {
    return {}
}

export const Menu = connect(mapStateToProps, mapDispatchToProps)(MenuContainer)
