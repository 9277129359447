// import AsyncStorage from '@react-native-async-storage/async-storage'
// export default AsyncStorage

function createPromise(getValue, callback) {
    return new Promise((resolve, reject) => {
        try {
            const value = getValue()
            callback?.(null, value)
            resolve(value)
        } catch (err) {
            callback?.(err)
            reject(err)
        }
    })
}

function createPromiseAll(promises, callback, processResult) {
    return Promise.all(promises).then(
        (result) => {
            const value = processResult?.(result) ?? null
            callback?.(null, value)
            return Promise.resolve(value)
        },
        (errors) => {
            callback?.(errors)
            return Promise.reject(errors)
        }
    )
}

const AsyncStorage = {
    getItem: (key, callback) => {
        return createPromise(() => window.localStorage.getItem(key), callback)
    },
    setItem: (key, value, callback) => {
        return createPromise(
            () => window.localStorage.setItem(key, value),
            callback
        )
    },
    removeItem: (key, callback) => {
        return createPromise(
            () => window.localStorage.removeItem(key),
            callback
        )
    },
    multiGet: (keys, callback) => {
        const promises = keys.map((key) => AsyncStorage.getItem(key))
        const processResult = (result) =>
            result.map((value, i) => [keys[i], value])
        return createPromiseAll(promises, callback, processResult)
    },
}

export default AsyncStorage
