import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export const PageHeader = ({ back, backTitle, title }) => {
    useEffect(() => {
        document.title = title

        return () => {
            document.title = process.env.REACT_APP_TITLE// eslint-disable-line
        }
    }, [title])

    if (!back) return <></>

    if (typeof back === 'function')
        return (
            <div className="page-header">
                <a onClick={back} className="back-link">
                    <i className="icn icn-arrow-left"></i>
                    {backTitle ? backTitle : `Назад`}
                </a>
            </div>
        )

    return (
        <div className="page-header">
            <Link to={back} className="back-link">
                <i className="icn icn-arrow-left"></i>
                {backTitle ? backTitle : `Назад`}
            </Link>
        </div>
    )
}
