import React from 'react'
import { useHistory } from 'react-router-dom'
import logo from '../../assets/images/logo.svg'
import { NavLink } from 'react-router-dom'
import { RegistrationContainer } from '../../containers/Auth/RegistrationContainer'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { AuthRedirect } from '../../components/Auth/AuthRedirect'

export const RegistrationPage = () => {
    const history = useHistory()

    return (
        <div className="login-box">
            <AuthRedirect />

            <div className="mb-3 login-box-logo">
                <img src={logo} height="57" width="137" alt="" />
            </div>

            <div className="mb-4 pt-2 text-center">
                <PageHeader title="Регистрация" />
                <h4>Регистрация</h4>
            </div>

            <div className="mb-4">
                <RegistrationContainer
                    onLogin={() => history.push('/auth/login')}
                />
            </div>

            <div className="text-center">
                <p>
                    <NavLink to="/auth/login">Авторизация</NavLink>
                </p>
            </div>
        </div>
    )
}
