import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { CartContainer } from '../../containers/Cart/CartContainer'

export const CartPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader title="Корзина" />
            <h1>Корзина</h1>

            <CartContainer {...props} />
        </div>
    )
}
