import React from 'react'
// import { PointsBlockContainer } from '../../containers/Points/PointsBlockContainer'
import { ProfileBlockContainer } from '../../containers/Profile/ProfileBlockContainer'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { ProfileContainer } from '../../containers/Profile/ProfileContainer'
import { NavLink } from 'react-router-dom'
// import { IndexAlert } from '../../components/Index/IndexAlert'

import getEnv from '../../helpers/envHelper'
const { REACT_APP_EIRC_URL } = getEnv()

export const ProfilePage = (props) => {
    return (
        <>
            {/* <div className="white-box profile-box mb-3">
                <ProfileBlockContainer />
            </div> */}

            <div className="white-box mb-3">
                <PageHeader title="Профиль" />

                <h1>Профиль</h1>

                <div className="mb-4 row">
                    <div className="col-xl-5 mb-3">
                        <ProfileBlockContainer />
                    </div>
                    <div className="col-xl-5 text-end">
                        <NavLink
                            to="/profile/home"
                            className="btn btn-lg btn-danger"
                        >
                            <i className="fa-solid fa-house mr-2"></i>
                            Мой дом
                        </NavLink>
                    </div>
                </div>

                <div className="row mb-3">
                    {/* <div className="col-12 col-xl-8">
                        <ProfileBlockContainer />
                    </div> */}
                    <div className="col-12 col-xl-10">
                        {/* <PointsBlockContainer /> */}
                        {/* <IndexAlert
                            text={`Скоро здесь появится возможность внести показания счетчиков и оплатить коммунальные услуги`}
                        /> */}

                        <a
                            href={REACT_APP_EIRC_URL}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-lg btn-primary"
                        >
                            <i className="fa-solid fa-calculator mr-2"></i>
                            Ввести показания счетчиков
                        </a>
                    </div>
                </div>
            </div>

            <div className="white-box profile-box">
                <ProfileContainer {...props} />
            </div>
        </>
    )
}
