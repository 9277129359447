import React, { useState, useEffect } from 'react'
import { ActivityIndicator } from 'react-native'
import { ServicesOrder } from '../../components/Services/ServicesOrder'
import api from '../../services/api'

export const ServicesOrderContainer = ({ match, route }) => {
    let { id, option_id } = match ? match.params : route.params
    const [response, setResponse] = useState({})
    let service_id = id

    if (!option_id) {
        const id_array = id.split('-')

        if (id_array.length != 2)
            return <ActivityIndicator size="large" color="#d21100" />

        service_id = id_array[0]
        option_id = id_array[1]
    }

    const fetch = () => {
        api.fetchById(`services`, service_id, {
            extend: 'preview_file,service_category,unit_type,service_options',
        }).then((response) => {
            setResponse(response.data)
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    const { data } = response

    if (!data) return <ActivityIndicator size="large" color="#d21100" />

    return <ServicesOrder data={data} id={service_id} option_id={option_id} />
}
