import { StyleSheet } from 'react-native'
import transform from 'css-to-react-native-transform'

let stylesList = transform(`

.html {
    font-size: 16px;
}

.opacity-0 {
    opacity: 0;
}

.opacity-25 {
    opacity: 0.25;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-70 {
    opacity: 0.7;
}

.opacity-75 {
    opacity: 0.75;
}

.opacity-100 {
    opacity: 1;
}

.opacity-0-hover:hover {
    opacity: 0;
}

.opacity-25-hover:hover {
    opacity: 0.25;
}

.opacity-50-hover:hover {
    opacity: 0.5;
}

.opacity-75-hover:hover {
    opacity: 0.75;
}

.opacity-100-hover:hover {
    opacity: 1;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-between-h1,
.flex-between-h2,
.flex-between-h3,
.flex-between-h4,
.flex-between-h5 {
    margin-bottom: 0px;
}

.sitemap-menu {
    position: fixed;
    right: 0px;
    padding: 0px 20px;
    font-size: 14px;
    }

.sitemap-menu-li {
    margin: 5px 0px;
}

.* {
    }

.html,
.body {
    min-height: 100%;
    background-color: #f2f2f2;
}

.body {
    font-size: 16px;
        color: #302528;
}

.b {
    font-weight: 600;
}

.h1,
.h2 {
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 2.5rem;
}

.h1 {
    font-size: 42px;
}

.h2 {
    font-size: 32px;
}

.h3 {
    font-size: 32px;
    font-weight: 600;
}

.h4 {
    font-size: 24px;
    font-weight: 600;
}

.h5 {
    font-size: 18px;
    font-weight: 600;
}

.p {
    margin-bottom: 20px;
    }

.a {
    text-decoration: none;
    color: inherit;
}

.a:hover {
    color: #d21100;
}

.small {
    font-size: 12px;
}

.p-icn {
    margin-right: 12px;
}

.hr {
    color: #ebebeb;
    opacity: 1;
    margin: 24px 0px;
}

.h1-page,
h1.h1-page {
        font-weight: 600;
    font-size: 24px;
    text-transform: unset;
}

.back-link {
    font-weight: 600;
    font-size: 24px;
        color: #302528;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.back-link-icn {
    margin-right: 20px;
}

.back-link-icn::before {
    top: 0;
}

.navbar-a[href*="tel"] {
    font-size: 18px;
    font-weight: 600;
}

.container-custom {
    padding: 0px 290px;
    position: relative;
}

.nav-link {
    color: #5b5456;
}

.nav-box {
    padding: 20px 25px 50px;
    background: #fff;
    border-radius: 0px 24px 24px 0px;
    position: fixed;
    left: 0px;
    z-index: 9;
    width: 250px;
}

.nav-box-nav-link {
    padding: 20px 10px;
}

.btn {
    font-weight: 500;
    text-align: center;
}

.btn-link {
    text-decoration: none;
}

.btn-white {
    background-color: #fff;
    color: #d21100;
}

.mw-sm {
    max-width: 360px;
}

.white-box {
    background: #fff url("../images/box-bg.png") no-repeat top right;
    background-position: right 30px top -30px;
    background-size: 895px auto;
    border-radius: 32px;
    padding: 85px 105px;
}

.white-box-section-block {
    margin-left: -105px;
    margin-right: -105px;
}

.black-box {
    background: #2d2829;
    border-radius: 32px;
    position: relative;
}

.black-box-h1,
.black-box-h2,
.black-box-h3,
.black-box-h4,
.black-box-h5 {
    color: #fff;
}

.custom-box-header {
    height: 32px;
    width: 100%;
    border-radius: 32px 32px 0px 0px;
}

.custom-box-footer {
    height: 32px;
    width: 100%;
    border-radius: 0px 0px 32px 32px;
}

.section-block {
    background: #f2f2f2;
    padding: 30px 85px;
}

.section-block-form-control {
    }

.list-group-item {
    padding: 1.5rem 0;
    border: 1px solid rgba(0,0,0,.125);

    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
}

.text-opacity {
    opacity: 0.7;
}

.badge-custom {
    color: #d21100;
    background-color: rgba(210, 17, 0, 0.07);
}

.card-address {
    padding: 8px 0px;
}

.card-address-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 0px 20px 0px;
}

.card-address-header-h4 {
    margin: 0px;
}

.card-address-header-h4-badge {
    margin-left: 10px;
}

.card-address-header-icn {
    margin-right: 5px;
}

.card-address-footer {
    margin-top: 20px;
    align-items: center;
}

.card-address-footer-icn {
    margin-right: 5px;
}

.form-control {
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
}

.form-control-search {
    padding-left: 24px;
    background-image: url(../icons/dark/search.svg);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 14px auto;
}

.form-label {
    font-size: 14px;
    margin: 0px;
    text-align: left;
}

.form-text {
    font-size: 12px;
    margin: 0px;
    opacity: 0.5;
    margin-top: 8px;
    color: #302528;
}

.form-switch {
    padding-left: 60px;
}

.form-switch-form-check-label {
    opacity: 0.7;
}

.form-switch-form-check-input {
    width: 45px;
    margin-left: -60px;
    height: 25px;
    margin-top: 0px;
    background-color: #555860;
    border-color: #555860;
}

.form-switch-form-check-input:checked {
    background-color: #d21100;
    border-color: #d21100;
}

.form-photo-label {
    width: 170px;
    height: 170px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 24px;
    padding: 42px 8px;
    text-align: center;
    color: #d21100;
    }

.form-photo-label-icn {
    display: block;
    margin-bottom: 8px;
}

.form-photo-label-icn::before {
    height: 25px;
    width: 25px;
    background-size: 25px auto;
}

.form-photo-control {
    display: none;
}

.points-block {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 24px;
    padding: 16px 20px;
}

.points-block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
}

.points-block-title {
    margin-bottom: 0;
    font-size: 20px;
}

.points-block-title-badge {
    margin-left: 8px;
}

.points-block-link {
    color: #5b5456;
    text-decoration: none;
    font-size: 12px;
}

.points-block-link-i {
    margin-right: 8px;
}

.points-block-fill,
.points-block-line {
    height: 16px;
    border-radius: 16px;
    background: #fcab00;
    display: block;
    position: relative;
}

.points-block-fill {
    position: absolute;
    background: #d21100;
    left: 0px;
    top: 0px;
}

.points-block-val {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    font-size: 20px;
    display: block;
    }

.points-block-text {
    font-size: 12px;
        opacity: 0.5;
    margin-top: 8px;
}

.service-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    max-width: 740px;
}

.service-category-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.service-category-preview {
    width: 170px;
    height: 170px;
    background: #f2f2f2;
    border-radius: 40px;
    padding: 10px 20px;
}

.service-category-preview-img {
    height: 82px;
}

.service-category-preview-img {
    max-width: 92px;
    max-height: 82px;
}

.service-category-preview-service-category-title {
    margin-top: 10px;
}

.service-category-title {
    font-weight: bold;
        font-size: 16px;
    text-align: center;
    margin-top: 20px;
}

.service-category-caption {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 4px;
}

.service-banner {
    padding: 16px 20px;
    border-radius: 24px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 360px;
    }

.service-banner-p:last-child {
    margin-bottom: 0px;
}

.service-step-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -38px;
}

.service-step-item {
    width: 190px;
    position: relative;
    padding-right: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.service-step-item::after {
    content: '';
    display: block;
    width: 95px;
    height: 1px;
    border-bottom: 1px dashed #555860;
    opacity: 0.5;
    position: absolute;
    top: 47px;
    right: -38px;
}

.service-step-item:last-child::after {
    display: none;
}

.service-step-preview {
    border-radius: 30px;
    background: #555860;
    width: 94px;
    height: 94px;
    margin: 0px auto 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-step-preview-img {
    max-width: 60px;
    max-height: 60px;
    width: auto;
    height: auto;
}

.news-list {
    display: grid;
    grid-template-columns: repeat(6, 170px);
    grid-column-gap: 20px;
}

.news-card {
    width: 170px;
    height: 170px;
    border-radius: 24px;
    padding: 16px 20px 20px 20px;
    background: #f2f2f2 no-repeat center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.news-card-image {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 170px;
    height: 170px;
}

.news-card-bg,
.news-card-image::before {
    position: absolute;
    width: 170px;
    height: 170px;
    left: 0px;
    top: 0px;
    background: #000;
    opacity: 0.6;
}

.news-card-date {
        position: relative;
}

.news-card-title {
    font-weight: 600;
        position: relative;
}

.index-card {
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 24px;
    padding: 48px;
    text-align: center;
}

.index-card-image {
    margin-bottom: 25px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.index-card-image-img {
    max-height: 100%;
    width: auto;
}

.index-card-title {
    font-weight: 600;
    font-size: 18px;
    margin: 10px 0px 0px 0px;
}

.index-card-text {
    margin: 20px 0px 0px 0px;
        color: #5b5456;
}

.index-card-cost {
    font-weight: 600;
    font-size: 22px;
    margin: 16px 0px 0px 0px;
}

.index-card-cost::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 8px;
    background: #fff;
    position: relative;
    top: -3px;
}

.index-card.bg-danger {
    border-color: #d21100;
    color: #fff;
}

.index-card.bg-danger-index-card-text {
    margin-top: 10px;
    color: #fff;
}

.page-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 35px;
}

.page-header-h1-page {
    margin: 0px;
}

.page-header-a+h1-page {
    margin-left: 20px;
}

.page-body-img {
    max-width: 100%;
    height: auto;
    border-radius: 24px;
}

.login-box {
    padding: 65px 95px;
    background: #fff;
    border-radius: 32px;
    max-width: 550px;
    text-align: center;
        margin: 0 auto;
}

.login-box-form-group {
    text-align: left;
}

.login-box-btn {
    width: 100%;
}

.login-box-a {
    font-weight: 500;
    font-size: 14px;
    color: #302528;
    text-decoration: none;
}

.phone-code-form {
    text-align: center;
}

.phone-code-example {
    font-size: 18px;
    }

.phone-code-example-span {
    font-weight: normal;
    background: #555860;
    border-radius: 5px;
    color: #fff;
    padding: 2px 4px;
}

.phone-code-values {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 8px;
    max-width: 268px;
    margin-left: auto;
    margin-right: auto;
}

.phone-code-values-form-control {
    font-size: 24px;
    text-align: center;
}

.service-card {
    position: relative;
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 16px 16px 16px 94px;
    background-color: #fff;
}

.service-card-p {
    margin-bottom: 5px;
}

.service-card-badge {
    position: absolute;
    right: 16px;
    top: -10px;
    font-size: 12px;
}

.service-card-preview {
    width: 80px;
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    background: #f2f2f2 no-repeat center;
    background-size: cover;
    border-radius: 24px 0px 0px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-card-preview-img {
    max-width: 36px;
    max-height: 36px;
    width: auto;
    height: auto;
}

.service-card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px 0px 0px;
}

.service-card-rating::before {
    content: "";
    background: url("../icons/star.svg") no-repeat center;
    background-size: 14px auto;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.service-card-price-s {
    margin-right: 10px;
}

.city-item {
    padding: 18px 18px 18px 75px;
    border-radius: 28px;
    border: 1px solid rgba(0, 0, 0, .1);
    position: relative;
    max-width: 360px;
    padding-bottom: 198px;
}

.city-item::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 28px;
    background: url(../icons/danger/pointer.svg) no-repeat center;
    left: 30px;
    top: 50%;
    margin-top: -14px;
    margin-top: -104px;
}

.city-item-h5 {
    margin-bottom: 5px;
}

.city-item-p {
    opacity: 0.5;
    margin: 0px;
}

.city-item-map {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 180px;
    border-radius: 0px 0px 28px 28px;
    overflow: hidden;
}

.city-item-map>div {
    width: 100%;
    height: 180px;
}

.notification-card {
    padding-left: 55px;
    position: relative;
}

.notification-card-header {
    display: flex;
    justify-content: space-between;
}

.notification-card-h5,
.notification-card-title {
    font-size: 16px;
    font-weight: 600;
        margin-bottom: 4px;
}

.notification-card-p,
.notification-card-text {
        margin-bottom: 8px;
}

.notification-card-small,
.notification-card-caption {
    font-size: 14px;
        opacity: 0.7;
}

.notification-card-preview {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0px;
}

.meters-data-item {
    font-size: 14px;
        border-radius: 24px;
    margin-bottom: 24px;
    padding: 18px;
    border: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.meters-data-card {
    padding: 18px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 24px;
}

.meters-data-card-footer {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faq-item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    margin-bottom: 16px;
}

.faq-item-link {
    font-size: 14px;
    font-weight: 600;
        padding: 18px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faq-item-icn {
    margin-left: 15px;
}

.faq-item-icn::before {
    top: 0;
}

.faq-item:last-child {
    margin-bottom: 0px;
}

.area-block-p {
    margin-bottom: 24px;
}

.area-block-a[href*="tel"] {
    font-size: 20px;
    font-weight: 600;
}

.gallery-block {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.gallery-block-figure {
    width: 170px;
    height: 170px;
    border-radius: 24px;
    overflow: hidden;
}

.video-link {
    display: inline-block;
    position: relative;
    border-radius: 24px;
    overflow: hidden;
}

.video-link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #000;
    opacity: 0.6;
}

.video-link-img {
    max-width: 360px;
    height: auto;
}

.video-link-icn {
    width: 44px;
    height: 44px;
    opacity: 0.7;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -22px;
    margin-top: -22px;
}

.video-link-icn::before {
    width: 100%;
    height: 100%;
    background-size: 100% auto;
}

.profile-block {
    font-size: 20px;
    font-weight: 600;
    max-width: 460px;
}



.order-points-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-points-price {
    font-size: 20px;
}

.order-points-value {
    font-family: 'Montserrat';
    font-style: italic;
    font-style: 16px;
    font-weight: 800;
}

.order-points-caption {
    font-style: 16px;
    opacity: 0.5;
        font-style: normal;
}

.order-list {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
}

.order-card {
    border-radius: 24px;
    padding: 18px;
    border: 1px solid rgba(0, 0, 0, .1);
    position: relative;
}

.order-card-link {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: block;
}

.order-card-date {
    font-size: 14px;
    opacity: 0.5;
}

.order-card-p {
    margin-bottom: 4px;
}

.order-card-header {
    display: flex;
    justify-content: space-between;
}

.order-card-body {
    margin-bottom: 8px;
}

.nav-pills {
    padding: 8px;
    background: #f2f2f2;
    border-radius: 40px;
}

.nav-pills-nav-link {
    padding: 10px;
    font-weight: 500;
}

.custom-list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    gap: 16px;
}

.custom-list-item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 20px 25px;
}

.custom-list-item-icn::before {
    top: 0;
}

.table-striped-tr-td {
    border: none;
}

.badge-count {
    background: #d21100;
    color: #fff;
    font-size: 10px;
        text-align: center;
    font-weight: 500;
    top: -12px;
    left: -6px;
    padding: 0px 3px 1px;
    border-radius: 16px;
}

.form-switch-form-check-input:checked+form-check-label {
    color: #d21100;
    opacity: 1;
}


    .white-box {
        padding: 50px 35px;
    }

    .nav-box {
        width: 220px;
        padding: 10px 10px 25px;
    }

    .nav-box-nav-link {
        padding: 15px 10px;
    }

    .container-custom {
        padding: 0px 0px 0px 220px;
    }




    /*** style ***/
    .html,
    .body {
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .container-fluid,
    .container {
        padding-right: 4px;
        padding-left: 4px;
    }

    .navbar,
    .sitemap-menu {
        display: none;
    }

    .nav-box {
        bottom: 0px;
        width: 100%;
        padding: 0px;
        height: 72px;
        border-radius: 24px 24px 0px 0px;
        box-shadow: 0px -5px 26px rgba(0, 0, 0, 0.07);
    }

    .nav-box-icn {
        display: block;
        margin: 0 auto 0.3333333333rem;
        text-align: center;
    }

    .nav-box-nav {
        flex-direction: unset;
        flex-wrap: nowrap;
        justify-content: space-around;
    }

    .nav-box-nav-link {
        padding: 1rem 10px;
        font-size: 12px;
        position: relative;
    }

    .nav-box-badge-count {
        position: absolute;
        padding: 1px 3px 3px;
        right: 12px;
        top: 12px;
        left: auto;
    }

    .navbar-mobile {
        display: block;
        height: 84px;
        padding: 16px;
    }

    .navbar-mobile-h1,
    .navbar-mobile-h2,
    .navbar-mobile-h3,
    .navbar-mobile-h4,
    .navbar-mobile-h5 {
        margin: 0;
        text-align: center;
    }

    .container-custom {
        padding: 0px;
    }

    .container-custom>.container-fluid {
        margin: 0px;
        padding: 0px;
    }

    .page-container {
        padding: 0px 1rem;
    }

    .btn {
        font-size: 14px;
        padding: 0.25rem 1rem;
    }

    .btn-lg,
    .btn-group-lg-btn {
        width: 100%;
        padding: 1rem 1rem;
        font-size: 14px;
        border-radius: 4rem;
    }

    .list-group-flush-list-group-item {
        padding: 1.5rem 0;
    }

    .list-group-sm.list-group-flush-list-group-item {
        padding: 1rem 0;
    }

    .form-control,
    .list-group-item {
        background: transparent;
    }

    .body {
        font-size: 14px;
            }

    .h1,
    .h2 {
        margin-bottom: 1.5rem;
        text-align: center;
    }

    .h1,
    .h2,
    .h3 {
        font-size: 20px;
    }

    .h5,
    .h4 {
        font-size: 16px;
    }

    .p {
        margin-bottom: 1rem;
            }

    .badge {
        padding: 4px 8px;
        font-size: 12px;
    }

    .mobile-box>div:not(class) {
        height: 100%;
    }

    .mobile-box-navbar-mobile {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        margin-top: 36px;
    }

    .mobile-box-page {
        padding-top: 120px;
        min-height: 100vh;
    }

    .mobile-box-page-white-box {
        min-height: calc(100vh - 120px);
        padding-bottom: 94px;
    }

    .mobile-box-main-page {
        background-position: center 220px;
    }

    .mobile-box-main-page-white-box {
        min-height: unset;
    }

    .white-box {
        margin: 0px;
        padding: 1rem;
        background: #fff;
        border-radius: 1.5rem 1.5rem 0 0;
        min-height: 100%;
    }

    .white-box-preview {
        height: 240px;
        margin-left: -1rem;
        margin-right: -1rem;
        margin-top: -1rem;
        margin-bottom: 1.5rem;
        border-radius: 1.5rem 1.5rem 0px 0px;
        overflow: hidden;
    }

    .white-box-preview-img {
        width: 100%;
        height: auto;
    }

    .black-box {
        padding: 5rem 1rem 1.5rem;
    }

    .black-box>.custom-box-footer {
        position: absolute;
        left: 0px;
        top: -1px;
        height: 40px;
    }

    .index-card {
        padding: 20px 16px;
        border: none;
        background-color: #fff;
        text-align: left;
    }

    .index-card-image {
        margin-bottom: 20px;
        height: 50px;
    }

    .index-card-title {
        font-weight: 600;
        font-size: 14px;
        margin: 8px 0 0 0;
    }

    .index-card-text {
        font-size: 12px;
        margin: 12px 0 0 0;
    }

    .index-card-cost {
        font-size: 16px;
        margin: 12px 0px 0px 0px;
    }

    .index-card-cost::before {
        width: 4px;
        height: 4px;
    }

    .points-block {
        border-radius: 24px;
        padding: 16px 20px;
    }

    .points-block-title {
        font-size: 20px;
    }

    .points-block-title-badge {
        position: relative;
        top: -2px;
    }

    .points-block-link {
        font-size: 12px;
    }

    .points-block-val {
        font-size: 20px;
    }

    .service-banner-h5,
    .service-banner-title {
        font-size: 14px;
        margin-bottom: 4px;
    }

    .service-banner-p {
        font-size: 12px;
            }

    .service-step {
        text-align: center;
    }

    .service-step-list {
        margin: 0px;
        justify-content: center;
    }

    .service-step-item {
        width: 33%;
        padding: 0px 5px;
        margin-bottom: 24px;
        font-size: 12px;
        min-height: 150px;
    }

    .service-step-item::after {
        display: none;
    }

    .service-step-preview {
        width: 76px;
        height: 76px;
        border-radius: 24px;
    }

    .service-step-preview-img {
        max-width: 35px;
        max-height: 40px;
    }

    .service-category-preview {
        margin-left: auto;
        margin-right: auto;
        border-radius: 1.5rem;
        width: 100%;
        max-width: 104px;
        height: 104px;
        padding: 0.5rem;
    }

    .service-category-preview-img {
        height: 50px;
    }

    .service-category-preview-img {
        max-width: 50px;
        max-height: 50px;
    }

    .service-category-title {
        font-weight: 600;
        font-size: 12px;
        margin-top: 1rem;
    }

    .col-4-service-category-preview {
        width: 104px;
        height: 104px;
    }

    .news-card {
        width: 132px;
        height: 132px;
        border-radius: 1rem;
        padding: 1rem;
    }

    .news-card-date {
        font-size: 12px;
                position: relative;
    }

    .news-card-title {
        font-weight: 600;
                position: relative;
    }

    .profile-block {
        font-size: 14px;
        font-weight: 600;
    }

    .card-address {
        padding: 0;
    }

    .card-address-footer {
        margin-top: 1rem;
    }

    .city-item {
        background: #fff;
        border: none;
        padding: 12px 12px 172px 65px;
        border-radius: 1.5rem;
    }

    .city-item::before {
        left: 1.5rem;
    }

    .city-item-map {
        height: 160px;
    }

    .service-list {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }

    .service-category-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 0.5rem;
        grid-row-gap: 0.5rem;
    }

    .service-card {
        padding-left: 86px;
    }

    .service-card-body {
        font-size: 12px;
            }

    .service-card-preview {
        width: 74px;
    }

    .service-card-p {
        margin-bottom: 0.25rem;
    }

    .service-card-badge {
        font-size: 10px;
            }

    .points-block-text {
        opacity: 0.5;
        font-size: 12px;
                margin-top: 0.5rem;
    }

    .gallery-block {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 0.5rem;
        grid-row-gap: 0.5rem;
    }

    .gallery-block-figure {
        width: 104px;
        height: 104px;
    }

    .gallery-block-figure-img {
        max-height: 100%;
    }

    .video-link {
        width: 100%;
        height: 200px;
    }

    .video-link-img {
        width: 100%;
        max-width: none;
    }

    .section-block {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .form-photo-label {
        width: 104px;
        height: 104px;
        padding: 20px 8px;
        font-size: 12px;
    }

    .notification-card-h5,
    .notification-card-title {
        font-size: 14px;
                margin-bottom: 0.25rem;
        position: relative;
    }

    .notification-card-h5-ellipse,
    .notification-card-title-ellipse {
        position: absolute;
        left: -1.5rem;
        top: 3px;
        opacity: 0.5;
    }

    .notification-card-p,
    .notification-card-text {
        font-size: 12px;
                margin-bottom: 0.5rem;
    }

    .notification-card-body {
        font-size: 12px;
                opacity: 0.7;
    }

    .notification-card-small,
    .notification-card-caption {
        font-size: 10px;
    }

    .table-striped {
        margin-left: -1rem;
        margin-right: -1rem;
        width: calc(100% + 32px);
    }

    .cashbox-map {
        height: calc(100vh - 215px);
        margin-left: -16px;
        margin-right: -16px;
        position: relative;
    }

    .cashbox-map>div {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .cashbox-map>div>ymaps {
        width: 100%;
        height: 100%;
    }

    .faq-item-link {
        padding: 12px 12px 12px 24px;
    }

    .order-list {
        column-gap: 1rem;
        row-gap: 1rem;
    }

    .order-card-h5,
    .order-card-title {
        font-size: 14px;
        font-weight: 600;
    }

    .order-card-body,
    .order-card-p {
        font-size: 12px;
            }

    .order-card-date {
        font-size: 12px;
    }

    .marketplace-page-service-category-list {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 0.5rem;
        grid-row-gap: 0.5rem;
    }

    .marketplace-page-service-category-img {
        height: 82px;
        display: flex;
        align-items: center;
    }

    .marketplace-page-service-category-preview {
        width: 100%;
        height: 100%;
        min-height: 160px;
        max-width: unset;
        padding: 0.5rem;
    }

    .marketplace-page-service-category-preview-img {
        max-width: 92px;
        max-height: 82px;
    }

    .marketplace-page-service-category-preview-service-category-title {
        font-size: 14px;
    }

`)

for (var item in stylesList) {
    for (var key in stylesList[item]) {
        var value = stylesList[item][key]

        switch (key) {
            case 'flexDirection':
            case 'gap':
            case 'listStyle':
            case 'fontStyle':
            case 'gridTemplateColumns':
            case 'gridColumnGap':
            case 'columnGap':
            case 'rowGap':
            case 'gridRowGap':
            case 'borderLeft':
            case 'borderRight':
            case 'borderTop':
            case 'borderBottom':
            case 'backgroundSize':
            case 'backgroundPosition':
            case 'backgroundImage':
            case 'backgroundRepeat':
                delete stylesList[item][key]
                break
            case 'color':
                if (value === 'inherit') delete stylesList[item][key]
                break
            case 'position':
                if (value === 'fixed') delete stylesList[item][key]
                break
            case 'textTransform':
                if (value === 'unset') stylesList[item][key] = 'none'
                break
            case 'display':
                if (value !== 'none') stylesList[item][key] = 'flex'
                break
            // case 'color':
            //     break;
            default:
                break
        }
    }
}

let appendStyle = {
    line: {
        height: 1,
        backgroundColor: '#ebebeb',
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    rowOut: {
        marginRight: -15,
        marginLeft: -15,
    },
    box: {
        backgroundColor: '#FFFFFF',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        // borderRadius: 32,
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        // borderTopLeftRadius: 12,
        // borderTopRightRadius: 12,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,

        paddingHorizontal: 16,
        paddingVertical: 16,
    },
    boxHeader: {
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        backgroundColor: '#FFFFFF',
        // height: 24,
        height: 0,
    },
    ellipse: {
        width: 8,
        height: 8,
        marginRight: 4,
        // flex: 1,
        // display: "flex",
        borderRadius: 8,
        backgroundColor: '#f2f2f2',
    },
    textLarge: {
        fontSize: 28,
        fontWeight: '600',
    },
    p: {
        fontSize: 14,
        lineHeight: 21,
        marginBottom: 16,
    },
}

let newStyleList = {
    ...stylesList,
    ...appendStyle,
}

export const style = StyleSheet.create(newStyleList)
