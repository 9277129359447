import React, { useEffect } from 'react'
import AsyncStorage from '../../services/storage'
import api from '../../services/api'
import { setCityList } from '../../store/actions/UserActions'
import { connect } from 'react-redux'

const Container = ({ getCities }) => {
    useEffect(() => {
        getCities()

        AsyncStorage.getItem('@access_token').then((token) => {
            if (token) location.href = '/'
        })
    }, [])

    return <></>
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
})

let mapDispatchToProps = (dispatch) => {
    return {
        getCities: () => {
            api.fetch(`cities`, {
                'paginator[limit]': 100,
                'paginator[page]': 1,
            }).then((response) => {
                dispatch(setCityList(response.data.items))
            })
        },
    }
}

export const AuthRedirect = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
