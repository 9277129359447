import React from 'react'

import { MarketplaceCard } from '../Marketplace/MarketplaceCard'
import { NavLink } from 'react-router-dom'

export const CartView = ({
    // services,
    countAll,
    sumAll,
    basket,
    removeFromCart,
    changeQuantity,
    // onSubmit,
}) => {
    return (
        <div>
            <div className="service-list mb-5">
                {/* {services &&
                    services.map((service) => (
                        <MarketplaceCard
                            key={service.id.toString()}
                            item={service}
                            price={service.price}
                            quantity={service.count}
                            removeFromCart={removeFromCart}
                            changeQuantity={changeQuantity}
                        />
                    ))} */}
                {basket &&
                    basket.map((item) => (
                        <MarketplaceCard
                            key={item.id.toString()}
                            item={item.service_option.service}
                            price={item.service_option.price}
                            quantity={item.unit_count}
                            removeFromCart={removeFromCart}
                            changeQuantity={(service, quantity) =>
                                changeQuantity(item, quantity)
                            }
                        />
                    ))}
            </div>

            <div className="row">
                <div className="col-xl-6">
                    <div className="flex-between mb-3">
                        <div>
                            <b>Услуг в заказе:</b>
                        </div>
                        <div>x{countAll}</div>
                    </div>

                    <div className="flex-between mb-5">
                        <div>
                            <b>Сумма:</b>
                        </div>
                        <div className="h4">{sumAll}₽</div>
                    </div>

                    {countAll ? (
                        // <button
                        //     className="btn btn-lg btn-danger w-100"
                        //     onClick={onSubmit}
                        // >
                        //     Оформить
                        // </button>
                        <NavLink
                            to={`/cart/order`}
                            className="btn btn-lg btn-danger w-100"
                        >
                            Оформить
                        </NavLink>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

// import React from 'react'
// import { Text, View, ScrollView, StyleSheet } from 'react-native'
// import { MarketplaceCard } from '../Marketplace/MarketplaceCard'
// import { c, s } from '../../helpers/style'
// import { style } from '../../styles/mainStyles'
// import { Btn } from '../Btn'

// export const CartView = ({
//     services,
//     countAll,
//     sumAll,
//     removeFromCart,
//     changeQuantity,
// }) => {
//     return <div>CartView</div>
// }

// const styles = StyleSheet.create({
//     box: {
//         backgroundColor: c.WHITE,
//         borderTopLeftRadius: c.SPACER * 1.5,
//         borderTopEndRadius: c.SPACER * 1.5,
//     },
//     row: {
//         flexDirection: 'row',
//         alignItems: 'center',
//         justifyContent: 'space-between',
//     },
//     sum: {
//         fontSize: c.REM * 1.25,
//     },
// })

// export const CartView = ({
//     services,
//     countAll,
//     sumAll,
//     removeFromCart,
//     changeQuantity,
// }) => {
//     return (
//         <View style={{ flex: 1 }}>
//             <ScrollView>
//                 <View style={[style.boxContainer, s.mb3, s.pt2]}>
//                     {services && services.length
//                         ? services.map((service) => (
//                               <View key={service.id.toString()} style={s.pb2}>
//                                   <MarketplaceCard
//                                       item={service}
//                                       price={service.price}
//                                       quantity={service.count}
//                                       removeFromCart={removeFromCart}
//                                       changeQuantity={changeQuantity}
//                                   />
//                               </View>
//                           ))
//                         : null}
//                 </View>
//             </ScrollView>

//             <View style={[style.boxContainer, s.pt5, s.pb5, styles.box]}>
//                 <View style={[s.pb3, styles.row]}>
//                     <Text style={style.b}>Услуг в заказе:</Text>
//                     <Text>x{countAll}</Text>
//                 </View>

//                 <View style={[s.pb4, styles.row]}>
//                     <Text style={style.b}>Сумма:</Text>
//                     <Text style={styles.sum}>{sumAll}₽</Text>
//                 </View>

//                 {countAll ? (
//                     <Btn color="btnDanger" onPress={() => alert('Оформить')}>
//                         Оформить
//                     </Btn>
//                 ) : null}
//             </View>
//         </View>
//     )
// }
