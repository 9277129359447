import React, { useEffect } from 'react'
import { Navbar } from '../components/Navbar'
import { Menu } from '../components/Menu'
import { connect } from 'react-redux'
import AsyncStorage from '../services/storage'
import { authUser } from '../store/actions/UserActions'
import { Footer } from '../components/Footer'

const ThemeProviderComponent = ({ children, authUser, isAuth }) => {
    useEffect(() => {
        if (!isAuth)
            AsyncStorage.multiGet(['@access_token', '@refresh_token']).then(
                (storageData) => {
                    let authData = {}

                    if (storageData && storageData.length === 2) {
                        storageData.map((item) => {
                            if (item && item.length === 2)
                                authData[item[0].replace('@', '')] = item[1]
                        })
                    }

                    if (authData.access_token && authData.refresh_token)
                        authUser(authData)
                }
            )
    }, [])

    if (isAuth)
        return (
            <div>
                <Navbar />

                <div className="container-custom">
                    <Menu />

                    <div className="container-fluid container-fix">
                        {children}
                        <Footer />
                    </div>
                </div>
            </div>
        )
    return (
        <div className="container-wrap login-bg">
            <div className="container-fluid container-fix">{children}</div>
        </div>
    )
}

let mapStateToProps = (state) => ({
    isAuth: state.user.access_token,
    refreshToken: state.user.refresh_token,
})

let mapDispatchToProps = (dispatch) => {
    return {
        authUser(token) {
            switch (token) {
                case null:
                case false:
                case undefined:
                case 'null':
                case 'false':
                case 'undefined':
                    dispatch(authUser(null))
                    break

                default:
                    dispatch(authUser(token))
                    break
            }
        },
    }
}

const ThemeProvider = connect(
    mapStateToProps,
    mapDispatchToProps
)(ThemeProviderComponent)
export { ThemeProvider }
