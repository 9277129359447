import React, { useState, useEffect } from 'react'
import api from '../../services/api'
import { HousesList } from '../../components/Houses/HousesList'

export const AreaHousesContainer = ({ match, route }) => {
    const [response, setResponse] = useState({})
    const { id } = match ? match.params : route.params

    const fetch = () => {
        api.fetch(`areas/${id}/houses`).then((response) => {
            setResponse(response.data)
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    return <HousesList response={response} />
}
