import * as React from 'react'
// import { NavLink } from 'react-router-dom'
import { PageHeader } from '../components/WebBlocks/PageHeader'
import { NewsBlockContainer } from '../containers/News/NewsBlockContainer'
import { MarketplaceBlockContainer } from '../containers/Services/MarketplaceBlockContainer'
import { PointsBlockContainer } from '../containers/Points/PointsBlockContainer'
import { IndexCardContainer } from '../containers/Index/IndexCardContainer'
import { SosBanner } from '../components/Contacts/SosBanner'

export const MainPage = () => {
    return (
        <div>
            <PageHeader title="Личный кабинет" />

            <div className="black-box no-img-bg mb-3">
                <MarketplaceBlockContainer />
            </div>

            <div className="row mb-3">
                <div className="col-12 col-xl-9">
                    <div className="white-box">
                        <h1>Личный кабинет</h1>

                        <div className="row">
                            <div className="col-6 col-xl-4 mb-3 mb-xl-0">
                                <IndexCardContainer type="danger" />
                            </div>

                            <div className="col-6 col-xl-4 mb-3 mb-xl-0">
                                <IndexCardContainer />
                            </div>

                            <div className="col-12 col-xl-4 mb-3 mb-xl-0">
                                <PointsBlockContainer />
                            </div>

                            {/* <div className="col-12 col-xl-4">
                                <div className="mb-4">
                                    <PointsBlockContainer />
                                </div>

                                <div className="text-center mb-4">
                                    <NavLink
                                        to="/profile"
                                        className="btn btn-lg btn-secondary w-100"
                                    >
                                        <i className="icn icn-chat-white"></i>
                                        Чат по дому
                                    </NavLink>
                                </div>

                                <div className="text-center">
                                    <NavLink
                                        to="/faq"
                                        className="btn btn-lg btn-secondary w-100"
                                    >
                                        <i className="icn icn-question-white"></i>
                                        Справка
                                    </NavLink>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-3">
                    <SosBanner />
                    {/* <div className="text-center mb-4">
                        <NavLink
                            to="/profile"
                            className="btn btn-lg btn-secondary w-100"
                        >
                            <i className="icn icn-chat-white"></i>
                            Чат по дому
                        </NavLink>
                    </div> */}
                </div>
            </div>

            <div className="white-box mb-3">
                <NewsBlockContainer />
            </div>
        </div>
    )
}
