import React from 'react'
// import { NavLink } from 'react-router-dom'
// import { View, Text, Linking } from 'react-native'
// import { s } from '../../helpers/style'
// import { style } from '../../styles/mainStyles'
// import { Pressable } from 'react-native'
// import Icon from 'react-native-vector-icons/Ionicons'

export const MyHome = ({ data }) => {
    let links = [
        { title: 'Тарифы', url: data.tariffs_link },
        { title: 'План текущего ремонта', url: data.repair_plan_link },
        { title: 'Отчеты', url: data.reports_link },
        { title: 'Документы', url: data.document_link },
    ]
    links.filter((item) => item.url)

    return (
        <div>
            <ul className="custom-list">
                {links.map((item, index) => (
                    <li key={index} className="custom-list-item">
                        <a
                            href={item.url}
                            className="custom-list-link"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {item.title}
                            <i className="fa-solid fa-up-right-from-square"></i>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}
