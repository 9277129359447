import * as React from 'react'
import moment from 'moment'

export const NewsCard = ({ item, onPress }) => (
    <div className="news-card" onClick={onPress}>
        <div
            className="news-card-image"
            style={{
                backgroundImage: item.preview_file
                    ? `url(${item.preview_file.path})`
                    : ``,
            }}
        ></div>
        <div className="news-card-date">
            {moment(item.created_at).format('DD.MM.YYYY')}
        </div>
        <div className="news-card-title">{item.title}</div>
        {item.short_text && (
            <div className="news-card-text">{item.short_text}</div>
        )}

        {item.city && <div className="news-card-badge">{item.city.title}</div>}
    </div>
)
