import React from 'react'
import { ActivityIndicator } from 'react-native'
import { connect } from 'react-redux'
import { DataAddressToString } from '../../helpers/dataHelper'
import { ManagmentView } from '../../components/Contacts/ManagmentView'

const Container = ({ company }) => {
    if (!company) return <ActivityIndicator color="#d21100" />

    const data = company
    const { timings } = company

    const actualAddress = DataAddressToString([
        data.actual_address_city,
        data.actual_address_street,
        data.actual_address_house,
        data.actual_address_building,
        data.actual_address_structure,
        data.actual_address_office,
    ])

    return (
        <ManagmentView
            data={data}
            timings={timings}
            actualAddress={actualAddress}
        />
    )
}

let mapStateToProps = (state) => ({
    city_id: state.user.city_id,
    company: state.user.company,
})

let mapDispatchToProps = () => {
    return {}
}

export const ManagmentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
