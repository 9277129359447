import React from 'react'
import { CartView } from '../../components/Cart/CartView'
import { connect } from 'react-redux'
import {
    addToCart,
    removeFromCart,
    changeQuantity,
} from '../../store/actions/CartActions'

const Container = (props) => {
    return <CartView {...props} />
}

let mapStateToProps = (state) => ({
    services: state.cart.services,
    basket: state.cart.basket,
    countAll: state.cart.countAll,
    sumAll: state.cart.sumAll,
})

let mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: () => {
            alert('onSubmit')
        },
        addToCart: (item) => {
            dispatch(addToCart(item))
        },
        removeFromCart: (item) => {
            dispatch(removeFromCart(item))
        },
        changeQuantity: (item, quantity) => {
            dispatch(changeQuantity(item, quantity))
        },
    }
}

export const CartContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
