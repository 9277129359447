import { StyleSheet } from 'react-native'

const bootstrapStyles = {
    fontWeightLight: { fontWeight: '300', fontFamily: 'System' },
    fontWeightNormal: { fontWeight: '400' },
    fontWeightBold: { fontWeight: '700', fontFamily: 'System' },
    fontItalic: { fontStyle: 'italic' },
    textBody: { color: '#212529' },
    textWhite: { color: '#fff' },
    textMuted: { color: '#6c757d' },
    textBlack50: { color: 'rgba(0, 0, 0, 0.5)' },
    textWhite50: { color: 'rgba(255, 255, 255, 0.5)' },
    textCenter: { textAlign: 'center' },
    textJustify: { textAlign: 'justify' },
    textSmall: { fontSize: 14 },
    textLarge: { fontSize: 20 },
    textSpaced: { letterSpacing: 1.2 },
    textMonospace: { fontFamily: 'monospace' },
    floatAuto: { textAlign: 'auto' },
    floatLeft: { textAlign: 'left' },
    floatRight: { textAlign: 'right' },
    floatCenter: { textAlign: 'center' },
    floatJustify: { textAlign: 'justify' },
    floatXsAuto: { textAlign: 'auto' },
    floatXsLeft: { textAlign: 'left' },
    floatXsRight: { textAlign: 'right' },
    floatXsCenter: { textAlign: 'center' },
    floatXsJustify: { textAlign: 'justify' },
    floatSmAuto: { textAlign: 'auto' },
    floatSmLeft: { textAlign: 'left' },
    floatSmRight: { textAlign: 'right' },
    floatSmCenter: { textAlign: 'center' },
    floatSmJustify: { textAlign: 'justify' },
    floatMdAuto: { textAlign: 'auto' },
    floatMdLeft: { textAlign: 'left' },
    floatMdRight: { textAlign: 'right' },
    floatMdCenter: { textAlign: 'center' },
    floatMdJustify: { textAlign: 'justify' },
    floatLgAuto: { textAlign: 'auto' },
    floatLgLeft: { textAlign: 'left' },
    floatLgRight: { textAlign: 'right' },
    floatLgCenter: { textAlign: 'center' },
    floatLgJustify: { textAlign: 'justify' },
    textPrimary: { color: '#3278B9' },
    textPrimaryLight: { color: '#C6D9EB' },
    textPrimaryDark: { color: '#0E2234' },
    textPrimaryLightest: { color: '#E6EFF7' },
    textPrimaryDarkest: { color: '#E6EFF7' },
    textSecondary: { color: '#555860' },
    textSecondaryLight: { color: '#CFD0D2' },
    textSecondaryDark: { color: '#18191B' },
    textSecondaryLightest: { color: '#EBEBEC' },
    textSecondaryDarkest: { color: '#EBEBEC' },
    textSuccess: { color: '#4DBE25' },
    textSuccessLight: { color: '#CDEDC2' },
    textSuccessDark: { color: '#16350A' },
    textSuccessLightest: { color: '#EAF7E5' },
    textSuccessDarkest: { color: '#EAF7E5' },
    textInfo: { color: '#17a2b8' },
    textInfoLight: { color: '#BEE5EB' },
    textInfoDark: { color: '#062D34' },
    textInfoLightest: { color: '#E3F4F6' },
    textInfoDarkest: { color: '#E3F4F6' },
    textWarning: { color: '#FCAB00' },
    textWarningLight: { color: '#FEE7B8' },
    textWarningDark: { color: '#473000' },
    textWarningLightest: { color: '#FFF5E0' },
    textWarningDarkest: { color: '#FFF5E0' },
    textDanger: { color: '#D21100' },
    textDangerLight: { color: '#F2BCB8' },
    textDangerDark: { color: '#3B0500' },
    textDangerLightest: { color: '#FAE2E0' },
    textDangerDarkest: { color: '#FAE2E0' },
    textLight: { color: '#F2F2F2' },
    textLightLight: { color: '#FBFBFB' },
    textLightDark: { color: '#444444' },
    textLightLightest: { color: '#FDFDFD' },
    textLightDarkest: { color: '#FDFDFD' },
    textDark: { color: '#302528' },
    textDarkLight: { color: '#C5C2C3' },
    textDarkDark: { color: '#0D0A0B' },
    textDarkLightest: { color: '#E6E5E5' },
    textDarkDarkest: { color: '#E6E5E5' },
    bgWhite: { backgroundColor: '#fff' },
    bgTransparent: { backgroundColor: 'transparent' },
    bgPrimary: { backgroundColor: '#3278B9' },
    bgPrimaryLight: { backgroundColor: '#C6D9EB' },
    bgPrimaryDark: { backgroundColor: '#0E2234' },
    bgPrimaryLightest: { backgroundColor: '#E6EFF7' },
    bgPrimaryDarkest: { backgroundColor: '#E6EFF7' },
    bgSecondary: { backgroundColor: '#555860' },
    bgSecondaryLight: { backgroundColor: '#CFD0D2' },
    bgSecondaryDark: { backgroundColor: '#18191B' },
    bgSecondaryLightest: { backgroundColor: '#EBEBEC' },
    bgSecondaryDarkest: { backgroundColor: '#EBEBEC' },
    bgSuccess: { backgroundColor: '#4DBE25' },
    bgSuccessLight: { backgroundColor: '#CDEDC2' },
    bgSuccessDark: { backgroundColor: '#16350A' },
    bgSuccessLightest: { backgroundColor: '#EAF7E5' },
    bgSuccessDarkest: { backgroundColor: '#EAF7E5' },
    bgInfo: { backgroundColor: '#17a2b8' },
    bgInfoLight: { backgroundColor: '#BEE5EB' },
    bgInfoDark: { backgroundColor: '#062D34' },
    bgInfoLightest: { backgroundColor: '#E3F4F6' },
    bgInfoDarkest: { backgroundColor: '#E3F4F6' },
    bgWarning: { backgroundColor: '#FCAB00' },
    bgWarningLight: { backgroundColor: '#FEE7B8' },
    bgWarningDark: { backgroundColor: '#473000' },
    bgWarningLightest: { backgroundColor: '#FFF5E0' },
    bgWarningDarkest: { backgroundColor: '#FFF5E0' },
    bgDanger: { backgroundColor: '#D21100' },
    bgDangerLight: { backgroundColor: '#F2BCB8' },
    bgDangerDark: { backgroundColor: '#3B0500' },
    bgDangerLightest: { backgroundColor: '#FAE2E0' },
    bgDangerDarkest: { backgroundColor: '#FAE2E0' },
    bgLight: { backgroundColor: '#F2F2F2' },
    bgLightLight: { backgroundColor: '#FBFBFB' },
    bgLightDark: { backgroundColor: '#444444' },
    bgLightLightest: { backgroundColor: '#FDFDFD' },
    bgLightDarkest: { backgroundColor: '#FDFDFD' },
    bgDark: { backgroundColor: '#302528' },
    bgDarkLight: { backgroundColor: '#C5C2C3' },
    bgDarkDark: { backgroundColor: '#0D0A0B' },
    bgDarkLightest: { backgroundColor: '#E6E5E5' },
    bgDarkDarkest: { backgroundColor: '#E6E5E5' },
    justifyContentStart: { justifyContent: 'flex-start' },
    justifyContentEnd: { justifyContent: 'flex-end' },
    justifyContentCenter: { justifyContent: 'center' },
    justifyContentBetween: { justifyContent: 'space-between' },
    justifyContentAround: { justifyContent: 'space-around' },
    alignItemsStart: { alignItems: 'flex-start' },
    alignItemsEnd: { alignItems: 'flex-end' },
    alignItemsCenter: { alignItems: 'center' },
    alignItemsBaseline: { alignItems: 'baseline' },
    alignItemsStretch: { alignItems: 'stretch' },
    alignContentStart: { alignContent: 'flex-start' },
    alignContentEnd: { alignContent: 'flex-end' },
    alignContentCenter: { alignContent: 'center' },
    alignContentBetween: { alignContent: 'space-between' },
    alignContentAround: { alignContent: 'space-around' },
    alignContentStretch: { alignContent: 'stretch' },
    alignSelfAuto: { alignSelf: 'auto' },
    alignSelfStart: { alignSelf: 'flex-start' },
    alignSelfEnd: { alignSelf: 'flex-end' },
    alignSelfCenter: { alignSelf: 'center' },
    alignSelfBaseline: { alignSelf: 'baseline' },
    alignSelfStretch: { alignSelf: 'stretch' },
    flex1: { flex: 1 },
    flex2: { flex: 2 },
    flex3: { flex: 3 },
    flex4: { flex: 4 },
    flex5: { flex: 5 },
    flex6: { flex: 6 },
    flex7: { flex: 7 },
    flex8: { flex: 8 },
    flex9: { flex: 9 },
    flex10: { flex: 10 },
    flex11: { flex: 11 },
    flex12: { flex: 12 },
    flex13: { flex: 13 },
    flex: { flex: 1 },
    flexRow: { flexDirection: 'row' },
    flexColumn: { flexDirection: 'column' },
    flexRowReverse: { flexDirection: 'row-reverse' },
    flexColumnReverse: { flexDirection: 'column-reverse' },
    flexWrap: { flexWrap: 'wrap' },
    flexNowrap: { flexWrap: 'nowrap' },
    flexGrow0: { flexGrow: 0 },
    flexShrink0: { flexShrink: 0 },
    flexShrink1: { flexShrink: 1 },
    flexXs1: { flex: 1 },
    flexXs2: { flex: 2 },
    flexXs3: { flex: 3 },
    flexXs4: { flex: 4 },
    flexXs5: { flex: 5 },
    flexXs6: { flex: 6 },
    flexXs7: { flex: 7 },
    flexXs8: { flex: 8 },
    flexXs9: { flex: 9 },
    flexXs10: { flex: 10 },
    flexXs11: { flex: 11 },
    flexXs12: { flex: 12 },
    flexXs13: { flex: 13 },
    flexXs: { flex: 1 },
    flexXsRow: { flexDirection: 'row' },
    flexXsColumn: { flexDirection: 'column' },
    flexXsRowReverse: { flexDirection: 'row-reverse' },
    flexXsColumnReverse: { flexDirection: 'column-reverse' },
    flexXsWrap: { flexWrap: 'wrap' },
    flexXsNowrap: { flexWrap: 'nowrap' },
    flexXsGrow0: { flexGrow: 0 },
    flexXsShrink0: { flexShrink: 0 },
    flexXsShrink1: { flexShrink: 1 },
    flexSm1: { flex: 1 },
    flexSm2: { flex: 2 },
    flexSm3: { flex: 3 },
    flexSm4: { flex: 4 },
    flexSm5: { flex: 5 },
    flexSm6: { flex: 6 },
    flexSm7: { flex: 7 },
    flexSm8: { flex: 8 },
    flexSm9: { flex: 9 },
    flexSm10: { flex: 10 },
    flexSm11: { flex: 11 },
    flexSm12: { flex: 12 },
    flexSm13: { flex: 13 },
    flexSm: { flex: 1 },
    flexSmRow: { flexDirection: 'row' },
    flexSmColumn: { flexDirection: 'column' },
    flexSmRowReverse: { flexDirection: 'row-reverse' },
    flexSmColumnReverse: { flexDirection: 'column-reverse' },
    flexSmWrap: { flexWrap: 'wrap' },
    flexSmNowrap: { flexWrap: 'nowrap' },
    flexSmGrow0: { flexGrow: 0 },
    flexSmShrink0: { flexShrink: 0 },
    flexSmShrink1: { flexShrink: 1 },
    flexMd1: { flex: 1 },
    flexMd2: { flex: 2 },
    flexMd3: { flex: 3 },
    flexMd4: { flex: 4 },
    flexMd5: { flex: 5 },
    flexMd6: { flex: 6 },
    flexMd7: { flex: 7 },
    flexMd8: { flex: 8 },
    flexMd9: { flex: 9 },
    flexMd10: { flex: 10 },
    flexMd11: { flex: 11 },
    flexMd12: { flex: 12 },
    flexMd13: { flex: 13 },
    flexMd: { flex: 1 },
    flexMdRow: { flexDirection: 'row' },
    flexMdColumn: { flexDirection: 'column' },
    flexMdRowReverse: { flexDirection: 'row-reverse' },
    flexMdColumnReverse: { flexDirection: 'column-reverse' },
    flexMdWrap: { flexWrap: 'wrap' },
    flexMdNowrap: { flexWrap: 'nowrap' },
    flexMdGrow0: { flexGrow: 0 },
    flexMdShrink0: { flexShrink: 0 },
    flexMdShrink1: { flexShrink: 1 },
    flexLg1: { flex: 1 },
    flexLg2: { flex: 2 },
    flexLg3: { flex: 3 },
    flexLg4: { flex: 4 },
    flexLg5: { flex: 5 },
    flexLg6: { flex: 6 },
    flexLg7: { flex: 7 },
    flexLg8: { flex: 8 },
    flexLg9: { flex: 9 },
    flexLg10: { flex: 10 },
    flexLg11: { flex: 11 },
    flexLg12: { flex: 12 },
    flexLg13: { flex: 13 },
    flexLg: { flex: 1 },
    flexLgRow: { flexDirection: 'row' },
    flexLgColumn: { flexDirection: 'column' },
    flexLgRowReverse: { flexDirection: 'row-reverse' },
    flexLgColumnReverse: { flexDirection: 'column-reverse' },
    flexLgWrap: { flexWrap: 'wrap' },
    flexLgNowrap: { flexWrap: 'nowrap' },
    flexLgGrow0: { flexGrow: 0 },
    flexLgShrink0: { flexShrink: 0 },
    flexLgShrink1: { flexShrink: 1 },
    shadow: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 8 },
        shadowOpacity: 0.125,
        shadowRadius: 12,
    },
    shadowSm: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
    },
    shadowLg: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 16 },
        shadowOpacity: 0.15,
        shadowRadius: 24,
    },
    shadowNone: {
        elevation: 0,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0,
        shadowRadius: 0,
    },
    mw100: { maxWidth: '100%' },
    mh100: { maxHeight: '100%' },
    w5: { width: '5%' },
    w10: { width: '20%' },
    w15: { width: '15%' },
    w20: { width: '20%' },
    w25: { width: '25%' },
    w30: { width: '30%' },
    w35: { width: '35%' },
    w40: { width: '40%' },
    w45: { width: '45%' },
    w50: { width: '50%' },
    w55: { width: '55%' },
    w60: { width: '60%' },
    w65: { width: '65%' },
    w70: { width: '70%' },
    w75: { width: '75%' },
    w80: { width: '80%' },
    w85: { width: '85%' },
    w90: { width: '90%' },
    w95: { width: '95%' },
    w100: { width: '100%' },
    wAuto: { width: 'auto' },
    h5: {
        fontSize: 20,
        marginBottom: 8,
        fontFamily: 'System',
        fontWeight: '400',
        color: '#212529',
        lineHeight: 24,
    },
    h10: { height: '20%' },
    h15: { height: '15%' },
    h20: { height: '20%' },
    h25: { height: '25%' },
    h30: { height: '30%' },
    h35: { height: '35%' },
    h40: { height: '40%' },
    h45: { height: '45%' },
    h50: { height: '50%' },
    h55: { height: '55%' },
    h60: { height: '60%' },
    h65: { height: '65%' },
    h70: { height: '70%' },
    h75: { height: '75%' },
    h80: { height: '80%' },
    h85: { height: '85%' },
    h90: { height: '90%' },
    h95: { height: '95%' },
    h100: { height: '100%' },
    hAuto: { height: 'auto' },
    wXs5: { width: '5%' },
    wXs10: { width: '20%' },
    wXs15: { width: '15%' },
    wXs20: { width: '20%' },
    wXs25: { width: '25%' },
    wXs30: { width: '30%' },
    wXs35: { width: '35%' },
    wXs40: { width: '40%' },
    wXs45: { width: '45%' },
    wXs50: { width: '50%' },
    wXs55: { width: '55%' },
    wXs60: { width: '60%' },
    wXs65: { width: '65%' },
    wXs70: { width: '70%' },
    wXs75: { width: '75%' },
    wXs80: { width: '80%' },
    wXs85: { width: '85%' },
    wXs90: { width: '90%' },
    wXs95: { width: '95%' },
    wXs100: { width: '100%' },
    wXsAuto: { width: 'auto' },
    hXs5: { height: '5%' },
    hXs10: { height: '20%' },
    hXs15: { height: '15%' },
    hXs20: { height: '20%' },
    hXs25: { height: '25%' },
    hXs30: { height: '30%' },
    hXs35: { height: '35%' },
    hXs40: { height: '40%' },
    hXs45: { height: '45%' },
    hXs50: { height: '50%' },
    hXs55: { height: '55%' },
    hXs60: { height: '60%' },
    hXs65: { height: '65%' },
    hXs70: { height: '70%' },
    hXs75: { height: '75%' },
    hXs80: { height: '80%' },
    hXs85: { height: '85%' },
    hXs90: { height: '90%' },
    hXs95: { height: '95%' },
    hXs100: { height: '100%' },
    hXsAuto: { height: 'auto' },
    wSm5: { width: '5%' },
    wSm10: { width: '20%' },
    wSm15: { width: '15%' },
    wSm20: { width: '20%' },
    wSm25: { width: '25%' },
    wSm30: { width: '30%' },
    wSm35: { width: '35%' },
    wSm40: { width: '40%' },
    wSm45: { width: '45%' },
    wSm50: { width: '50%' },
    wSm55: { width: '55%' },
    wSm60: { width: '60%' },
    wSm65: { width: '65%' },
    wSm70: { width: '70%' },
    wSm75: { width: '75%' },
    wSm80: { width: '80%' },
    wSm85: { width: '85%' },
    wSm90: { width: '90%' },
    wSm95: { width: '95%' },
    wSm100: { width: '100%' },
    wSmAuto: { width: 'auto' },
    hSm5: { height: '5%' },
    hSm10: { height: '20%' },
    hSm15: { height: '15%' },
    hSm20: { height: '20%' },
    hSm25: { height: '25%' },
    hSm30: { height: '30%' },
    hSm35: { height: '35%' },
    hSm40: { height: '40%' },
    hSm45: { height: '45%' },
    hSm50: { height: '50%' },
    hSm55: { height: '55%' },
    hSm60: { height: '60%' },
    hSm65: { height: '65%' },
    hSm70: { height: '70%' },
    hSm75: { height: '75%' },
    hSm80: { height: '80%' },
    hSm85: { height: '85%' },
    hSm90: { height: '90%' },
    hSm95: { height: '95%' },
    hSm100: { height: '100%' },
    hSmAuto: { height: 'auto' },
    wMd5: { width: '5%' },
    wMd10: { width: '20%' },
    wMd15: { width: '15%' },
    wMd20: { width: '20%' },
    wMd25: { width: '25%' },
    wMd30: { width: '30%' },
    wMd35: { width: '35%' },
    wMd40: { width: '40%' },
    wMd45: { width: '45%' },
    wMd50: { width: '50%' },
    wMd55: { width: '55%' },
    wMd60: { width: '60%' },
    wMd65: { width: '65%' },
    wMd70: { width: '70%' },
    wMd75: { width: '75%' },
    wMd80: { width: '80%' },
    wMd85: { width: '85%' },
    wMd90: { width: '90%' },
    wMd95: { width: '95%' },
    wMd100: { width: '100%' },
    wMdAuto: { width: 'auto' },
    hMd5: { height: '5%' },
    hMd10: { height: '20%' },
    hMd15: { height: '15%' },
    hMd20: { height: '20%' },
    hMd25: { height: '25%' },
    hMd30: { height: '30%' },
    hMd35: { height: '35%' },
    hMd40: { height: '40%' },
    hMd45: { height: '45%' },
    hMd50: { height: '50%' },
    hMd55: { height: '55%' },
    hMd60: { height: '60%' },
    hMd65: { height: '65%' },
    hMd70: { height: '70%' },
    hMd75: { height: '75%' },
    hMd80: { height: '80%' },
    hMd85: { height: '85%' },
    hMd90: { height: '90%' },
    hMd95: { height: '95%' },
    hMd100: { height: '100%' },
    hMdAuto: { height: 'auto' },
    wLg5: { width: '5%' },
    wLg10: { width: '20%' },
    wLg15: { width: '15%' },
    wLg20: { width: '20%' },
    wLg25: { width: '25%' },
    wLg30: { width: '30%' },
    wLg35: { width: '35%' },
    wLg40: { width: '40%' },
    wLg45: { width: '45%' },
    wLg50: { width: '50%' },
    wLg55: { width: '55%' },
    wLg60: { width: '60%' },
    wLg65: { width: '65%' },
    wLg70: { width: '70%' },
    wLg75: { width: '75%' },
    wLg80: { width: '80%' },
    wLg85: { width: '85%' },
    wLg90: { width: '90%' },
    wLg95: { width: '95%' },
    wLg100: { width: '100%' },
    wLgAuto: { width: 'auto' },
    hLg5: { height: '5%' },
    hLg10: { height: '20%' },
    hLg15: { height: '15%' },
    hLg20: { height: '20%' },
    hLg25: { height: '25%' },
    hLg30: { height: '30%' },
    hLg35: { height: '35%' },
    hLg40: { height: '40%' },
    hLg45: { height: '45%' },
    hLg50: { height: '50%' },
    hLg55: { height: '55%' },
    hLg60: { height: '60%' },
    hLg65: { height: '65%' },
    hLg70: { height: '70%' },
    hLg75: { height: '75%' },
    hLg80: { height: '80%' },
    hLg85: { height: '85%' },
    hLg90: { height: '90%' },
    hLg95: { height: '95%' },
    hLg100: { height: '100%' },
    hLgAuto: { height: 'auto' },
    m0: { margin: 0 },
    my0: { marginVertical: 0 },
    mx0: { marginHorizontal: 0 },
    mt0: { marginTop: 0 },
    mb0: { marginBottom: 0 },
    mr0: { marginRight: 0 },
    ml0: { marginLeft: 0 },
    p0: { padding: 0 },
    py0: { paddingVertical: 0 },
    px0: { paddingHorizontal: 0 },
    pt0: { paddingTop: 0 },
    pb0: { paddingBottom: 0 },
    pr0: { paddingRight: 0 },
    pl0: { paddingLeft: 0 },
    m1: { margin: 4 },
    my1: { marginVertical: 4 },
    mx1: { marginHorizontal: 4 },
    mt1: { marginTop: 4 },
    mb1: { marginBottom: 4 },
    mr1: { marginRight: 4 },
    ml1: { marginLeft: 4 },
    p1: { padding: 4 },
    py1: { paddingVertical: 4 },
    px1: { paddingHorizontal: 4 },
    pt1: { paddingTop: 4 },
    pb1: { paddingBottom: 4 },
    pr1: { paddingRight: 4 },
    pl1: { paddingLeft: 4 },
    m2: { margin: 8 },
    my2: { marginVertical: 8 },
    mx2: { marginHorizontal: 8 },
    mt2: { marginTop: 8 },
    mb2: { marginBottom: 8 },
    mr2: { marginRight: 8 },
    ml2: { marginLeft: 8 },
    p2: { padding: 8 },
    py2: { paddingVertical: 8 },
    px2: { paddingHorizontal: 8 },
    pt2: { paddingTop: 8 },
    pb2: { paddingBottom: 8 },
    pr2: { paddingRight: 8 },
    pl2: { paddingLeft: 8 },
    m3: { margin: 16 },
    my3: { marginVertical: 16 },
    mx3: { marginHorizontal: 16 },
    mt3: { marginTop: 16 },
    mb3: { marginBottom: 16 },
    mr3: { marginRight: 16 },
    ml3: { marginLeft: 16 },
    p3: { padding: 16 },
    py3: { paddingVertical: 16 },
    px3: { paddingHorizontal: 16 },
    pt3: { paddingTop: 16 },
    pb3: { paddingBottom: 16 },
    pr3: { paddingRight: 16 },
    pl3: { paddingLeft: 16 },
    m4: { margin: 24 },
    my4: { marginVertical: 24 },
    mx4: { marginHorizontal: 24 },
    mt4: { marginTop: 24 },
    mb4: { marginBottom: 24 },
    mr4: { marginRight: 24 },
    ml4: { marginLeft: 24 },
    p4: { padding: 24 },
    py4: { paddingVertical: 24 },
    px4: { paddingHorizontal: 24 },
    pt4: { paddingTop: 24 },
    pb4: { paddingBottom: 24 },
    pr4: { paddingRight: 24 },
    pl4: { paddingLeft: 24 },
    m5: { margin: 48 },
    my5: { marginVertical: 48 },
    mx5: { marginHorizontal: 48 },
    mt5: { marginTop: 48 },
    mb5: { marginBottom: 48 },
    mr5: { marginRight: 48 },
    ml5: { marginLeft: 48 },
    p5: { padding: 48 },
    py5: { paddingVertical: 48 },
    px5: { paddingHorizontal: 48 },
    pt5: { paddingTop: 48 },
    pb5: { paddingBottom: 48 },
    pr5: { paddingRight: 48 },
    pl5: { paddingLeft: 48 },
    mAuto: { margin: 'auto' },
    myAuto: { marginVertical: 'auto' },
    mxAuto: { marginHorizontal: 'auto' },
    mtAuto: { marginTop: 'auto' },
    mbAuto: { marginBottom: 'auto' },
    mrAuto: { marginRight: 'auto' },
    mlAuto: { marginLeft: 'auto' },
    mXs0: { margin: 0 },
    myXs0: { marginVertical: 0 },
    mxXs0: { marginHorizontal: 0 },
    mtXs0: { marginTop: 0 },
    mbXs0: { marginBottom: 0 },
    mrXs0: { marginRight: 0 },
    mlXs0: { marginLeft: 0 },
    pXs0: { padding: 0 },
    pyXs0: { paddingVertical: 0 },
    pxXs0: { paddingHorizontal: 0 },
    ptXs0: { paddingTop: 0 },
    pbXs0: { paddingBottom: 0 },
    prXs0: { paddingRight: 0 },
    plXs0: { paddingLeft: 0 },
    mXs1: { margin: 4 },
    myXs1: { marginVertical: 4 },
    mxXs1: { marginHorizontal: 4 },
    mtXs1: { marginTop: 4 },
    mbXs1: { marginBottom: 4 },
    mrXs1: { marginRight: 4 },
    mlXs1: { marginLeft: 4 },
    pXs1: { padding: 4 },
    pyXs1: { paddingVertical: 4 },
    pxXs1: { paddingHorizontal: 4 },
    ptXs1: { paddingTop: 4 },
    pbXs1: { paddingBottom: 4 },
    prXs1: { paddingRight: 4 },
    plXs1: { paddingLeft: 4 },
    mXs2: { margin: 8 },
    myXs2: { marginVertical: 8 },
    mxXs2: { marginHorizontal: 8 },
    mtXs2: { marginTop: 8 },
    mbXs2: { marginBottom: 8 },
    mrXs2: { marginRight: 8 },
    mlXs2: { marginLeft: 8 },
    pXs2: { padding: 8 },
    pyXs2: { paddingVertical: 8 },
    pxXs2: { paddingHorizontal: 8 },
    ptXs2: { paddingTop: 8 },
    pbXs2: { paddingBottom: 8 },
    prXs2: { paddingRight: 8 },
    plXs2: { paddingLeft: 8 },
    mXs3: { margin: 16 },
    myXs3: { marginVertical: 16 },
    mxXs3: { marginHorizontal: 16 },
    mtXs3: { marginTop: 16 },
    mbXs3: { marginBottom: 16 },
    mrXs3: { marginRight: 16 },
    mlXs3: { marginLeft: 16 },
    pXs3: { padding: 16 },
    pyXs3: { paddingVertical: 16 },
    pxXs3: { paddingHorizontal: 16 },
    ptXs3: { paddingTop: 16 },
    pbXs3: { paddingBottom: 16 },
    prXs3: { paddingRight: 16 },
    plXs3: { paddingLeft: 16 },
    mXs4: { margin: 24 },
    myXs4: { marginVertical: 24 },
    mxXs4: { marginHorizontal: 24 },
    mtXs4: { marginTop: 24 },
    mbXs4: { marginBottom: 24 },
    mrXs4: { marginRight: 24 },
    mlXs4: { marginLeft: 24 },
    pXs4: { padding: 24 },
    pyXs4: { paddingVertical: 24 },
    pxXs4: { paddingHorizontal: 24 },
    ptXs4: { paddingTop: 24 },
    pbXs4: { paddingBottom: 24 },
    prXs4: { paddingRight: 24 },
    plXs4: { paddingLeft: 24 },
    mXs5: { margin: 48 },
    myXs5: { marginVertical: 48 },
    mxXs5: { marginHorizontal: 48 },
    mtXs5: { marginTop: 48 },
    mbXs5: { marginBottom: 48 },
    mrXs5: { marginRight: 48 },
    mlXs5: { marginLeft: 48 },
    pXs5: { padding: 48 },
    pyXs5: { paddingVertical: 48 },
    pxXs5: { paddingHorizontal: 48 },
    ptXs5: { paddingTop: 48 },
    pbXs5: { paddingBottom: 48 },
    prXs5: { paddingRight: 48 },
    plXs5: { paddingLeft: 48 },
    mXsAuto: { margin: 'auto' },
    myXsAuto: { marginVertical: 'auto' },
    mxXsAuto: { marginHorizontal: 'auto' },
    mtXsAuto: { marginTop: 'auto' },
    mbXsAuto: { marginBottom: 'auto' },
    mrXsAuto: { marginRight: 'auto' },
    mlXsAuto: { marginLeft: 'auto' },
    mSm0: { margin: 0 },
    mySm0: { marginVertical: 0 },
    mxSm0: { marginHorizontal: 0 },
    mtSm0: { marginTop: 0 },
    mbSm0: { marginBottom: 0 },
    mrSm0: { marginRight: 0 },
    mlSm0: { marginLeft: 0 },
    pSm0: { padding: 0 },
    pySm0: { paddingVertical: 0 },
    pxSm0: { paddingHorizontal: 0 },
    ptSm0: { paddingTop: 0 },
    pbSm0: { paddingBottom: 0 },
    prSm0: { paddingRight: 0 },
    plSm0: { paddingLeft: 0 },
    mSm1: { margin: 4 },
    mySm1: { marginVertical: 4 },
    mxSm1: { marginHorizontal: 4 },
    mtSm1: { marginTop: 4 },
    mbSm1: { marginBottom: 4 },
    mrSm1: { marginRight: 4 },
    mlSm1: { marginLeft: 4 },
    pSm1: { padding: 4 },
    pySm1: { paddingVertical: 4 },
    pxSm1: { paddingHorizontal: 4 },
    ptSm1: { paddingTop: 4 },
    pbSm1: { paddingBottom: 4 },
    prSm1: { paddingRight: 4 },
    plSm1: { paddingLeft: 4 },
    mSm2: { margin: 8 },
    mySm2: { marginVertical: 8 },
    mxSm2: { marginHorizontal: 8 },
    mtSm2: { marginTop: 8 },
    mbSm2: { marginBottom: 8 },
    mrSm2: { marginRight: 8 },
    mlSm2: { marginLeft: 8 },
    pSm2: { padding: 8 },
    pySm2: { paddingVertical: 8 },
    pxSm2: { paddingHorizontal: 8 },
    ptSm2: { paddingTop: 8 },
    pbSm2: { paddingBottom: 8 },
    prSm2: { paddingRight: 8 },
    plSm2: { paddingLeft: 8 },
    mSm3: { margin: 16 },
    mySm3: { marginVertical: 16 },
    mxSm3: { marginHorizontal: 16 },
    mtSm3: { marginTop: 16 },
    mbSm3: { marginBottom: 16 },
    mrSm3: { marginRight: 16 },
    mlSm3: { marginLeft: 16 },
    pSm3: { padding: 16 },
    pySm3: { paddingVertical: 16 },
    pxSm3: { paddingHorizontal: 16 },
    ptSm3: { paddingTop: 16 },
    pbSm3: { paddingBottom: 16 },
    prSm3: { paddingRight: 16 },
    plSm3: { paddingLeft: 16 },
    mSm4: { margin: 24 },
    mySm4: { marginVertical: 24 },
    mxSm4: { marginHorizontal: 24 },
    mtSm4: { marginTop: 24 },
    mbSm4: { marginBottom: 24 },
    mrSm4: { marginRight: 24 },
    mlSm4: { marginLeft: 24 },
    pSm4: { padding: 24 },
    pySm4: { paddingVertical: 24 },
    pxSm4: { paddingHorizontal: 24 },
    ptSm4: { paddingTop: 24 },
    pbSm4: { paddingBottom: 24 },
    prSm4: { paddingRight: 24 },
    plSm4: { paddingLeft: 24 },
    mSm5: { margin: 48 },
    mySm5: { marginVertical: 48 },
    mxSm5: { marginHorizontal: 48 },
    mtSm5: { marginTop: 48 },
    mbSm5: { marginBottom: 48 },
    mrSm5: { marginRight: 48 },
    mlSm5: { marginLeft: 48 },
    pSm5: { padding: 48 },
    pySm5: { paddingVertical: 48 },
    pxSm5: { paddingHorizontal: 48 },
    ptSm5: { paddingTop: 48 },
    pbSm5: { paddingBottom: 48 },
    prSm5: { paddingRight: 48 },
    plSm5: { paddingLeft: 48 },
    mSmAuto: { margin: 'auto' },
    mySmAuto: { marginVertical: 'auto' },
    mxSmAuto: { marginHorizontal: 'auto' },
    mtSmAuto: { marginTop: 'auto' },
    mbSmAuto: { marginBottom: 'auto' },
    mrSmAuto: { marginRight: 'auto' },
    mlSmAuto: { marginLeft: 'auto' },
    mMd0: { margin: 0 },
    myMd0: { marginVertical: 0 },
    mxMd0: { marginHorizontal: 0 },
    mtMd0: { marginTop: 0 },
    mbMd0: { marginBottom: 0 },
    mrMd0: { marginRight: 0 },
    mlMd0: { marginLeft: 0 },
    pMd0: { padding: 0 },
    pyMd0: { paddingVertical: 0 },
    pxMd0: { paddingHorizontal: 0 },
    ptMd0: { paddingTop: 0 },
    pbMd0: { paddingBottom: 0 },
    prMd0: { paddingRight: 0 },
    plMd0: { paddingLeft: 0 },
    mMd1: { margin: 4 },
    myMd1: { marginVertical: 4 },
    mxMd1: { marginHorizontal: 4 },
    mtMd1: { marginTop: 4 },
    mbMd1: { marginBottom: 4 },
    mrMd1: { marginRight: 4 },
    mlMd1: { marginLeft: 4 },
    pMd1: { padding: 4 },
    pyMd1: { paddingVertical: 4 },
    pxMd1: { paddingHorizontal: 4 },
    ptMd1: { paddingTop: 4 },
    pbMd1: { paddingBottom: 4 },
    prMd1: { paddingRight: 4 },
    plMd1: { paddingLeft: 4 },
    mMd2: { margin: 8 },
    myMd2: { marginVertical: 8 },
    mxMd2: { marginHorizontal: 8 },
    mtMd2: { marginTop: 8 },
    mbMd2: { marginBottom: 8 },
    mrMd2: { marginRight: 8 },
    mlMd2: { marginLeft: 8 },
    pMd2: { padding: 8 },
    pyMd2: { paddingVertical: 8 },
    pxMd2: { paddingHorizontal: 8 },
    ptMd2: { paddingTop: 8 },
    pbMd2: { paddingBottom: 8 },
    prMd2: { paddingRight: 8 },
    plMd2: { paddingLeft: 8 },
    mMd3: { margin: 16 },
    myMd3: { marginVertical: 16 },
    mxMd3: { marginHorizontal: 16 },
    mtMd3: { marginTop: 16 },
    mbMd3: { marginBottom: 16 },
    mrMd3: { marginRight: 16 },
    mlMd3: { marginLeft: 16 },
    pMd3: { padding: 16 },
    pyMd3: { paddingVertical: 16 },
    pxMd3: { paddingHorizontal: 16 },
    ptMd3: { paddingTop: 16 },
    pbMd3: { paddingBottom: 16 },
    prMd3: { paddingRight: 16 },
    plMd3: { paddingLeft: 16 },
    mMd4: { margin: 24 },
    myMd4: { marginVertical: 24 },
    mxMd4: { marginHorizontal: 24 },
    mtMd4: { marginTop: 24 },
    mbMd4: { marginBottom: 24 },
    mrMd4: { marginRight: 24 },
    mlMd4: { marginLeft: 24 },
    pMd4: { padding: 24 },
    pyMd4: { paddingVertical: 24 },
    pxMd4: { paddingHorizontal: 24 },
    ptMd4: { paddingTop: 24 },
    pbMd4: { paddingBottom: 24 },
    prMd4: { paddingRight: 24 },
    plMd4: { paddingLeft: 24 },
    mMd5: { margin: 48 },
    myMd5: { marginVertical: 48 },
    mxMd5: { marginHorizontal: 48 },
    mtMd5: { marginTop: 48 },
    mbMd5: { marginBottom: 48 },
    mrMd5: { marginRight: 48 },
    mlMd5: { marginLeft: 48 },
    pMd5: { padding: 48 },
    pyMd5: { paddingVertical: 48 },
    pxMd5: { paddingHorizontal: 48 },
    ptMd5: { paddingTop: 48 },
    pbMd5: { paddingBottom: 48 },
    prMd5: { paddingRight: 48 },
    plMd5: { paddingLeft: 48 },
    mMdAuto: { margin: 'auto' },
    myMdAuto: { marginVertical: 'auto' },
    mxMdAuto: { marginHorizontal: 'auto' },
    mtMdAuto: { marginTop: 'auto' },
    mbMdAuto: { marginBottom: 'auto' },
    mrMdAuto: { marginRight: 'auto' },
    mlMdAuto: { marginLeft: 'auto' },
    mLg0: { margin: 0 },
    myLg0: { marginVertical: 0 },
    mxLg0: { marginHorizontal: 0 },
    mtLg0: { marginTop: 0 },
    mbLg0: { marginBottom: 0 },
    mrLg0: { marginRight: 0 },
    mlLg0: { marginLeft: 0 },
    pLg0: { padding: 0 },
    pyLg0: { paddingVertical: 0 },
    pxLg0: { paddingHorizontal: 0 },
    ptLg0: { paddingTop: 0 },
    pbLg0: { paddingBottom: 0 },
    prLg0: { paddingRight: 0 },
    plLg0: { paddingLeft: 0 },
    mLg1: { margin: 4 },
    myLg1: { marginVertical: 4 },
    mxLg1: { marginHorizontal: 4 },
    mtLg1: { marginTop: 4 },
    mbLg1: { marginBottom: 4 },
    mrLg1: { marginRight: 4 },
    mlLg1: { marginLeft: 4 },
    pLg1: { padding: 4 },
    pyLg1: { paddingVertical: 4 },
    pxLg1: { paddingHorizontal: 4 },
    ptLg1: { paddingTop: 4 },
    pbLg1: { paddingBottom: 4 },
    prLg1: { paddingRight: 4 },
    plLg1: { paddingLeft: 4 },
    mLg2: { margin: 8 },
    myLg2: { marginVertical: 8 },
    mxLg2: { marginHorizontal: 8 },
    mtLg2: { marginTop: 8 },
    mbLg2: { marginBottom: 8 },
    mrLg2: { marginRight: 8 },
    mlLg2: { marginLeft: 8 },
    pLg2: { padding: 8 },
    pyLg2: { paddingVertical: 8 },
    pxLg2: { paddingHorizontal: 8 },
    ptLg2: { paddingTop: 8 },
    pbLg2: { paddingBottom: 8 },
    prLg2: { paddingRight: 8 },
    plLg2: { paddingLeft: 8 },
    mLg3: { margin: 16 },
    myLg3: { marginVertical: 16 },
    mxLg3: { marginHorizontal: 16 },
    mtLg3: { marginTop: 16 },
    mbLg3: { marginBottom: 16 },
    mrLg3: { marginRight: 16 },
    mlLg3: { marginLeft: 16 },
    pLg3: { padding: 16 },
    pyLg3: { paddingVertical: 16 },
    pxLg3: { paddingHorizontal: 16 },
    ptLg3: { paddingTop: 16 },
    pbLg3: { paddingBottom: 16 },
    prLg3: { paddingRight: 16 },
    plLg3: { paddingLeft: 16 },
    mLg4: { margin: 24 },
    myLg4: { marginVertical: 24 },
    mxLg4: { marginHorizontal: 24 },
    mtLg4: { marginTop: 24 },
    mbLg4: { marginBottom: 24 },
    mrLg4: { marginRight: 24 },
    mlLg4: { marginLeft: 24 },
    pLg4: { padding: 24 },
    pyLg4: { paddingVertical: 24 },
    pxLg4: { paddingHorizontal: 24 },
    ptLg4: { paddingTop: 24 },
    pbLg4: { paddingBottom: 24 },
    prLg4: { paddingRight: 24 },
    plLg4: { paddingLeft: 24 },
    mLg5: { margin: 48 },
    myLg5: { marginVertical: 48 },
    mxLg5: { marginHorizontal: 48 },
    mtLg5: { marginTop: 48 },
    mbLg5: { marginBottom: 48 },
    mrLg5: { marginRight: 48 },
    mlLg5: { marginLeft: 48 },
    pLg5: { padding: 48 },
    pyLg5: { paddingVertical: 48 },
    pxLg5: { paddingHorizontal: 48 },
    ptLg5: { paddingTop: 48 },
    pbLg5: { paddingBottom: 48 },
    prLg5: { paddingRight: 48 },
    plLg5: { paddingLeft: 48 },
    mLgAuto: { margin: 'auto' },
    myLgAuto: { marginVertical: 'auto' },
    mxLgAuto: { marginHorizontal: 'auto' },
    mtLgAuto: { marginTop: 'auto' },
    mbLgAuto: { marginBottom: 'auto' },
    mrLgAuto: { marginRight: 'auto' },
    mlLgAuto: { marginLeft: 'auto' },
    border: { borderColor: '#dee2e6', borderWidth: 1, borderStyle: 'solid' },
    borderTop: {
        borderColor: '#dee2e6',
        borderTopWidth: 1,
        borderStyle: 'solid',
    },
    borderRight: {
        borderColor: '#dee2e6',
        borderRightWidth: 1,
        borderStyle: 'solid',
    },
    borderBottom: {
        borderColor: '#dee2e6',
        borderBottomWidth: 1,
        borderStyle: 'solid',
    },
    borderLeft: {
        borderColor: '#dee2e6',
        borderLeftWidth: 1,
        borderStyle: 'solid',
    },
    border0: { borderWidth: 0 },
    borderTop0: { borderTopWidth: 0 },
    borderRight0: { borderRightWidth: 0 },
    borderBottom0: { borderBottomWidth: 0 },
    borderLeft0: { borderLeftWidth: 0 },
    borderWhite: { borderColor: '#fff' },
    borderRounded: { borderRadius: 4.8 },
    roundedSm: { borderRadius: 2.4 },
    rounded: { borderRadius: 4.8 },
    roundedTop: { borderTopLeftRadius: 4.8, borderTopRightRadius: 4.8 },
    roundedRight: { borderTopRightRadius: 4.8, borderBottomRightRadius: 4.8 },
    roundedBottom: {
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
    roundedLeft: { borderTopLeftRadius: 4.8, borderBottomLeftRadius: 4.8 },
    roundedLg: { borderRadius: 8 },
    roundedPill: { borderRadius: 800 },
    rounded0: { borderRadius: 0 },
    borderPrimary: { borderColor: '#3278B9' },
    borderSecondary: { borderColor: '#555860' },
    borderSuccess: { borderColor: '#4DBE25' },
    borderInfo: { borderColor: '#17a2b8' },
    borderWarning: { borderColor: '#FCAB00' },
    borderDanger: { borderColor: '#D21100' },
    borderLight: { borderColor: '#F2F2F2' },
    borderDark: { borderColor: '#302528' },
    dNone: { display: 'none' },
    dFlex: { display: 'flex' },
    dXsNone: { display: 'none' },
    dXsFlex: { display: 'flex' },
    dSmNone: { display: 'none' },
    dSmFlex: { display: 'flex' },
    dMdNone: { display: 'none' },
    dMdFlex: { display: 'flex' },
    dLgNone: { display: 'none' },
    dLgFlex: { display: 'flex' },
    dPrintNone: { display: 'none' },
    dPrintFlex: { display: 'flex' },
    overflowVisible: { overflow: 'visible' },
    overflowHidden: { overflow: 'hidden' },
    h1: {
        fontSize: 40,
        marginBottom: 8,
        fontFamily: 'System',
        fontWeight: '400',
        color: '#212529',
        lineHeight: 48,
    },
    h2: {
        fontSize: 32,
        marginBottom: 8,
        fontFamily: 'System',
        fontWeight: '400',
        color: '#212529',
        lineHeight: 38.4,
    },
    h3: {
        fontSize: 28,
        marginBottom: 8,
        fontFamily: 'System',
        fontWeight: '400',
        color: '#212529',
        lineHeight: 33.6,
    },
    h4: {
        fontSize: 24,
        marginBottom: 8,
        fontFamily: 'System',
        fontWeight: '400',
        color: '#212529',
        lineHeight: 28.799999999999997,
    },
    h6: {
        fontSize: 16,
        marginBottom: 8,
        fontFamily: 'System',
        fontWeight: '400',
        color: '#212529',
        lineHeight: 19.2,
    },
    hr: {
        marginTop: 16,
        marginBottom: 16,
        borderWidth: 0,
        borderTopWidth: 1,
        borderTopColor: 'rgba(0, 0, 0, 0.1)',
        borderStyle: 'solid',
    },
    mark: { padding: 3.2, backgroundColor: '#fcf8e3' },
    listUnstyled: {},
    initialism: { textTransform: 'uppercase' },
    blockquote: { marginBottom: 16, fontSize: 320 },
    lead: { fontSize: 20, fontWeight: '300' },
    small: { fontSize: 14 },
    large: { fontSize: 20 },
    containerFluid: {
        width: '100%',
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    noGutters: { marginRight: 0, marginLeft: 0 },
    noGuttersCol: { paddingRight: 0, paddingLeft: 0 },
    Gutters: { marginRight: -4, marginLeft: -4 },
    GuttersCol: { paddingRight: 4, paddingLeft: 4 },
    container: {
        width: '100%',
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 960,
    },
    containerXs: {
        width: '100%',
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    containerSm: {
        width: '100%',
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 450,
    },
    containerMd: {
        width: '100%',
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 720,
    },
    containerLg: {
        width: '100%',
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 960,
    },
    row: {
        flexWrap: 'wrap',
        marginRight: -4,
        marginLeft: -4,
        flexDirection: 'row',
    },
    rowXs: {
        flexWrap: 'wrap',
        marginRight: -4,
        marginLeft: -4,
        flexDirection: 'row',
    },
    rowSm: {
        flexWrap: 'wrap',
        marginRight: -4,
        marginLeft: -4,
        flexDirection: 'row',
    },
    rowMd: {
        flexWrap: 'wrap',
        marginRight: -4,
        marginLeft: -4,
        flexDirection: 'row',
    },
    rowLg: {
        flexWrap: 'wrap',
        marginRight: -4,
        marginLeft: -4,
        flexDirection: 'row',
    },
    rowXl: { flexWrap: 'wrap', marginRight: -4, marginLeft: -4 },
    col: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 1,
    },
    col1: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 1,
    },
    col2: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 2,
    },
    col3: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 3,
    },
    col4: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 4,
    },
    col5: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 5,
    },
    col6: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 6,
    },
    col7: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 7,
    },
    col8: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 8,
    },
    col9: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 9,
    },
    col10: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 10,
    },
    col11: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 11,
    },
    colXs: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 1,
    },
    colXs1: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 1,
    },
    colXs2: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 2,
    },
    colXs3: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 3,
    },
    colXs4: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 4,
    },
    colXs5: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 5,
    },
    colXs6: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 6,
    },
    colXs7: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 7,
    },
    colXs8: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 8,
    },
    colXs9: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 9,
    },
    colXs10: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 10,
    },
    colXs11: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 11,
    },
    colSm: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 1,
    },
    colSm1: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 1,
    },
    colSm2: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 2,
    },
    colSm3: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 3,
    },
    colSm4: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 4,
    },
    colSm5: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 5,
    },
    colSm6: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 6,
    },
    colSm7: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 7,
    },
    colSm8: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 8,
    },
    colSm9: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 9,
    },
    colSm10: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 10,
    },
    colSm11: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 11,
    },
    colMd: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 1,
    },
    colMd1: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 1,
    },
    colMd2: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 2,
    },
    colMd3: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 3,
    },
    colMd4: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 4,
    },
    colMd5: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 5,
    },
    colMd6: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 6,
    },
    colMd7: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 7,
    },
    colMd8: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 8,
    },
    colMd9: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 9,
    },
    colMd10: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 10,
    },
    colMd11: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 11,
    },
    colLg: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 1,
    },
    colLg1: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 1,
    },
    colLg2: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 2,
    },
    colLg3: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 3,
    },
    colLg4: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 4,
    },
    colLg5: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 5,
    },
    colLg6: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 6,
    },
    colLg7: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 7,
    },
    colLg8: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 8,
    },
    colLg9: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 9,
    },
    colLg10: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 10,
    },
    colLg11: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
        flex: 11,
    },
    colXl: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
    },
    colXl1: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
    },
    colXl2: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
    },
    colXl3: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
    },
    colXl4: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
    },
    colXl5: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
    },
    colXl6: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
    },
    colXl7: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
    },
    colXl8: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
    },
    colXl9: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
    },
    colXl10: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
    },
    colXl11: {
        flexDirection: 'column',
        paddingRight: 4,
        paddingLeft: 4,
        minWidth: 0,
        maxWidth: '100%',
    },
    alertPrimary: { borderColor: '#C6D9EB', backgroundColor: '#D6E4F1' },
    alertPrimaryText: { color: '#1A3E60' },
    alertPrimaryHr: { borderTopColor: 'rgb(182, 206, 229)' },
    alertPrimaryLink: { color: 'rgb(23, 56, 86)' },
    alertSecondary: { borderColor: '#CFD0D2', backgroundColor: '#DDDEDF' },
    alertSecondaryText: { color: '#2C2E32' },
    alertSecondaryHr: { borderTopColor: 'rgb(196, 198, 200)' },
    alertSecondaryLink: { color: 'rgb(40, 41, 45)' },
    alertSuccess: { borderColor: '#CDEDC2', backgroundColor: '#DBF2D3' },
    alertSuccessText: { color: '#286313' },
    alertSuccessHr: { borderTopColor: 'rgb(191, 232, 177)' },
    alertSuccessLink: { color: 'rgb(36, 89, 17)' },
    alertInfo: { borderColor: '#BEE5EB', backgroundColor: '#D1ECF1' },
    alertInfoText: { color: '#0C5460' },
    alertInfoHr: { borderTopColor: 'rgb(174, 223, 230)' },
    alertInfoLink: { color: 'rgb(11, 76, 86)' },
    alertWarning: { borderColor: '#FEE7B8', backgroundColor: '#FEEECC' },
    alertWarningText: { color: '#835900' },
    alertWarningHr: { borderTopColor: 'rgb(254, 224, 162)' },
    alertWarningLink: { color: 'rgb(118, 80, 0)' },
    alertDanger: { borderColor: '#F2BCB8', backgroundColor: '#F6CFCC' },
    alertDangerText: { color: '#6D0900' },
    alertDangerHr: { borderTopColor: 'rgb(239, 171, 166)' },
    alertDangerLink: { color: 'rgb(98, 8, 0)' },
    alertLight: { borderColor: '#FBFBFB', backgroundColor: '#FCFCFC' },
    alertLightText: { color: '#7E7E7E' },
    alertLightHr: { borderTopColor: 'rgb(238, 238, 238)' },
    alertLightLink: { color: 'rgb(113, 113, 113)' },
    alertDark: { borderColor: '#C5C2C3', backgroundColor: '#D6D3D4' },
    alertDarkText: { color: '#191315' },
    alertDarkHr: { borderTopColor: 'rgb(188, 184, 185)' },
    alertDarkLink: { color: 'rgb(23, 17, 19)' },
    alert: {
        position: 'relative',
        width: '100%',
        paddingVertical: 12,
        paddingHorizontal: 20,
        marginBottom: 16,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderTopLeftRadius: 4.8,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
    alertText: {},
    alertLink: { fontWeight: '700' },
    alertDismissible: { paddingRight: 64 },
    alertDismissibleClose: {
        position: 'absolute',
        top: 0,
        right: 0,
        paddingVertical: 12,
        paddingHorizontal: 20,
    },
    btnGroup: { position: 'relative' },
    btnGroupVertical: { position: 'relative', flexDirection: 'column' },
    btnToolbar: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    btnPrimary: { backgroundColor: '#3278B9', borderColor: '#3278B9' },
    btnPrimaryText: { color: '#fff' },
    btnPrimaryActive: {
        backgroundColor: 'rgb(40, 96, 148)',
        borderColor: 'rgb(40, 96, 148)',
    },
    btnSecondary: { backgroundColor: '#555860', borderColor: '#555860' },
    btnSecondaryText: { color: '#fff' },
    btnSecondaryActive: {
        backgroundColor: 'rgb(68, 70, 77)',
        borderColor: 'rgb(68, 70, 77)',
    },
    btnSuccess: { backgroundColor: '#4DBE25', borderColor: '#4DBE25' },
    btnSuccessText: { color: '#fff' },
    btnSuccessActive: {
        backgroundColor: 'rgb(62, 152, 30)',
        borderColor: 'rgb(62, 152, 30)',
    },
    btnInfo: { backgroundColor: '#17a2b8', borderColor: '#17a2b8' },
    btnInfoText: { color: '#fff' },
    btnInfoActive: {
        backgroundColor: 'rgb(18, 130, 147)',
        borderColor: 'rgb(18, 130, 147)',
    },
    btnWarning: { backgroundColor: '#FCAB00', borderColor: '#FCAB00' },
    btnWarningText: { color: '#212529' },
    btnWarningActive: {
        backgroundColor: 'rgb(202, 137, 0)',
        borderColor: 'rgb(202, 137, 0)',
    },
    btnDanger: { backgroundColor: '#D21100', borderColor: '#D21100' },
    btnDangerText: { color: '#fff' },
    btnDangerActive: {
        backgroundColor: 'rgb(168, 14, 0)',
        borderColor: 'rgb(168, 14, 0)',
    },
    btnLight: { backgroundColor: '#F2F2F2', borderColor: '#F2F2F2' },
    btnLightText: { color: '#212529' },
    btnLightActive: {
        backgroundColor: 'rgb(194, 194, 194)',
        borderColor: 'rgb(194, 194, 194)',
    },
    btnDark: { backgroundColor: '#302528', borderColor: '#302528' },
    btnDarkText: { color: '#fff' },
    btnDarkActive: {
        backgroundColor: 'rgb(38, 30, 32)',
        borderColor: 'rgb(38, 30, 32)',
    },
    btnOutlinePrimary: { borderColor: '#3278B9' },
    btnOutlinePrimaryText: { color: '#3278B9' },
    btnOutlineSecondary: { borderColor: '#555860' },
    btnOutlineSecondaryText: { color: '#555860' },
    btnOutlineSuccess: { borderColor: '#4DBE25' },
    btnOutlineSuccessText: { color: '#4DBE25' },
    btnOutlineInfo: { borderColor: '#17a2b8' },
    btnOutlineInfoText: { color: '#17a2b8' },
    btnOutlineWarning: { borderColor: '#FCAB00' },
    btnOutlineWarningText: { color: '#FCAB00' },
    btnOutlineDanger: { borderColor: '#D21100' },
    btnOutlineDangerText: { color: '#D21100' },
    btnOutlineLight: { borderColor: '#F2F2F2' },
    btnOutlineLightText: { color: '#F2F2F2' },
    btnOutlineDark: { borderColor: '#302528' },
    btnOutlineDarkText: { color: '#302528' },
    btn: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',
        overflow: 'hidden',
        justifyContent: 'center',
        paddingHorizontal: 12,
        paddingVertical: 6,
        borderTopLeftRadius: 55,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
        textDecorationLine: 'none',
    },
    btnDisabled: { opacity: 0.65 },
    btnHover: { color: '#212529', textDecorationLine: 'none' },
    btnFocus: {},
    btnActive: {},
    btnTouchable: { borderRadius: 55, overflow: 'hidden' },
    btnTouchableBlock: {},
    btnText: {
        fontFamily: 'System',
        fontWeight: '400',
        color: '#212529',
        textAlign: 'center',
        fontSize: 16,
        lineHeight: 24,
    },
    btnLink: {},
    btnLinkText: {
        fontWeight: '400',
        color: '#17a2b8',
        textDecorationLine: 'none',
        textDecorationColor: '#17a2b8',
        textDecorationStyle: 'solid',
    },
    btnLinkHoverText: {
        color: 'rgb(20, 138, 156)',
        textDecorationLine: 'underline',
        textDecorationColor: 'rgb(20, 138, 156)',
        textDecorationStyle: 'solid',
    },
    btnLinkFocusText: {
        textDecorationLine: 'underline',
        textDecorationColor: 'rgb(20, 138, 156)',
        textDecorationStyle: 'solid',
    },
    btnLinkDisabledText: { color: '#6c757d' },
    btnLg: {
        paddingHorizontal: 16,
        paddingVertical: 8,
        borderTopLeftRadius: 4.8,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
    btnLgText: { fontSize: 20, lineHeight: 24 },
    btnSm: {
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderTopLeftRadius: 4.8,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
    btnSmText: { fontSize: 14, lineHeight: 24 },
    btnBlock: { width: '100%' },
    formControl: {
        width: '100%',
        height: 38,
        paddingVertical: 6,
        paddingHorizontal: 12,
        fontFamily: 'System',
        fontSize: 16,
        fontWeight: '400',
        color: '#495057',
        backgroundColor: '#fff',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#ced4da',
        borderTopLeftRadius: 4.8,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
    colFormLabel: {
        paddingTop: 7,
        paddingBottom: 7,
        marginBottom: 0,
        lineHeight: 24,
        fontSize: 16,
    },
    colFormLabelLg: {
        paddingTop: 9,
        paddingBottom: 9,
        lineHeight: 24,
        fontSize: 20,
    },
    colFormLabelSm: {
        paddingTop: 5,
        paddingBottom: 5,
        lineHeight: 24,
        fontSize: 14,
    },
    formControlPlaintext: {
        paddingHorizontal: 0,
        marginBottom: 0,
        color: '#212529',
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
    },
    formControlPlaintextSm: { paddingRight: 0, paddingLeft: 0 },
    formControlPlaintextLg: { paddingRight: 0, paddingLeft: 0 },
    formControlSm: {
        height: 34,
        paddingVertical: 4,
        paddingHorizontal: 8,
        fontSize: 14,
        borderTopLeftRadius: 2.4,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
    formControlLg: {
        height: 42,
        paddingVertical: 8,
        paddingHorizontal: 16,
        fontSize: 20,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
    formGroup: { marginBottom: 16 },
    formText: { marginTop: 4 },
    formLabelText: { marginBottom: 8 },
    formLabelSmText: { fontSize: 14 },
    formLabelLgText: { fontSize: 20 },
    formRow: { marginRight: -5, marginLeft: -5 },
    formCol: { paddingRight: 5, paddingLeft: 5 },
    formInline: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    formInlineFormCheck: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 'auto',
        paddingLeft: 0,
    },
    formInlineLabel: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 0,
    },
    formInlineFormGroup: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 'auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: 0,
    },
    formInlineFormControl: { width: 'auto' },
    formInlineFormControlPlaintext: {},
    formInlineInputGroup: { width: 'auto' },
    formInlineCustomSelect: { width: 'auto' },
    formInlineFormCheckInput: {
        position: 'relative',
        flexShrink: 0,
        marginTop: 0,
        marginRight: 4,
        marginLeft: 0,
    },
    formInlineCustomControl: { alignItems: 'center', justifyContent: 'center' },
    formInlineCustomControlLabel: { marginBottom: 0 },
    validFeedback: { width: '100%', marginTop: 4, color: '#4DBE25' },
    validFeedbackText: { width: '100%', marginTop: 4, color: '#4DBE25' },
    isValid: { borderColor: '#4DBE25' },
    invalidFeedback: { width: '100%', marginTop: 4, color: '#D21100' },
    invalidFeedbackText: { width: '100%', marginTop: 4, color: '#D21100' },
    isInvalid: { borderColor: '#D21100' },
    card: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#fff',
        borderWidth: 1,
        borderColor: '#dee2e6',
        borderTopLeftRadius: 4.8,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
    cardListGroup: {
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
        borderBottomWidth: 1,
        borderBottomColor: '#dee2e6',
    },
    cardBody: { flex: 1, paddingHorizontal: 20, paddingVertical: 20 },
    cardTitle: { marginBottom: 12 },
    cardSubtitle: { marginTop: -6, marginBottom: 0 },
    cardHeader: {
        paddingVertical: 12,
        paddingHorizontal: 20,
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        borderBottomWidth: 1,
        borderBottomColor: '#dee2e6',
    },
    cardFooter: {
        paddingVertical: 12,
        paddingHorizontal: 20,
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
    },
    cardHeaderTabs: {
        marginRight: -10,
        marginBottom: -12,
        marginLeft: -10,
        borderBottomWidth: 0,
    },
    cardHeaderPills: { marginRight: -10, marginLeft: -10 },
    cardImgOverlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        padding: 20,
    },
    cardImg: {
        flexShrink: 0,
        alignSelf: 'stretch',
        borderTopLeftRadius: 3.8,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
    cardImgTop: {
        flexShrink: 0,
        alignSelf: 'stretch',
        borderTopLeftRadius: 3.8,
        borderTopRightRadius: 3.8,
    },
    cardImgBottom: {
        flexShrink: 0,
        alignSelf: 'stretch',
        borderBottomLeftRadius: 3.8,
        borderBottomRightRadius: 3.8,
    },
    cardDeck: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginRight: -4,
        marginLeft: -4,
    },
    cardDeckCard: { marginBottom: 0, marginRight: 4, marginLeft: 4 },
    cardGroup: { marginBottom: 4, flexDirection: 'row', flexWrap: 'wrap' },
    cardColumnsCard: { marginBottom: 12, width: '100%' },
    cardColumns: {},
    cardAccordionCardHeader: { borderRadius: 0, marginBottom: -1 },
    listGroup: { flexDirection: 'column', paddingLeft: 0, marginBottom: 0 },
    listGroupItem: {
        position: 'relative',
        paddingVertical: 12,
        paddingHorizontal: 20,
        backgroundColor: '#fff',
        borderBottomWidth: 0,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.125)',
        borderStyle: 'solid',
    },
    listGroupItemDisabled: { backgroundColor: '#fff' },
    listGroupItemActive: {
        zIndex: 2,
        backgroundColor: '#3278B9',
        borderColor: '#3278B9',
    },
    listGroupItemText: {
        color: null,
        textDecorationLine: 'none',
        textDecorationColor: '#17a2b8',
        textDecorationStyle: 'solid',
    },
    listGroupItemDisabledText: { color: '#6c757d' },
    listGroupItemActiveText: { color: '#fff' },
    listGroupFlush: {},
    listGroupFlushItem: { borderLeftWidth: 0, borderRightWidth: 0 },
    listGroupHorizontal: { flexDirection: 'row' },
    listGroupHorizontalItem: {
        borderBottomWidth: 1,
        borderRightWidth: 0,
        flex: 1,
    },
    listGroupHorizontalItemActive: { marginTop: 0 },
    listGroupHorizontalXs: { flexDirection: 'row' },
    listGroupHorizontalXsItem: {
        borderBottomWidth: 1,
        borderRightWidth: 0,
        flex: 1,
    },
    listGroupHorizontalXsItemActive: { marginTop: 0 },
    listGroupHorizontalSm: { flexDirection: 'row' },
    listGroupHorizontalSmItem: {
        borderBottomWidth: 1,
        borderRightWidth: 0,
        flex: 1,
    },
    listGroupHorizontalSmItemActive: { marginTop: 0 },
    listGroupHorizontalMd: { flexDirection: 'row' },
    listGroupHorizontalMdItem: {
        borderBottomWidth: 1,
        borderRightWidth: 0,
        flex: 1,
    },
    listGroupHorizontalMdItemActive: { marginTop: 0 },
    listGroupHorizontalLg: { flexDirection: 'row' },
    listGroupHorizontalLgItem: {
        borderBottomWidth: 1,
        borderRightWidth: 0,
        flex: 1,
    },
    listGroupHorizontalLgItemActive: { marginTop: 0 },
    listGroupHorizontalXl: false,
    listGroupHorizontalXlItem: false,
    listGroupHorizontalXlItemActive: false,
    listGroupItemPrimary: { color: '#3278B9', backgroundColor: '#C6D9EB' },
    listGroupItemPrimaryText: { color: '#3278B9' },
    listGroupItemSecondary: { color: '#555860', backgroundColor: '#CFD0D2' },
    listGroupItemSecondaryText: { color: '#555860' },
    listGroupItemSuccess: { color: '#4DBE25', backgroundColor: '#CDEDC2' },
    listGroupItemSuccessText: { color: '#4DBE25' },
    listGroupItemInfo: { color: '#17a2b8', backgroundColor: '#BEE5EB' },
    listGroupItemInfoText: { color: '#17a2b8' },
    listGroupItemWarning: { color: '#FCAB00', backgroundColor: '#FEE7B8' },
    listGroupItemWarningText: { color: '#FCAB00' },
    listGroupItemDanger: { color: '#D21100', backgroundColor: '#F2BCB8' },
    listGroupItemDangerText: { color: '#D21100' },
    listGroupItemLight: { color: '#F2F2F2', backgroundColor: '#FBFBFB' },
    listGroupItemLightText: { color: '#F2F2F2' },
    listGroupItemDark: { color: '#302528', backgroundColor: '#C5C2C3' },
    listGroupItemDarkText: { color: '#302528' },
    toast: {
        maxWidth: '100%',
        overflow: 'hidden',
        color: null,
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        opacity: 0,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
    toastHeader: {
        alignItems: 'center',
        paddingVertical: 4,
        paddingHorizontal: 12,
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.05)',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    toastHeaderText: { color: '#6c757d' },
    toastBody: { padding: 12 },
    toastShowing: { opacity: 1 },
    toastShow: { opacity: 1 },
    toastHide: { display: 'none' },
    modal: { backgroundColor: 'rgba(0, 0, 0, 0.5)', flex: 1 },
    modalDialog: { margin: 16, marginTop: 48, flex: 1 },
    modalContent: {
        backgroundColor: '#fff',
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.2)',
        flex: 1,
        borderTopLeftRadius: 14.399999999999999,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
    modalHeader: {
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        paddingVertical: 16,
        paddingHorizontal: 16,
        borderBottomWidth: 1,
        borderBottomColor: '#e9ecef',
        borderStyle: 'solid',
        borderTopLeftRadius: 14.399999999999999,
        borderTopRightRadius: 14.399999999999999,
    },
    modalHeaderClose: { position: 'absolute', right: 16, top: 16, zIndex: 1 },
    modalTitle: { marginBottom: 0, lineHeight: 24 },
    modalBody: { padding: 16 },
    modalFooter: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 16,
        borderTopWidth: 1,
        borderTopColor: '#e9ecef',
        borderStyle: 'solid',
    },
    progress: {
        height: 16,
        overflow: 'hidden',
        backgroundColor: '#e9ecef',
        borderTopLeftRadius: 4.8,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
    progressBar: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#3278B9',
    },
    progressText: { color: '#fff', fontSize: 12, textAlign: 'center' },
    text: {
        fontFamily: 'System',
        fontSize: 16,
        fontWeight: '400',
        lineHeight: 24,
        color: '#212529',
    },
    linkText: {
        color: '#17a2b8',
        textDecorationLine: 'none',
        textDecorationColor: '#17a2b8',
        textDecorationStyle: 'solid',
    },
    image: { resizeMode: 'contain' },
    body: { flex: 1, backgroundColor: '#fff' },
    hidden: { display: 'none' },
    link: {
        color: '#17a2b8',
        textDecorationLine: 'none',
        textDecorationColor: '#17a2b8',
        textDecorationStyle: 'solid',
    },
    a: {
        color: '#17a2b8',
        textDecorationLine: 'none',
        textDecorationColor: '#17a2b8',
        textDecorationStyle: 'solid',
    },
    table: { width: '100%', marginBottom: 16, backgroundColor: null },
    tableTbody: { flexDirection: 'column' },
    tableThead: { flexDirection: 'row' },
    tableTheadTh: {
        flex: 1,
        padding: 12,
        justifyContent: 'flex-end',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
        borderBottomWidth: 2,
        borderBottomColor: '#dee2e6',
    },
    tableTheadThText: { color: '#212529' },
    tableTbodyTd: {
        flex: 1,
        padding: 12,
        justifyContent: 'flex-start',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
    },
    tableTbodyTdText: { color: '#212529' },
    tableTbodyTbody: {
        borderStyle: 'solid',
        borderTopWidth: 2,
        borderTopColor: '#dee2e6',
    },
    tableTbodyTr: { flexDirection: 'row' },
    tableDark: { color: '#fff', backgroundColor: 'rgb(56, 62, 69)' },
    tableDarkTbodyTd: { color: '#fff', borderColor: 'rgb(56, 62, 69)' },
    tableDarkTbodyTdText: { color: '#fff' },
    tableTheadDarkTh: {
        color: '#fff',
        backgroundColor: '#343a40',
        borderColor: 'rgb(56, 62, 69)',
    },
    tableTheadDarkThText: { color: '#fff' },
    tableTheadLightTh: {
        color: '#495057',
        backgroundColor: '#e9ecef',
        borderColor: '#dee2e6',
    },
    tableTheadLightThText: { color: '#495057' },
    tableBorderedTbodyTd: {
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#dee2e6',
        borderRightWidth: 1,
        borderRightColor: '#dee2e6',
    },
    tableBorderedTheadTh: {
        borderStyle: 'solid',
        borderRightWidth: 1,
        borderRightColor: '#dee2e6',
    },
    tableBorderless: { borderWidth: 0 },
    tableBorderlessTbodyTd: { borderWidth: 0 },
    tableBorderlessTheadTh: { borderWidth: 0 },
    tableBorderlessTbodyTbody: { borderWidth: 0 },
    tableSmTbodyTd: {
        flex: 1,
        padding: 4.8,
        justifyContent: 'flex-start',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
    },
    tableSmTheadTh: {
        flex: 1,
        padding: 4.8,
        justifyContent: 'flex-end',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
        borderBottomWidth: 2,
        borderBottomColor: '#dee2e6',
    },
    tablePrimaryTBodyTd: { backgroundColor: '#C6D9EB', borderColor: '#94B9DB' },
    tablePrimaryTheadTh: { backgroundColor: '#C6D9EB', borderColor: '#94B9DB' },
    tablePrimaryTd: { backgroundColor: '#C6D9EB', borderColor: '#94B9DB' },
    tablePrimaryTh: { backgroundColor: '#C6D9EB', borderColor: '#94B9DB' },
    tablePrimaryCol: { backgroundColor: '#C6D9EB', borderColor: '#94B9DB' },
    tablePrimaryHeadCol: { backgroundColor: '#C6D9EB', borderColor: '#94B9DB' },
    tableSecondaryTBodyTd: {
        backgroundColor: '#CFD0D2',
        borderColor: '#A7A8AC',
    },
    tableSecondaryTheadTh: {
        backgroundColor: '#CFD0D2',
        borderColor: '#A7A8AC',
    },
    tableSecondaryTd: { backgroundColor: '#CFD0D2', borderColor: '#A7A8AC' },
    tableSecondaryTh: { backgroundColor: '#CFD0D2', borderColor: '#A7A8AC' },
    tableSecondaryCol: { backgroundColor: '#CFD0D2', borderColor: '#A7A8AC' },
    tableSecondaryHeadCol: {
        backgroundColor: '#CFD0D2',
        borderColor: '#A7A8AC',
    },
    tableSuccessTBodyTd: { backgroundColor: '#CDEDC2', borderColor: '#A2DD8E' },
    tableSuccessTheadTh: { backgroundColor: '#CDEDC2', borderColor: '#A2DD8E' },
    tableSuccessTd: { backgroundColor: '#CDEDC2', borderColor: '#A2DD8E' },
    tableSuccessTh: { backgroundColor: '#CDEDC2', borderColor: '#A2DD8E' },
    tableSuccessCol: { backgroundColor: '#CDEDC2', borderColor: '#A2DD8E' },
    tableSuccessHeadCol: { backgroundColor: '#CDEDC2', borderColor: '#A2DD8E' },
    tableInfoTBodyTd: { backgroundColor: '#BEE5EB', borderColor: '#86CFDA' },
    tableInfoTheadTh: { backgroundColor: '#BEE5EB', borderColor: '#86CFDA' },
    tableInfoTd: { backgroundColor: '#BEE5EB', borderColor: '#86CFDA' },
    tableInfoTh: { backgroundColor: '#BEE5EB', borderColor: '#86CFDA' },
    tableInfoCol: { backgroundColor: '#BEE5EB', borderColor: '#86CFDA' },
    tableInfoHeadCol: { backgroundColor: '#BEE5EB', borderColor: '#86CFDA' },
    tableWarningTBodyTd: { backgroundColor: '#FEE7B8', borderColor: '#FDD37A' },
    tableWarningTheadTh: { backgroundColor: '#FEE7B8', borderColor: '#FDD37A' },
    tableWarningTd: { backgroundColor: '#FEE7B8', borderColor: '#FDD37A' },
    tableWarningTh: { backgroundColor: '#FEE7B8', borderColor: '#FDD37A' },
    tableWarningCol: { backgroundColor: '#FEE7B8', borderColor: '#FDD37A' },
    tableWarningHeadCol: { backgroundColor: '#FEE7B8', borderColor: '#FDD37A' },
    tableDangerTBodyTd: { backgroundColor: '#F2BCB8', borderColor: '#E8837A' },
    tableDangerTheadTh: { backgroundColor: '#F2BCB8', borderColor: '#E8837A' },
    tableDangerTd: { backgroundColor: '#F2BCB8', borderColor: '#E8837A' },
    tableDangerTh: { backgroundColor: '#F2BCB8', borderColor: '#E8837A' },
    tableDangerCol: { backgroundColor: '#F2BCB8', borderColor: '#E8837A' },
    tableDangerHeadCol: { backgroundColor: '#F2BCB8', borderColor: '#E8837A' },
    tableLightTBodyTd: { backgroundColor: '#FBFBFB', borderColor: '#F8F8F8' },
    tableLightTheadTh: { backgroundColor: '#FBFBFB', borderColor: '#F8F8F8' },
    tableLightTd: { backgroundColor: '#FBFBFB', borderColor: '#F8F8F8' },
    tableLightTh: { backgroundColor: '#FBFBFB', borderColor: '#F8F8F8' },
    tableLightCol: { backgroundColor: '#FBFBFB', borderColor: '#F8F8F8' },
    tableLightHeadCol: { backgroundColor: '#FBFBFB', borderColor: '#F8F8F8' },
    tableDarkTBodyTd: { backgroundColor: '#C5C2C3', borderColor: '#938E8F' },
    tableDarkTheadTh: { color: '#fff', borderColor: 'rgb(56, 62, 69)' },
    tableDarkTd: { color: '#fff', borderColor: 'rgb(56, 62, 69)' },
    tableDarkTh: { color: '#fff', borderColor: 'rgb(56, 62, 69)' },
    tableDarkCol: { color: '#fff', borderColor: 'rgb(56, 62, 69)' },
    tableDarkHeadCol: { color: '#fff', borderColor: 'rgb(56, 62, 69)' },
    tableTh: {
        flex: 1,
        padding: 12,
        justifyContent: 'flex-end',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
        borderBottomWidth: 2,
        borderBottomColor: '#dee2e6',
    },
    tableThText: { color: '#212529' },
    tableTr: { flexDirection: 'row' },
    tableTd: {
        flex: 1,
        padding: 12,
        justifyContent: 'flex-start',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
    },
    tableTdText: { color: '#212529' },
    tableBorderedTh: {
        borderStyle: 'solid',
        borderRightWidth: 1,
        borderRightColor: '#dee2e6',
    },
    tableBorderedTd: {
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#dee2e6',
        borderRightWidth: 1,
        borderRightColor: '#dee2e6',
    },
    tableBorderlessTh: { borderWidth: 0 },
    tableBorderlessTd: { borderWidth: 0 },
    tableDarkTheadThText: { color: '#fff' },
    tableHead: { flexDirection: 'row' },
    tableHeadCol: {
        flex: 1,
        padding: 12,
        justifyContent: 'flex-end',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
        borderBottomWidth: 2,
        borderBottomColor: '#dee2e6',
    },
    tableHeadColText: { color: '#212529' },
    tableSmHeadCol: {
        flex: 1,
        padding: 4.8,
        justifyContent: 'flex-end',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
        borderBottomWidth: 2,
        borderBottomColor: '#dee2e6',
    },
    tableBorderedHeadCol: {
        borderStyle: 'solid',
        borderRightWidth: 1,
        borderRightColor: '#dee2e6',
    },
    tableBorderlessHeadCol: { borderWidth: 0 },
    tableDarkHeadColText: { color: '#fff' },
    tableBody: { flexDirection: 'column' },
    tableRow: { flexDirection: 'row' },
    tableCol: {
        flex: 1,
        padding: 12,
        justifyContent: 'flex-start',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
    },
    tableColText: { color: '#212529' },
    tableSmCol: {
        flex: 1,
        padding: 4.8,
        justifyContent: 'flex-start',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: '#dee2e6',
    },
    tableBorderedCol: {
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#dee2e6',
        borderRightWidth: 1,
        borderRightColor: '#dee2e6',
    },
    tableBorderlessCol: { borderWidth: 0 },
    tableDarkColText: { color: '#fff' },
    kbd: {
        paddingHorizontal: 6.4,
        paddingVertical: 3.2,
        color: '#fff',
        backgroundColor: '#212529',
        borderTopLeftRadius: 2.4,
        borderTopRightRadius: 4.8,
        borderBottomRightRadius: 4.8,
        borderBottomLeftRadius: 4.8,
    },
}

export const s = StyleSheet.create(bootstrapStyles)
export const c = {
    DARK: '#302528',
    WHITE: '#FFFFFF',
    LIGHT: '#f9f9f9',
    SPACER: 16,
}
