import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
// import { NavLink } from 'react-router-dom'
import { PageHeader } from '../WebBlocks/PageHeader'

export const ServicesView = ({ data, addToCart, orderId }) => {
    const { service_options } = data
    const [checkedOptions, setCheckedOptions] = useState([])

    const history = useHistory()

    const addToCartPre = (options) => {
        if (orderId) {
            addToCart(options, function () {
                history.push(`/tickets/${orderId}`)
            })
        } else {
            addToCart(options)
        }
    }

    const onChangeOption = (e, option) => {
        if (e.target.checked) {
            setCheckedOptions([...checkedOptions, option])
        } else {
            setCheckedOptions(checkedOptions.filter((c) => c.id != option.id))
        }
    }

    return (
        <div>
            <PageHeader
                back={`/services/category/${data.service_category_id}`}
                // backTitle={data.title}
                title={data.title}
            />

            <h1>{data.title}</h1>
            <div className="page-body row mb-5">
                <div className="col-12 col-md-6 col-xl-5 mb-3">
                    <img src={data.preview_file.path} alt="" />
                </div>
                <div className="col-12 col-md-6 col-xl-6">
                    {data.old_per_unit_price ? (
                        <div className="mb-3">
                            <h5 className="opacity-70 text-decoration-line-through">
                                {data.old_per_unit_price}₽
                            </h5>
                            <h4 className="text-danger">
                                от {data.per_unit_price}₽
                            </h4>
                        </div>
                    ) : (
                        <h4 className="mb-3">от {data.per_unit_price}₽</h4>
                    )}

                    <p>{data.description}</p>

                    {service_options && service_options.length ? (
                        <div>
                            {service_options.map((option) => (
                                <div key={option.id} className="mb-3">
                                    {service_options.length === 1 ? (
                                        <button
                                            onClick={() =>
                                                addToCartPre([option])
                                            }
                                            className="btn btn-danger"
                                        >
                                            {orderId
                                                ? `Добавить к заказу`
                                                : `Добавить в корзину`}
                                        </button>
                                    ) : (
                                        <div className="service-option">
                                            <input
                                                id={`serviceOption${option.id}`}
                                                type="checkbox"
                                                onChange={(e) =>
                                                    onChangeOption(e, option)
                                                }
                                            />
                                            <label
                                                htmlFor={`serviceOption${option.id}`}
                                            >
                                                <div className="service-option__name">
                                                    {option.name}
                                                </div>

                                                <div className="service-option__price">
                                                    {option.price}₽
                                                </div>
                                            </label>
                                        </div>
                                    )}
                                </div>
                            ))}

                            {service_options.length > 1 ? (
                                <div>
                                    <button
                                        onClick={() =>
                                            addToCartPre(checkedOptions)
                                        }
                                        className="btn btn-danger"
                                    >
                                        {orderId
                                            ? `Добавить к заказу `
                                            : `Добавить в корзину `}
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
