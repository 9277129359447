import * as React from 'react'
import { OpenTelegram } from '../../helpers/linkHelper'

export const AccountCard = ({ item, onPress, settings }) => {
    let telegramLink = item.account?.house?.telegram_link

    if (!telegramLink && settings) {
        telegramLink = settings.telegram_bot
    }
    return (
        <div className="card-address">
            <div className="row mb-3">
                <div className="col-6">
                    {/* {item.account_id && (
                        <small className="text-opacity">
                            Л/С {item.account_id ? item.account_id : `-`}
                        </small>
                    )} */}
                    {/* {!item.account_id && item.city && ( */}
                    {item.city && (
                        <small className="text-opacity">
                            {item.city.title}
                        </small>
                    )}
                </div>
                <div className="col-6 text-end">
                    {item.is_default && (
                        <span className="badge-default">Основной</span>
                    )}
                </div>
            </div>

            <div className="card-address-header">
                <h5 className="card-address-title">
                    {item.address ? item.address : `-`}
                </h5>
            </div>
            {/* <div className="card-address-body">
            <small className="text-opacity">Л/С {item.id}</small>
        </div> */}
            {/* <div className="card-address-footer row">
                <div className="col-auto text-opacity">
                    <i className="icn icn-fire"></i>0 ГВС
                </div>
                <div className="col-auto text-opacity">
                    <i className="icn icn-water"></i>0 ХВС
                </div>
            </div> */}

            {item.house_id || item.account ? (
                <div className="card-address-footer row">
                    <div className="col-auto">
                        <a
                            onClick={onPress}
                            className="btn btn-light text-danger"
                        >
                            <i className="fa-solid fa-location-dot mr-0"></i>
                        </a>
                    </div>
                    <div className="col-auto">
                        <a
                            onClick={() => OpenTelegram(telegramLink)}
                            className="btn btn-light text-primary"
                        >
                            {/* <i className="icn icn-chat-white"></i> */}
                            <i className="fa-brands fa-telegram mr-0"></i>
                        </a>
                    </div>
                </div>
            ) : null}
        </div>
    )
}
