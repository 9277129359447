import React, { useState, useRef } from 'react'
import {
    Text,
    View,
    TextInput,
    StyleSheet,
    Pressable,
    Switch,
    Image,
    TouchableOpacity,
} from 'react-native'

// import * as ImagePicker from 'expo-image-picker'
import { ImagePicker } from '../helpers/webHelper'

import { RNPickerSelect } from '../helpers/webHelper'
// import RNPickerSelect from 'react-native-picker-select'

import { TextInputMask } from 'react-native-masked-text'

// import DateTimePicker from '@react-native-community/datetimepicker';

import { s } from '../helpers/style'
import { style } from '../styles/mainStyles'
import { Icn } from './Elements/Icn'
// import { BtnRemove } from './Btn'

// keyboardType:
//   default
//   number-pad
//   decimal-pad
//   numeric
//   email-address
//   phone-pad

export function InputField({
    label,
    placeholder,
    value,
    onChangeText,
    onBlur,
    type,
    name,
}) {
    const [isValid, setIsValid] = useState(true)

    let inputF = null

    if (type && type === 'phone-pad') {
        inputF = (
            <TextInputMask
                type={'custom'}
                options={{
                    mask: '+7 (999) 999-99-99',
                }}
                value={value}
                style={[s.formControl, style['form-control'], styles.input]}
                onBlur={onBlur}
                placeholder={placeholder}
                onChangeText={(text) => {
                    text = text.replace(/[^0-9]/g, '')
                    onChangeText(text)
                }}
                keyboardType={type ? type : 'default'}
            />
        )
    } else if (name && name === 'account_id') {
        inputF = (
            <TextInputMask
                type={'custom'}
                options={{
                    mask: '99999999',
                }}
                value={value}
                style={[s.formControl, style['form-control'], styles.input]}
                onBlur={onBlur}
                placeholder={placeholder}
                onChangeText={(text) => {
                    text = text.replace(/[^0-9]/g, '')
                    onChangeText(text)
                }}
                keyboardType={type ? type : 'default'}
            />
        )
    } else {
        inputF = (
            <TextInput
                onChangeText={(text) => {
                    if (type && type === 'email-address') {
                        const isEmailValid = /^[^@]+@\w+(\.\w+)+\w$/.test(text)
                        setIsValid(isEmailValid)
                    }

                    onChangeText(text)
                }}
                onBlur={onBlur}
                value={value}
                placeholder={placeholder}
                style={[
                    s.formControl,
                    style['form-control'],
                    styles.input,
                    isValid ? `` : styles.error,
                ]}
                keyboardType={type ? type : 'default'}
            />
        )
    }

    // {type && type === 'phone-pad' ? (
    //     <TextInputMask
    //         type={'custom'}
    //         options={{
    //             mask: '+7 (999) 999-99-99',
    //         }}
    //         value={value}
    //         style={[s.formControl, style['form-control'], styles.input]}
    //         onBlur={onBlur}
    //         placeholder={placeholder}
    //         onChangeText={(text) => {
    //             text = text.replace(/[^0-9]/g, '')
    //             onChangeText(text)
    //         }}
    //         keyboardType={type ? type : 'default'}
    //     />
    // ) : (
    // <TextInput
    //     onChangeText={onChangeText}
    //     onBlur={onBlur}
    //     value={value}
    //     placeholder={placeholder}
    //     style={[s.formControl, style['form-control'], styles.input]}
    //     keyboardType={type ? type : 'default'}
    // />
    // )}

    return (
        <View style={[s.formGroup, s.mb4]}>
            <Text style={[s.formLabel, style['form-label']]}>{label}</Text>
            {inputF}
        </View>
    )
}

export function InputPassword({
    label,
    placeholder,
    value,
    onChangeText,
    onBlur,
    type,
}) {
    const [hidePass, setHidePass] = useState(true)

    return (
        <View style={[s.formGroup, s.mb3]}>
            <Text style={[s.formLabel, style['form-label'], styles.label]}>
                {label}
            </Text>

            <View style={styles.row}>
                <TextInput
                    onChangeText={onChangeText}
                    onBlur={onBlur}
                    value={value}
                    placeholder={placeholder}
                    style={[s.formControl, style['form-control'], styles.input]}
                    keyboardType={type ? type : 'default'}
                    secureTextEntry={hidePass ? true : false}
                    textContentType="password"
                />

                <TouchableOpacity
                    onPress={() => setHidePass(!hidePass)}
                    style={[styles.buttonPassword]}
                >
                    <Icn
                        icon={hidePass ? 'eye-outline' : 'eye-off-outline'}
                        color="secondary"
                        size="sm"
                    />
                </TouchableOpacity>
            </View>
        </View>
    )
}

export function InputCode({ value, onChangeText }) {
    // https://github.com/thoughtbot/react-native-code-input-example

    const CODE_LENGTH = 6

    // const codeDigitsArray = new Array(CODE_LENGTH).fill(0)

    // const toDigitInput = (_value, idx) => {
    //     const emptyInputChar = ' '
    //     const digit = value[idx] || emptyInputChar

    //     return (
    // <View key={idx} style={styles.codeItem}>
    //     <Text style={styles.codeItemText}>{digit}</Text>
    // </View>
    //     )
    // }

    const [containerIsFocused, setContainerIsFocused] = useState(true)

    const codeDigitsArray = new Array(CODE_LENGTH).fill(0)

    const codeRef = useRef(null)

    const handleOnPress = () => {
        console.log('handleOnPress')
        setContainerIsFocused(true)
        codeRef?.current?.focus()
    }

    const handleOnBlur = () => {
        console.log('handleOnBlur')
        setContainerIsFocused(false)
    }

    const toDigitInput = (_value, idx) => {
        const emptyInputChar = ' '
        const digit = value[idx] || emptyInputChar

        const isCurrentDigit = idx === value.length
        const isLastDigit = idx === CODE_LENGTH - 1
        const isCodeFull = value.length === CODE_LENGTH

        const isFocused = isCurrentDigit || (isLastDigit && isCodeFull)

        const containerStyle =
            containerIsFocused && isFocused
                ? styles.codeItemFocused
                : styles.codeItem

        return (
            <View key={idx} style={containerStyle}>
                <Text style={styles.codeItemText}>{digit}</Text>
            </View>
        )
    }

    return (
        <View style={[s.formGroup, s.mb4]}>
            <Pressable style={styles.codeRow} onPress={handleOnPress}>
                {codeDigitsArray.map(toDigitInput)}
            </Pressable>

            <TextInput
                ref={codeRef}
                onChangeText={onChangeText}
                // onBlur={onBlur}
                onBlur={handleOnBlur}
                value={value}
                style={[styles.inputCode]}
                keyboardType="number-pad"
                returnKeyType="done"
                maxLength={CODE_LENGTH}
                textContentType="oneTimeCode"
                autoFocus={true}
            />
        </View>
    )
}

export function TextareaField({
    label,
    placeholder,
    value,
    onChangeText,
    onBlur,
}) {
    return (
        <View style={[s.formGroup, s.mb4]}>
            <Text style={[s.formLabel, style['form-label']]}>{label}</Text>
            <TextInput
                onChangeText={onChangeText}
                onBlur={onBlur}
                value={value}
                placeholder={placeholder}
                style={[s.formControl, style['form-control'], styles.input]}
            />
        </View>
    )
}

// https://github.com/react-native-datetimepicker/datetimepicker
// export function DateField({
//     label,
//     placeholder,
//     value,
//     onChangeText,
//     onBlur,
//     mode = 'date',
// }) {
//     /*
//     mode:
//       "date" (default for iOS and Android and Windows)
//       "time"
//       "datetime" (iOS only)
//       "countdown" (iOS only)
//   */

//     return <></>
//     // const [date, setDate] = useState(new Date());

//     // const onChange = (event, selectedDate) => {
//     //   const currentDate = selectedDate || date;
//     //   // setShow(Platform.OS === 'ios');
//     //   setDate(currentDate);
//     // };

//     // return <DateTimePicker
//     //         testID="dateTimePicker"
//     //         value={date}
//     //         mode={mode}
//     //         is24Hour={true}
//     //         display="default"
//     //         onChange={onChange}
//     //       />
// }

// https://snack.expo.dev/@lfkwtz/react-native-picker-select

export function SelectField({
    label,
    placeholder,
    selectedValue,
    onValueChange,
    items,
    icon,
}) {
    let placeholderObject = {
        label: placeholder,
        value: '',
    }

    return (
        <View style={[s.formGroup, s.mb4]}>
            {label && (
                <Text style={[s.formLabel, style['form-label']]}>{label}</Text>
            )}
            <RNPickerSelect
                selectedValue={selectedValue}
                onValueChange={onValueChange}
                items={items}
                placeholder={placeholderObject}
                style={pickerSelectStyles}
                doneText="Выбрать"
            />
            {icon && (
                <View style={styles.iconSelect}>
                    <Icn icon={icon} color="danger" />
                </View>
            )}
        </View>
    )
}

export function SelectFieldSmall({ selectedValue, onValueChange, items }) {
    let placeholderObject = {
        label: '-',
        value: 0,
    }

    return (
        <View style={styles.selectFieldSmall}>
            <RNPickerSelect
                selectedValue={selectedValue}
                value={selectedValue}
                onValueChange={onValueChange}
                items={items}
                placeholder={placeholderObject}
                style={pickerSelectSmallStyles}
                useNativeAndroidPickerStyle={false}
                doneText="Выбрать"
            />
            {/* <View style={styles.iconSelectSmall}>
                <Icn icon="chevron-down" color="danger" />
            </View> */}
        </View>
    )
}

export function SearchField({
    placeholder,
    value,
    onChangeText,
    onBlur,
    onSubmitEditing,
}) {
    return (
        <View style={[styles.row, styles.border]}>
            <Icn icon="search" color="dark" size="sm" />
            <TextInput
                onChangeText={onChangeText}
                onBlur={onBlur}
                value={value}
                placeholder={placeholder}
                returnKeyType="search"
                blurOnSubmit={true}
                onSubmitEditing={onSubmitEditing}
                style={[
                    s.formControl,
                    style['form-control'],
                    styles.inputSearch,
                ]}
            />
        </View>
    )
}

export function SwitchField({ label, value = false, onValueChange, onBlur }) {
    const [isEnabled, setIsEnabled] = useState(value)
    const toggleSwitch = () => {
        setIsEnabled((previousState) => !previousState)
        if (onValueChange) onValueChange((previousState) => !previousState)
        if (onBlur) onBlur(!isEnabled)
    }

    return (
        <View style={[styles.row]}>
            <Switch
                trackColor={{ false: '#555860', true: '#d21100' }}
                thumbColor="#FFFFFF"
                ios_backgroundColor="#555860"
                onValueChange={toggleSwitch}
                value={isEnabled}
            />
            <Text
                style={[
                    s.ml3,
                    style['form-check-label'],
                    isEnabled ? s.textDanger : s.textSecondary,
                ]}
            >
                {label}
            </Text>
        </View>
    )
}

const ImageItem = ({ item, onRemove }) => {
    return (
        <View>
            <Image source={{ uri: item.localUri }} style={styles.image} />
            <TouchableOpacity
                onPress={onRemove}
                style={[s.bgDanger, styles.buttonRemove]}
            >
                <Icn icon="trash" color="white"></Icn>
            </TouchableOpacity>
        </View>
    )
}

export function GalleryField({ onChange }) {
    let [selectedImages, setSelectedImages] = React.useState([])

    const deleteHandler = (indx) => {
        const removeImage = selectedImages.filter(
            (item, index) => index !== indx
        )
        setSelectedImages(removeImage)
        if (onChange) onChange(removeImage)
    }

    let openImagePickerAsync = async () => {
        let pickerResult = await ImagePicker.launchImageLibraryAsync()

        if (pickerResult.cancelled === true) {
            return
        }

        const addImage = selectedImages.concat([{ localUri: pickerResult.uri }])

        setSelectedImages(addImage)
        if (onChange) onChange(addImage)
    }

    return (
        <View style={[s.flexRow]}>
            {selectedImages.map((selectedImage, index) => (
                <ImageItem
                    key={index}
                    onRemove={() => deleteHandler(index)}
                    item={selectedImage}
                />
            ))}

            {selectedImages.length < 3 ? (
                <div className="form-image-picker">
                    <TouchableOpacity
                        onPress={openImagePickerAsync}
                        style={styles.buttonImage}
                    >
                        <i className="fa-solid fa-file-image"></i>
                        {/* <Icn icon="photo" color="danger" size="lg" />
                        <Text style={[styles.buttonImageText, s.textDanger]}>
                            Добавить фотографию
                        </Text> */}
                    </TouchableOpacity>
                </div>
            ) : null}
        </View>
    )
}

const styles = StyleSheet.create({
    input: {
        borderWidth: 0,
        borderBottomColor: '#918b8d',
        borderBottomWidth: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        fontSize: 14,
    },
    inputSearch: {
        borderWidth: 0,
    },
    inputPassword: {
        borderWidth: 0,
    },
    border: {
        borderBottomColor: '#918b8d',
        borderBottomWidth: 1,
        // height: 36,
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    image: {
        width: 104,
        height: 104,
        borderRadius: 24,
        marginRight: 8,
    },
    buttonPassword: {
        width: 20,
        height: 20,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        position: 'absolute',
        right: 0,
        bottom: 15,
    },
    buttonImage: {
        width: 104,
        height: 104,
        borderRadius: 24,
        borderWidth: 1,
        borderColor: 'rgba(0,0,0,.1)',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    buttonImageText: {
        fontSize: 14,
        marginTop: 4,
        textAlign: 'center',
    },
    buttonRemove: {
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 32,
        position: 'absolute',
        right: 0,
        top: -6,
    },
    iconSelect: {
        position: 'absolute',
        top: 23,
        right: 0,
    },
    selectFieldSmall: {
        width: 60,
    },
    iconSelectSmall: {
        position: 'absolute',
        top: 0,
        right: 4,
    },
    codeBorder: {
        width: 240,
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginHorizontal: 'auto',
    },
    codeBorderItem: {
        borderBottomWidth: 1,
        borderBottomColor: '#918b8d',
        width: 30,
    },
    inputCode: {
        fontSize: 24,
        letterSpacing: 30,
        marginLeft: 30,
        textAlign: 'center',
        borderWidth: 1,
        borderColor: '#000',
        position: 'absolute',
        // left: 0,
        // top: 0,
        // opacity: 0,
        height: 0,
        width: 0,
        opacity: 0,
    },
    codeRow: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    codeItem: {
        borderBottomWidth: 1,
        borderBottomColor: '#918b8d',
        width: 30,
        marginHorizontal: 6,
        paddingVertical: 6,
    },
    codeItemFocused: {
        borderBottomWidth: 1,
        borderBottomColor: 'red',
        width: 30,
        marginHorizontal: 6,
        paddingVertical: 6,
        boxShadow: '0px 0px 5px rgba(210, 18, 1, 0.3)',
    },
    codeItemText: {
        textAlign: 'center',
        fontSize: 24,
    },
})

const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
        fontSize: 14,
        borderWidth: 0,
        borderBottomColor: '#918b8d',
        borderBottomWidth: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: 8,
        paddingVertical: 10,
        paddingRight: 30, // to ensure the text is never behind the icon
    },
    inputAndroid: {
        fontSize: 14,
        borderWidth: 0,
        borderBottomColor: '#918b8d',
        borderBottomWidth: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: 8,
        paddingVertical: 10,
        paddingRight: 30, // to ensure the text is never behind the icon
    },
    inputWeb: {
        fontSize: 14,
        borderWidth: 0,
        borderBottomColor: '#918b8d',
        borderBottomWidth: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: 8,
        paddingVertical: 10,
        paddingRight: 30,
    },
})

const pickerSelectSmallInput = {
    fontSize: 12,
    height: 13,
    borderColor: '#918b8d',
    borderWidth: 1,
    borderRadius: 16,
    padding: 8,
    paddingVertical: 8,
    width: 60,
}

const pickerSelectSmallStyles = StyleSheet.create({
    inputIOS: pickerSelectSmallInput,
    inputAndroid: pickerSelectSmallInput,
    inputWeb: pickerSelectSmallInput,
})
