import * as React from 'react'
// import { Text, View } from 'react-native'
// import { s } from '../../helpers/style'
// import { style } from '../../styles/mainStyles'
// import { Icn } from '../Elements/Icn'

export const HousesCard = ({ item }) => {
    return (
        <div className="area-house-card">
            <i className="fa-solid fa-location-dot text-danger"></i>
            {item.address_street} <b> {item.address_house}</b>
        </div>
        // <View style={[style['custom-list-item'], s.mt3]}>
        //     <View style={[style.row, s.mb2]}>
        //         <Icn icon="pointer2" color="danger"></Icn>
        //         <Text style={[s.fontWeightBold, s.ml2, style['opacity-50']]}>
        //             {item.address_street} {item.address_house}
        //         </Text>
        //     </View>
        // </View>
    )
}

// address_building: "123"
// address_house: "123"
// address_office: "123"
// address_street: "123"
// address_structure: "123"
// area_id: 29
// city_id: null
// created_at: "2021-08-27 14:24:20"
// id: 44
// telegram_link: "123"
