import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { MyHomeContainer } from '../../containers/Profile/MyHomeContainer'

export const MyHomePage = (props) => {
    return (
        <div className="white-box">
            <PageHeader title="Мой дом" back="/profile" />
            <h1>Мой дом</h1>
            <MyHomeContainer {...props} />
        </div>
    )
}
