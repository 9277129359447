import React from 'react'
import { connect } from 'react-redux'
import { MyHome } from '../../components/Profile/MyHome'

const Container = ({ company }) => {
    return <MyHome data={company} />
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
    company: state.user.company,
})

let mapDispatchToProps = () => {
    return {}
}

export const MyHomeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
