import React from 'react'
import { View, Text } from 'react-native'
import { Formik } from 'formik'
import { InputField } from '../Fields'
import { Btn } from '../Btn'
import { connect } from 'react-redux'

import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'

const RegistrationSetAccountForm = ({
    onSubmit,
    registration_phone,
    setStep,
}) => {
    let formValues = {
        account_number: '',
        apartment_number: '',
        phone: registration_phone,
    }

    return (
        <View>
            <Text style={[style.p, s.textCenter]}>
                Введите номер лицевого счета и номер квартиры. Данные вы можете
                найти на своей квитанции
            </Text>

            <Formik initialValues={formValues} onSubmit={onSubmit}>
                {({ handleChange, handleBlur, handleSubmit, values }) => (
                    <View>
                        <InputField
                            label="Лицевой счет"
                            value={values.account_number}
                            onChangeText={handleChange('account_number')}
                            onBlur={handleBlur('account_number')}
                            type="number-pad"
                        />

                        <InputField
                            label="Номер квартиры"
                            value={values.apartment_number}
                            onChangeText={handleChange('apartment_number')}
                            onBlur={handleBlur('apartment_number')}
                            type="number-pad"
                        />
                        <Btn onPress={handleSubmit} color="btnDanger">
                            Далее
                        </Btn>
                    </View>
                )}
            </Formik>

            <View style={s.mt3}>
                <Btn onPress={() => setStep(5)} color="btnSecondary">
                    Пропустить
                </Btn>
            </View>
        </View>
    )
}

let mapStateToProps = (state) => ({
    registration_phone: state.user.registration_phone,
})

let mapDispatchToProps = () => {
    return {}
}

export const RegistrationSetAccount = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationSetAccountForm)
