import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { ProfileEditContainer } from '../../containers/Profile/ProfileEditContainer'

export const ProfileEditPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader title="Настройки профиля" />
            <h1>Настройки профиля</h1>
            <ProfileEditContainer {...props} />
        </div>
    )
}
