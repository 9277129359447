import moment from 'moment'
// import 'moment/min/moment-with-locales'
import 'moment/locale/ru.js'
moment.locale('ru')

export function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString =
        splitDataURI[0].indexOf('base64') >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}

export function DataAddressToString(list) {
    if (!list || !list.length) return ''

    let listFormatted = list.filter(
        (element) => element !== null && element !== '' && element !== ' '
    )

    return listFormatted && listFormatted.length ? listFormatted.join(', ') : ''
}

export function DataTimeFormat(str) {
    if (!str) return str

    var strArr = str.split(':')
    if (strArr.length !== 3) return str

    return `${strArr[0]}:${strArr[1]}`
}

export function getDateList() {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((item) => {
        const startDate = moment().add(item, 'days'),
            endDate = moment().add(item + 1, 'days')

        return {
            label: `${startDate.format('dd DD.MM')} – ${endDate.format(
                'dd DD.MM'
            )}`,
            value: `${startDate.format('YYYY-MM-DD')} – ${endDate.format(
                'YYYY-MM-DD'
            )}`,
        }
    })
}

export function getTimeList() {
    return [
        { label: '08:00 – 11:00', value: '08:00 – 11:00' },
        { label: '11:00 – 14:00', value: '11:00 – 14:00' },
        { label: '14:00 – 17:00', value: '14:00 – 17:00' },
        { label: '17:00 – 20:00', value: '17:00 – 20:00' },
    ]
}
