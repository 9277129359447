import React, { useState, useEffect } from 'react'
import api from '../../services/api'
import { NewsBlock } from '../../components/News/NewsBlock'
import { ActivityIndicator } from 'react-native'

const PAGE_SIZE = 6 // eslint-disable-line

export const NewsBlockContainer = () => {
    const [response, setResponse] = useState(null)

    const fetch = () => {
        api.fetch(`news`, {
            'paginator[limit]': PAGE_SIZE,
            'paginator[page]': 1,
            extend: 'preview_file,house,city',
        }).then((response) => {
            setResponse(response.data)
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    if (!response) return <ActivityIndicator color="#d21100" />

    const { items } = response

    return <NewsBlock items={items} />
}
