import React from 'react'

export const FaqView = ({ data }) => {
    return (
        <div>
            <h1>{data.title}</h1>
            <div className="mb-5">
                <div dangerouslySetInnerHTML={{ __html: data.text }} />
            </div>
        </div>
    )
}
