import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { HouseViewContainer } from '../../containers/House/HouseViewContainer'

export const HouseViewPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader title="Участок" back={`/profile`} />
            <HouseViewContainer {...props} />
        </div>
    )
}
