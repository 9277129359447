import React from 'react'
import { View, Text } from 'react-native'
import { Formik } from 'formik'
import { InputField } from '../Fields'
import { Btn } from '../Btn'
import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'
import api from '../../services/api'
import { ErrorAlert } from '../../helpers/apiMessageHelper'

const ChangePassword = ({ onSubmit, formValues = { password: '' } }) => {
    const onSubmitForm = (values) => {
        api.post(`me/change-password`, values)
            .then(() => {
                onSubmit()
            })
            .catch((error) => {
                ErrorAlert(error)
            })
    }

    return (
        <View style={{ width: '100%' }}>
            <Text style={[s.h3, style.h3, s.textCenter]}>Изменение пароля</Text>
            <Text style={[style.p, s.mb4, s.textCenter]}>
                Введите новый пароль
            </Text>

            <Formik initialValues={formValues} onSubmit={onSubmitForm}>
                {({ handleChange, handleBlur, handleSubmit, values }) => (
                    <View>
                        <InputField
                            label="Новый пароль"
                            placeholder="******"
                            value={values.password}
                            onChangeText={handleChange('password')}
                            onBlur={handleBlur('password')}
                        />
                        {/* <InputField
                        label='Повторить новый пароль'
                        placeholder='******'
                        value={values.passwordRepeat}
                        onChangeText={handleChange('passwordRepeat')}
                        onBlur={handleBlur('passwordRepeat')}
                    />                 */}
                        <Btn onPress={handleSubmit} color="btnDanger">
                            Сохранить
                        </Btn>
                    </View>
                )}
            </Formik>
        </View>
    )
}

export default ChangePassword
