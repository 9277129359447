import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { InfoContainer } from '../../containers/Contacts/InfoContainer'

export const InfoPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader back="/contacts" title="Контакты" />
            <h1>Контакты</h1>
            <InfoContainer {...props} />
        </div>
    )
}
