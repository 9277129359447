export const icons = {
    white: {
        dashboard: require(`../assets/icons/white/dashboard.png`),
        arrowLeft: require(`../assets/icons/white/arrow-left.png`),
        arrowRight: require(`../assets/icons/white/arrow-right.png`),
        calendar: require(`../assets/icons/white/calendar.png`),
        chat: require(`../assets/icons/white/chat.png`),
        edit: require(`../assets/icons/white/edit.png`),
        fire: require(`../assets/icons/white/fire.png`),
        home: require(`../assets/icons/white/home.png`),
        info: require(`../assets/icons/white/info.png`),
        instagram: require(`../assets/icons/white/instagram.png`),
        logout2: require(`../assets/icons/white/logout-2.png`),
        logout: require(`../assets/icons/white/logout.png`),
        mail: require(`../assets/icons/white/mail.png`),
        meter: require(`../assets/icons/white/meter.png`),
        notification: require(`../assets/icons/white/notification.png`),
        phone: require(`../assets/icons/white/phone.png`),
        photo: require(`../assets/icons/white/photo.png`),
        play: require(`../assets/icons/white/play.png`),
        pointer2: require(`../assets/icons/white/pointer-2.png`),
        pointer: require(`../assets/icons/white/pointer.png`),
        question: require(`../assets/icons/white/question.png`),
        search: require(`../assets/icons/white/search.png`),
        setting: require(`../assets/icons/white/setting.png`),
        time: require(`../assets/icons/white/time.png`),
        trash: require(`../assets/icons/white/trash.png`),
        user: require(`../assets/icons/white/user.png`),
        vk: require(`../assets/icons/white/vk.png`),
        water: require(`../assets/icons/white/water.png`),
    },
    danger: {
        dashboard: require(`../assets/icons/danger/dashboard.png`),
        arrowLeft: require(`../assets/icons/danger/arrow-left.png`),
        arrowRight: require(`../assets/icons/danger/arrow-right.png`),
        calendar: require(`../assets/icons/danger/calendar.png`),
        chat: require(`../assets/icons/danger/chat.png`),
        edit: require(`../assets/icons/danger/edit.png`),
        fire: require(`../assets/icons/danger/fire.png`),
        home: require(`../assets/icons/danger/home.png`),
        info: require(`../assets/icons/danger/info.png`),
        instagram: require(`../assets/icons/danger/instagram.png`),
        logout2: require(`../assets/icons/danger/logout-2.png`),
        logout: require(`../assets/icons/danger/logout.png`),
        mail: require(`../assets/icons/danger/mail.png`),
        meter: require(`../assets/icons/danger/meter.png`),
        notification: require(`../assets/icons/danger/notification.png`),
        phone: require(`../assets/icons/danger/phone.png`),
        photo: require(`../assets/icons/danger/photo.png`),
        play: require(`../assets/icons/danger/play.png`),
        pointer2: require(`../assets/icons/danger/pointer-2.png`),
        pointer: require(`../assets/icons/danger/pointer.png`),
        question: require(`../assets/icons/danger/question.png`),
        search: require(`../assets/icons/danger/search.png`),
        setting: require(`../assets/icons/danger/setting.png`),
        time: require(`../assets/icons/danger/time.png`),
        trash: require(`../assets/icons/danger/trash.png`),
        user: require(`../assets/icons/danger/user.png`),
        vk: require(`../assets/icons/danger/vk.png`),
        water: require(`../assets/icons/danger/water.png`),
        externalLink: require(`../assets/icons/danger/external-link.png`),
    },
    dark: {
        dashboard: require(`../assets/icons/dark/dashboard.png`),
        arrowLeft: require(`../assets/icons/dark/arrow-left.png`),
        arrowRight: require(`../assets/icons/dark/arrow-right.png`),
        calendar: require(`../assets/icons/dark/calendar.png`),
        chat: require(`../assets/icons/dark/chat.png`),
        edit: require(`../assets/icons/dark/edit.png`),
        fire: require(`../assets/icons/dark/fire.png`),
        home: require(`../assets/icons/dark/home.png`),
        info: require(`../assets/icons/dark/info.png`),
        instagram: require(`../assets/icons/dark/instagram.png`),
        logout2: require(`../assets/icons/dark/logout-2.png`),
        logout: require(`../assets/icons/dark/logout.png`),
        mail: require(`../assets/icons/dark/mail.png`),
        meter: require(`../assets/icons/dark/meter.png`),
        notification: require(`../assets/icons/dark/notification.png`),
        phone: require(`../assets/icons/dark/phone.png`),
        photo: require(`../assets/icons/dark/photo.png`),
        play: require(`../assets/icons/dark/play.png`),
        pointer2: require(`../assets/icons/dark/pointer-2.png`),
        pointer: require(`../assets/icons/dark/pointer.png`),
        question: require(`../assets/icons/dark/question.png`),
        search: require(`../assets/icons/dark/search.png`),
        setting: require(`../assets/icons/dark/setting.png`),
        time: require(`../assets/icons/dark/time.png`),
        trash: require(`../assets/icons/dark/trash.png`),
        user: require(`../assets/icons/dark/user.png`),
        vk: require(`../assets/icons/dark/vk.png`),
        water: require(`../assets/icons/dark/water.png`),
    },
    secondary: {
        dashboard: require(`../assets/icons/secondary/dashboard.png`),
        arrowLeft: require(`../assets/icons/secondary/arrow-left.png`),
        arrowRight: require(`../assets/icons/secondary/arrow-right.png`),
        calendar: require(`../assets/icons/secondary/calendar.png`),
        chat: require(`../assets/icons/secondary/chat.png`),
        edit: require(`../assets/icons/secondary/edit.png`),
        fire: require(`../assets/icons/secondary/fire.png`),
        home: require(`../assets/icons/secondary/home.png`),
        info: require(`../assets/icons/secondary/info.png`),
        instagram: require(`../assets/icons/secondary/instagram.png`),
        logout2: require(`../assets/icons/secondary/logout-2.png`),
        logout: require(`../assets/icons/secondary/logout.png`),
        mail: require(`../assets/icons/secondary/mail.png`),
        meter: require(`../assets/icons/secondary/meter.png`),
        notification: require(`../assets/icons/secondary/notification.png`),
        phone: require(`../assets/icons/secondary/phone.png`),
        photo: require(`../assets/icons/secondary/photo.png`),
        play: require(`../assets/icons/secondary/play.png`),
        pointer2: require(`../assets/icons/secondary/pointer-2.png`),
        pointer: require(`../assets/icons/secondary/pointer.png`),
        question: require(`../assets/icons/secondary/question.png`),
        search: require(`../assets/icons/secondary/search.png`),
        setting: require(`../assets/icons/secondary/setting.png`),
        time: require(`../assets/icons/secondary/time.png`),
        trash: require(`../assets/icons/secondary/trash.png`),
        user: require(`../assets/icons/secondary/user.png`),
        vk: require(`../assets/icons/secondary/vk.png`),
        water: require(`../assets/icons/secondary/water.png`),
        'eye-outline': require(`../assets/icons/secondary/eye-outline.png`),
        'eye-off-outline': require(`../assets/icons/secondary/eye-off-outline.png`),
    },
    warning: {
        dashboard: require(`../assets/icons/warning/dashboard.png`),
        arrowLeft: require(`../assets/icons/warning/arrow-left.png`),
        arrowRight: require(`../assets/icons/warning/arrow-right.png`),
        calendar: require(`../assets/icons/warning/calendar.png`),
        chat: require(`../assets/icons/warning/chat.png`),
        edit: require(`../assets/icons/warning/edit.png`),
        fire: require(`../assets/icons/warning/fire.png`),
        home: require(`../assets/icons/warning/home.png`),
        info: require(`../assets/icons/warning/info.png`),
        instagram: require(`../assets/icons/warning/instagram.png`),
        logout2: require(`../assets/icons/warning/logout-2.png`),
        logout: require(`../assets/icons/warning/logout.png`),
        mail: require(`../assets/icons/warning/mail.png`),
        meter: require(`../assets/icons/warning/meter.png`),
        notification: require(`../assets/icons/warning/notification.png`),
        phone: require(`../assets/icons/warning/phone.png`),
        photo: require(`../assets/icons/warning/photo.png`),
        play: require(`../assets/icons/warning/play.png`),
        pointer2: require(`../assets/icons/warning/pointer-2.png`),
        pointer: require(`../assets/icons/warning/pointer.png`),
        question: require(`../assets/icons/warning/question.png`),
        search: require(`../assets/icons/warning/search.png`),
        setting: require(`../assets/icons/warning/setting.png`),
        time: require(`../assets/icons/warning/time.png`),
        trash: require(`../assets/icons/warning/trash.png`),
        user: require(`../assets/icons/warning/user.png`),
        vk: require(`../assets/icons/warning/vk.png`),
        water: require(`../assets/icons/warning/water.png`),
    },
}
