import React, { useState } from 'react'
import { AnimateCounter } from '../Commons/AnimateCounter'
import ScrollAnimation from 'react-animate-on-scroll'

export const PointsBlock = ({ profile }) => {
    const isAnimate = window.innerWidth >= 990 ? false : true
    const [showDigits, isShowDigits] = useState(isAnimate)
    // let count = '365'

    // if (profile && profile.bonus_count) {
    //     count = String(profile.bonus_count)
    // }

    const bonusMax = 3000
    const bonusCurrent = profile?.bonus_count
        ? parseInt(profile.bonus_count)
        : 0
    const percent = (bonusCurrent * 100) / bonusMax

    return (
        <div className="index-points bg-warning bg-opacity-80">
            <div className="index-card-icon bg-warning text-dark">
                <ion-icon name="trophy-outline" size="large"></ion-icon>
            </div>

            <div className="index-points-body">
                <ScrollAnimation
                    animateIn="fadeIn"
                    initiallyVisible={isAnimate}
                    afterAnimatedIn={function afterAnimatedIn() {
                        isShowDigits(true)
                    }}
                >
                    <div className="index-points-title">
                        Ваши бонусы
                        {/* {profile && <sup>{profile.bonus_level}</sup>} */}
                    </div>

                    <div className="index-points-text">1 балл = 1 рубль</div>

                    <div className="index-points-line points-block-line">
                        <div className="index-points-count">
                            {showDigits && (
                                <AnimateCounter
                                    number={bonusCurrent}
                                    duration={2}
                                />
                            )}
                            <span>баллов</span>
                        </div>
                        <div
                            className="points-block-fill"
                            style={{ width: percent + '%' }}
                        ></div>
                    </div>
                </ScrollAnimation>

                {/* <div className="index-points-cost">
                <AnimateCounter number={count} duration={3} /> Б
            </div> */}
            </div>
        </div>

        /*
        <div className="points-block">
            <div className="points-block-header">
                <h4 className="points-block-title">
                    Статус
                    {profile && (
                        <span className="badge rounded-pill bg-danger">
                            {profile.bonus_level}
                        </span>
                    )}
                </h4>
                <span className="points-block-link">
                    <i className="bi bi-info-circle text-danger"></i>
                    Подробно
                </span>
            </div>
            <div className="points-block-body row">
                <div className="col-4">
                    <b className="points-block-val">
                        {profile && profile.bonus_count}
                    </b>
                    <span className="opacity-50">баллов</span>
                </div>
                <div className="col-8">
                    <div className="points-block-line">
                        <div
                            className="points-block-fill"
                            style={{ width: percent + '%' }}
                        ></div>
                    </div>
                    <div className="points-block-text">
                        До следующего уровня
                        <br />
                        необходимо накопить {bonusNeed} баллов
                    </div>
                </div>
            </div>
        </div>
        */
    )
}
