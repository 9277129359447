import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { AreaHousesContainer } from '../../containers/Area/AreaHousesContainer'

export const AreaHousesPage = (props) => {
    console.log('props', props)
    return (
        <div className="white-box">
            <PageHeader
                title="Дома в обслуживании"
                back={props.history.goBack}
            />
            <h1>Дома в обслуживании</h1>
            <AreaHousesContainer {...props} />
        </div>
    )
}
