import React from 'react'
import { connect } from 'react-redux'
import api from '../../services/api'
import {
    authUser,
    setProfile,
    setCity,
    setCityList,
    setCompany,
    setSettings,
} from '../../store/actions/UserActions'
import { ErrorAlert } from '../../helpers/apiMessageHelper'
import { LoginForm } from '../../components/Auth/LoginForm'

const Container = ({ onSubmit }) => {
    let formValues = { username: '', password: '' }

    return <LoginForm formValues={formValues} onSubmit={onSubmit} />
}

export const onRefreshLogin = () => {
    // refresh_token
}

export const onAuthLogin = (values, onSubmitSuccess, dispatch) => {
    api.login(values)
        .then(async (response) => {
            await dispatch(authUser(response.data))

            api.profile().then((response) => {
                let cityId = response.data.data.city_id

                // if (!cityId)
                //     cityId = response.data.data?.default_account?.house?.city_id

                dispatch(setProfile(response.data.data))
                dispatch(setCity(cityId))

                // список городов
                api.fetch(`cities`, {
                    'per-page': 100,
                }).then((response) => {
                    dispatch(setCityList(response.data.items))
                })

                // список настроек
                api.fetch(`settings`, {
                    'per-page': 100,
                }).then((response) => {
                    dispatch(setSettings(response.data.items))
                })

                // test компания
                api.fetch(`companies`, {
                    'per-page': 1,
                    extend: 'timings,provider_type,city,company_detail',
                    'filter[city_id]': `EQ(${cityId})`,
                    // 'filter[city_id]' : `EQ(4)`
                }).then((response) => {
                    const { items } = response.data
                    if (items && items.length) dispatch(setCompany(items[0]))
                })

                onSubmitSuccess()
            })
            // dispatch(getProfile());
        })
        .catch((error) => {
            ErrorAlert(error)
        })
}

let mapStateToProps = (state) => ({
    access_token: state.user.access_token,
})

let mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (values, onSubmitSuccess) => {
            onAuthLogin(values, onSubmitSuccess, dispatch)
            // api.login(values)
            //     .then(async (response) => {
            //         await dispatch(authUser(response.data.access_token))

            //         api.get(`me`, {
            //             // extend: 'default_account,accounts,accounts.house',
            //             extend: 'new_notifications_count,default_account,accounts,accounts.house,default_account.house',
            //         }).then((response) => {
            //             let cityId = response.data.data.city_id

            //             if (!cityId)
            //                 cityId =
            //                     response.data.data?.default_account?.house
            //                         ?.city_id

            //             dispatch(setProfile(response.data.data))
            //             dispatch(setCity(cityId))

            //             // список городов
            //             api.fetch(`cities`, {
            //                 'per-page': 100,
            //             }).then((response) => {
            //                 dispatch(setCityList(response.data.items))
            //             })

            //             // test компания
            //             api.fetch(`companies`, {
            //                 'per-page': 1,
            //                 extend: 'timings,provider_type,city',
            //                 'filter[city_id]': `EQ(${cityId})`,
            //                 // 'filter[city_id]' : `EQ(4)`
            //             }).then((response) => {
            //                 const { items } = response.data

            //                 if (items && items.length)
            //                     dispatch(setCompany(items[0]))
            //             })

            //             onSubmitSuccess()
            //         })
            //         // dispatch(getProfile());
            //     })
            //     .catch((error) => {
            //         ErrorAlert(error)
            //     })
        },
    }
}

export const LoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
