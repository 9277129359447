import React, { useState, useEffect } from 'react'
import { Text, ActivityIndicator } from 'react-native'

import api from '../../services/api'
import { DataAddressToString } from '../../helpers/dataHelper'
import { HouseView } from '../../components/Houses/HousesView'

export const HouseViewContainer = (props) => {
    const { match, route } = props
    const [response, setResponse] = useState({})
    const [worktime, setWorktime] = useState([])

    const { id } = match ? match.params : route.params

    const fetch = () => {
        api.fetchById(`houses`, id, {
            extend: 'area,area.timings',
        }).then((response) => {
            const { area_id } = response.data.data

            if (area_id) {
                api.fetch(`area-timings`, {
                    'filter[area_id]': `EQ(${area_id})`,
                }).then((responseTimings) => {
                    setWorktime(responseTimings.data.items)
                })
            }

            setResponse(response.data)
        })
    }

    useEffect(() => {
        fetch()
    }, [id])

    const { data } = response

    if (!data) return <ActivityIndicator color="#d21100" />

    const { area } = data
    let areaAddress = ''

    if (!area)
        return (
            <Text style={{ textAlign: 'center' }}>
                Нет информации по участку/дому
            </Text>
        )

    areaAddress = DataAddressToString([
        area.address_city,
        area.address_street,
        area.address_building,
        area.address_house,
        area.address_structure,
        area.address_office,
    ])

    return (
        <HouseView
            area={area}
            areaAddress={areaAddress}
            worktime={worktime}
            {...props}
        />
    )
}
