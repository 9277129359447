import React from 'react'
import { useHistory } from 'react-router-dom'
import { NewsCard } from '../News/NewsCard'
// import { Redirect } from 'react-router-dom'

export const NewsList = ({ response }) => {
    const { items } = response
    const history = useHistory()

    const RenderItem = ({ item }) => {
        return (
            <NewsCard
                item={item}
                onPress={() => {
                    history.push(`/news/${item.id}`)
                }}
            />
        )
    }

    return (
        <div className="news-list">
            {items &&
                items.map((item) => (
                    <RenderItem item={item} key={item.id.toString()} />
                ))}
        </div>
    )
}
