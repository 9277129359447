import React from 'react'

export const MarketplaceSteps = () => (
    <div>
        <h2 className="text-center">Как это работает</h2>
        <div className="service-step">
            <div className="service-step-list">
                <div className="service-step-item">
                    <div className="service-step-preview">
                        <img src="/images/service-step-1.svg" alt="" />
                    </div>
                    Размещаете заказ
                </div>
                <div className="service-step-item">
                    <div className="service-step-preview">
                        <img src="/images/service-step-2.svg" alt="" />
                    </div>
                    Подбираем лучшего исполнителя
                </div>
                <div className="service-step-item">
                    <div className="service-step-preview">
                        <img src="/images/service-step-3.svg" alt="" />
                    </div>
                    Подтверждаете заказ
                </div>
                <div className="service-step-item">
                    <div className="service-step-preview">
                        <img src="/images/service-step-4.svg" alt="" />
                    </div>
                    Выполнение заказа
                </div>
                <div className="service-step-item">
                    <div className="service-step-preview">
                        <img src="/images/service-step-5.svg" alt="" />
                    </div>
                    Оплата
                </div>
            </div>
        </div>
    </div>
)
