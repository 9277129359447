import React, { useState, useEffect } from 'react'
import api from '../../services/api'
import { NotificationsList } from '../../components/Notifications//NotificationsList'
import { ActivityIndicator } from 'react-native'

import getEnv from '../../helpers/envHelper'
const { REACT_APP_PAGE_SIZE } = getEnv()

export const NotificationsContainer = () => {
    const [response, setResponse] = useState(null)
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const fetch = (filter = {}) => {
        api.get(`/me/notifications`, {
            'paginator[limit]': REACT_APP_PAGE_SIZE,
            'paginator[page]': 1,
            sort: '-created_at',
            ...filter,
            // 'extend' : 'service,ticket_status'
        })
            .then((fetchResponse) => {
                let responseData = fetchResponse.data,
                    responseItems =
                        response && response.items ? response.items : []
                responseData.items = responseItems.concat(responseData.items)

                setResponse(fetchResponse.data)
                setPageCount(fetchResponse.data._meta.pageCount)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const loadMore = async () => {
        if (!isLoading) {
            let nextPage = page + 1
            if (nextPage > pageCount) return false
            setIsLoading(true)
            setPage(nextPage)
            await fetch({ page: nextPage })
        }
    }

    const readAll = () => {
        api.post(`/me/notifications/all/read`)
    }

    useEffect(() => {
        fetch()
        readAll()
    }, [])

    if (!response) return <ActivityIndicator size="large" color="#d21100" />

    return <NotificationsList response={response} loadMore={loadMore} />
}

// export const NotificationsRead = ({ id }) => {
//     api.post(`/me/notifications/${id}/read`)
// }
