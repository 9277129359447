import * as React from 'react'
// import { Text, ImageBackground, StyleSheet } from 'react-native'

// import { s } from '../../helpers/style'
// import { style } from '../../styles/mainStyles'
// import imgBanner from '../../assets/images/service-banner.png'

export const BannerMarketplace = () => {
    return (
        <div className="service-banner">
            <h4>Вернем деньги!</h4>
            <p>
                Мы вернем деньги, если вам не понравится
                <br />
                результат оказанной услуги
            </p>
        </div>
    )
}

// const styles = StyleSheet.create({
//     image: {
//         paddingVertical: 16,
//         paddingLeft: 20,
//         paddingRight: 40,
//         borderRadius: 24,
//         overflow: 'hidden',
//     },
// })
