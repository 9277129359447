import React, { useState, useEffect } from 'react'
import { ActivityIndicator, Linking } from 'react-native'
import moment from 'moment'
import api from '../../services/api'
import {
    getOrderStatusById,
    getTicketStatusById,
    getTicketStatusBySlug,
} from '../../helpers/statusHelper'
import { ErrorAlert, MessageAlert } from '../../helpers/apiMessageHelper'
import { TicketsView } from '../../components/Tickets/TicketsView'
import { connect } from 'react-redux'
import { setOrderId } from '../../store/actions/OrderActions'

import getEnv from '../../helpers/envHelper'
import { saveFileByUri } from '../../helpers/fileHelper'
const {
    REACT_APP_PAY_SUCCESS_URL,
    REACT_APP_PAY_FAIL_URL,
    REACT_APP_PAY_PARTIAL_SUCCESS_URL,
    REACT_APP_PAY_PARTIAL_FAIL_URL,
    REACT_APP_CDN,
} = getEnv()

const onPressPay = (id) => {
    api.post(
        `tickets/${id}/pay?success-url=${REACT_APP_PAY_SUCCESS_URL}&fail-url=${REACT_APP_PAY_FAIL_URL}`,
        {},
        {
            'success-url': REACT_APP_PAY_SUCCESS_URL, // eslint-disable-line
            'fail-url': REACT_APP_PAY_FAIL_URL, // eslint-disable-line
        }
    )
        .then((response) => {
            let { data } = response
            if (data && data['payment-url'])
                Linking.openURL(data['payment-url'])
            else ErrorAlert()
        })
        .catch((error) => {
            ErrorAlert(error)
        })
}

const onPressPayMaster = (id) => {
    api.post(
        `/tickets/${id}/pay-partial?success-url=${REACT_APP_PAY_PARTIAL_SUCCESS_URL}&fail-url=${REACT_APP_PAY_PARTIAL_FAIL_URL}`,
        {},
        {
            'success-url': REACT_APP_PAY_PARTIAL_SUCCESS_URL, // eslint-disable-line
            'fail-url': REACT_APP_PAY_PARTIAL_FAIL_URL, // eslint-disable-line
        }
    )
        .then((response) => {
            let { data } = response
            if (data && data['payment-url'])
                Linking.openURL(data['payment-url'])
            else ErrorAlert()
        })
        .catch((error) => {
            ErrorAlert(error)
        })

    // MessageAlert('Ошибка', `Оплата выезда мастера по заявке №${id} не прошла`)
}

const Container = ({
    match,
    route,
    profile,
    setOrderId,
    statuses,
    orderStatuses,
}) => {
    const [response, setResponse] = useState({})
    const [reviews, setReviews] = useState(null)
    const { id } = match ? match.params : route.params

    const [modalReview, setModalReview] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)

    const [agreePay, setAgreePay] = useState(false)
    const [rate, setRate] = useState(0)

    const [cancelModal, setCancelModal] = useState(false)

    const fetch = () => {
        const extend = [
            'user',
            'account',
            'ticket_status',
            'service',
            'service.preview_file',
            'service_option',
            'reviews',
            'address',
            'city',
            'ticket_items',
            'ticket_items.service_option',
            'ticket_items.service_option.service',
            'ticket_items.service_option.service.preview_file',
            'master',
        ]
        api.fetchById(`tickets`, id, {
            extend: extend.join(','),
        }).then((response) => {
            setResponse(response.data)
        })

        api.fetch(`tickets/${id}/reviews`, {
            // extend: 'user,account,ticket_status,service,service.preview_file,reviews',
        }).then((response) => {
            setReviews(response.data)
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    const onPressCancel = (id, onSuccess) => {
        setCancelModal(true)

        if (onSuccess) {
            onSuccess()
        }
        // api.post(`tickets/${id}/cancel`)
        //     .then(() => {
        //         if (onSuccess) {
        //             onSuccess()
        //         }

        //         MessageAlert('Успешно', `Заявка №${id} отменена.`)
        //     })
        //     .catch((error) => {
        //         ErrorAlert(error)
        //     })
    }

    const onPressAct = (id) => {
        api.get(`tickets/${id}/act`)
            .then((responseAct) => {
                if (
                    typeof responseAct.data === 'string' ||
                    responseAct.data instanceof String
                ) {
                    saveFileByUri(`${REACT_APP_CDN}/${responseAct.data}`)
                } else {
                    saveFileByUri(responseAct.data.url)
                }
            })
            .catch((error) => {
                ErrorAlert(error)
            })
    }

    const onPressConfirmation = (id) => {
        const findStatus = getTicketStatusBySlug('confirmation_work')
        if (findStatus) {
            api.patch(`tickets/${id}`, {
                ticket_status_id: findStatus.id,
            })
                .then(() => {
                    MessageAlert('Успешно', `Заявка №${id} подтверждена.`)
                    fetch()
                })
                .catch((error) => {
                    ErrorAlert(error)
                })
        } else {
            MessageAlert('Ошибка', `Статус не найден`)
        }
    }

    const onPrePressPay = (id) => {
        if (!agreePay) {
            MessageAlert(
                'Ошибка',
                'Для оплаты заявки нужно согласиться с условиями оплат.'
            )
            return
        }

        onPressPay(id)
    }

    const onPrePressPayMaster = (id) => {
        if (!agreePay) {
            MessageAlert(
                'Ошибка',
                'Для оплаты нужно согласиться с условиями оплат.'
            )
            return
        }

        onPressPayMaster(id)
    }

    const onUpdate = () => {
        fetch()
    }

    if (!response || !response?.data)
        return <ActivityIndicator size="large" color="#d21100" />

    const { data } = response
    const { service } = data

    const period =
        moment(`${data.start_period_date} ${data.start_period_time}`).format(
            'DD.MM.YYYY, H:mm'
        ) +
        ' ' +
        moment(`${data.end_period_date} ${data.end_period_time}`).format(
            'DD.MM.YYYY, H:mm'
        )

    const startDate = moment(
        `${data.start_period_date} ${data.start_period_time}`
    ).format('DD.MM.YYYY, H:mm')

    const endDate = moment(
        `${data.end_period_date} ${data.end_period_time}`
    ).format('DD.MM.YYYY, H:mm')

    let status = statuses ? getTicketStatusById(data.ticket_status_id) : null
    const orderStatus = orderStatuses
        ? getOrderStatusById(data.ticket_payment_status_id)
        : null

    if (orderStatus && status.canPayMaster) {
        status.canPayMaster =
            orderStatus.slug === 'partially_paid' || orderStatus.slug === 'paid'
                ? false
                : true
    }

    let isShowPayment = false

    if (status && status.slug === 'approved') {
        isShowPayment = true
    }

    return (
        <TicketsView
            id={id}
            profile={profile}
            service={service}
            status={status}
            rate={rate}
            setRate={setRate}
            modalReview={modalReview}
            setModalReview={setModalReview}
            modalEdit={modalEdit}
            setModalEdit={setModalEdit}
            data={data}
            period={period}
            isShowPayment={isShowPayment}
            onPressPay={onPrePressPay}
            onPressPayMaster={onPrePressPayMaster}
            onPressCancel={onPressCancel}
            reviews={reviews ? reviews.items : []}
            agreePay={agreePay}
            setAgreePay={setAgreePay}
            startDate={startDate}
            endDate={endDate}
            onUpdate={onUpdate}
            setOrderId={setOrderId}
            cancelModal={cancelModal}
            setCancelModal={setCancelModal}
            onPressConfirmation={onPressConfirmation}
            onPressAct={onPressAct}
        />
    )
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
    statuses: state.status.statuses,
    orderStatuses: state.status.orderStatuses,
})

let mapDispatchToProps = (dispatch) => {
    return {
        setOrderId: (orderId) => {
            dispatch(setOrderId(orderId))
        },
    }
}

export const TicketsViewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
