import React from 'react'
import { NotificationsCard } from '../Notifications/NotificationsCard'

export const NotificationsList = ({ response }) => {
    const { items } = response

    if (items && items.length)
        return (
            <div className="list-group list-group-flush">
                {items.map((item) => {
                    return (
                        <div className="list-group-item" key={item.id}>
                            <NotificationsCard item={item} />
                        </div>
                    )
                })}
            </div>
        )

    return <></>
}
