import React, { useState } from 'react'
import { View, Text } from 'react-native'
import { Formik } from 'formik'
import { InputField } from '../Fields'
import { Btn, BtnEdit, BtnLink } from '../Btn'

import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'

export const ChangeFieldRow = ({ item }) => {
    let { onSubmit, formValues, field, placeholder } = item
    const [formShow, setFormShow] = useState(false)

    return (
        <View style={[style['list-group-item']]}>
            {formShow ? (
                <Formik initialValues={formValues} onSubmit={onSubmit}>
                    {({ handleChange, handleBlur, handleSubmit, values }) => (
                        <View>
                            <InputField
                                placeholder={placeholder}
                                value={values[field]}
                                onChangeText={handleChange(`${field}`)}
                                onBlur={handleBlur(`${field}`)}
                            />
                            <Btn onPress={handleSubmit} color="btnDanger">
                                Сохранить
                            </Btn>
                            <BtnLink onPress={() => setFormShow(false)}>
                                Отмена
                            </BtnLink>
                        </View>
                    )}
                </Formik>
            ) : (
                <View style={[s.row]}>
                    <View style={s.col11}>
                        <Text style={style['opacity-70']}>
                            {formValues[field]}
                        </Text>
                    </View>

                    <View style={s.col1}>
                        <BtnEdit onPress={() => setFormShow(true)} />
                    </View>
                </View>
            )}

            {/* <View style={[s.row]}>
                <View style={s.col11}>
                    <Text style={style['opacity-70']}>{formValues[field]}</Text>
                </View>

                <View style={s.col1}>
                    <BtnEdit onPress={() => setFormShow(true)} />
                </View>
            </View>

            <BottomSheetModal
                visible={formShow}
                onClose={() => setFormShow(false)}
            >
                <View style={{ width: '100%' }}>
                    <Text style={[s.h3, style.h3]}>{item.title}</Text>

                    <Formik initialValues={formValues} onSubmit={onSubmit}>
                        {({
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            values,
                        }) => (
                            <View>
                                <InputField
                                    placeholder={placeholder}
                                    value={values[field]}
                                    onChangeText={handleChange(`${field}`)}
                                    onBlur={handleBlur(`${field}`)}
                                />
                                <Btn onPress={handleSubmit} color="btnDanger">
                                    Сохранить
                                </Btn>
                                <BtnLink onPress={() => setFormShow(false)}>
                                    Отмена
                                </BtnLink>
                            </View>
                        )}
                    </Formik>
                </View>
            </BottomSheetModal> */}
        </View>
    )
}
