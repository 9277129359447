import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { CartOrderContainer } from '../../containers/Cart/CartOrderContainer'

export const CartOrderPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader title="Оформление заявки" />
            <h1>Оформление заявки</h1>

            <CartOrderContainer {...props} />
        </div>
    )
}
