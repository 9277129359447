import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { NewsViewContainer } from '../../containers/News/NewsViewContainer'

export const NewsViewPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader back="/news" title="Новости"></PageHeader>
            <h1>Новости</h1>
            <NewsViewContainer {...props} />
        </div>
    )
}
