import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { LoginPage } from '../pages/Auth/LoginPage'
import { RecoveryPage } from '../pages/Auth/RecoveryPage'
import { RegistrationPage } from '../pages/Auth/RegistrationPage'
import { MainPage } from '../pages/MainPage'
import { NewsPage } from '../pages/News/NewsPage'
import { NewsViewPage } from '../pages/News/NewsViewPage'
import { NotificationsPage } from '../pages/Notifications/NotificationsPage'
import { ContactsPage } from '../pages/Contacts/ContactsPage'
import { ManagmentPage } from '../pages/Contacts/ManagmentPage'
import { AboutPage } from '../pages/Contacts/AboutPage'
import { InfoPage } from '../pages/Contacts/InfoPage'
import { CashboxPage } from '../pages/Cashbox/CashboxPage'
import { FaqPage } from '../pages/Faq/FaqPage'
import { FaqViewPage } from '../pages/Faq/FaqViewPage'
import { TicketsPage } from '../pages/Tickets/TicketsPage'
import { TicketsViewPage } from '../pages/Tickets/TicketsViewPage'

import { ServicesCategoryPage } from '../pages/Services/ServicesCategoryPage'
import { ServicesPage } from '../pages/Services/ServicesPage'
import { ServicesViewPage } from '../pages/Services/ServicesViewPage'
import { ServicesOrderPage } from '../pages/Services/ServicesOrderPage'

import { ProfilePage } from '../pages/Profile/ProfilePage'
import { ProfileEditPage } from '../pages/Profile/ProfileEditPage'
import { MyHomePage } from '../pages/Profile/MyHomePage'
import { HouseViewPage } from '../pages/House/HouseViewPage'
import { AreaHousesPage } from '../pages/Area/AreaHousesPage'

import { CartPage } from '../pages/Cart/CartPage'
import { CartOrderPage } from '../pages/Cart/CartOrderPage'

const RootNavigation = () => {
    return (
        <Switch>
            <Route path="/auth/login">
                <LoginPage />
            </Route>
            <Route path="/auth/recovery">
                <RecoveryPage />
            </Route>
            <Route path="/auth/registration">
                <RegistrationPage />
            </Route>

            <Route exact path="/news" component={NewsPage} />
            <Route path="/news/:id" component={NewsViewPage} />

            <Route path="/house/:id" component={HouseViewPage} />

            <Route path="/area/:id/houses" component={AreaHousesPage} />

            <Route exact path="/tickets" component={TicketsPage} />
            <Route path="/tickets/:id" component={TicketsViewPage} />

            <Route exact path="/faq" component={FaqPage} />
            <Route path="/faq/:id" component={FaqViewPage} />

            <Route exact path="/services" component={ServicesPage} />
            <Route
                path="/services/category/:id"
                component={ServicesCategoryPage}
            />
            <Route path="/services/order/:id" component={ServicesOrderPage} />
            <Route path="/services/:id" component={ServicesViewPage} />

            <Route path="/cashbox" component={CashboxPage} />

            <Route exact path="/contacts" component={ContactsPage} />
            <Route path="/contacts/about" component={AboutPage} />
            <Route path="/contacts/managment" component={ManagmentPage} />
            <Route path="/contacts/info" component={InfoPage} />

            <Route exact path="/notifications" component={NotificationsPage} />

            <Route exact path="/profile" component={ProfilePage} />
            <Route path="/profile/edit" component={ProfileEditPage} />
            <Route path="/profile/home" component={MyHomePage} />

            <Route exact path="/cart" component={CartPage} />
            <Route path="/cart/order" component={CartOrderPage} />

            <Route path="/">
                <MainPage />
            </Route>
        </Switch>
    )
}

export default RootNavigation
