import React, { useState, useEffect } from 'react'
import { TicketsList } from '../../components/Tickets/TicketsList'
import api from '../../services/api'
import { ActivityIndicator } from 'react-native'
import { connect } from 'react-redux'
import getEnv from '../../helpers/envHelper'
const { REACT_APP_PAGE_SIZE } = getEnv()

const Container = ({ route, statuses }) => {
    const [response, setResponse] = useState(null)
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const [currentLabel, setCurrentLabel] = useState(null)

    let random = route?.params?.random

    const fetch = (filter = {}) => {
        api.fetch(`tickets`, {
            'paginator[limit]': REACT_APP_PAGE_SIZE,
            'paginator[page]': 1,
            // 'filter[canceled_at]': 'EQN',
            sort: '-created_at',
            // 'filter[ticket_status_id]': 'EQ(4)',
            extend: 'service,ticket_status,address,ticket_items,ticket_items.service_option',
            ...filter,
        })
            .then((fetchResponse) => {
                // if (fetchResponse.data._meta.page !== 1) {
                //     let responseData = fetchResponse.data,
                //         responseItems = response && response.items ? response.items : []
                //     responseData.items = responseItems.concat(
                //         responseData.items
                //     )
                // }

                setResponse(fetchResponse.data)
                setPageCount(fetchResponse.data._meta.pageCount)
                setRefreshing(false)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const onRefresh = async () => {
        setRefreshing(true)
        setPage(1)
        setResponse(null)
        setCurrentLabel(null)
        await fetch({ page: 1 })
    }

    const loadMore = async () => {
        if (!isLoading) {
            let nextPage = page + 1
            if (nextPage > pageCount) return false
            setPage(nextPage)
            setIsLoading(true)
            await fetch({ page: nextPage })
        }
    }

    const onSearch = (formData) => {
        setPage(1)
        setResponse(null)

        let params = { 'paginator[page]': 1 }

        if (formData && formData.value) {
            let filterKey = `filter[${formData.name}]`
            params[filterKey] = `EQ(${formData.value})`

            fetch(params)
        } else {
            setCurrentLabel(null)
            fetch(params)
        }
    }

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
        setResponse(null)
        setCurrentLabel(null)
        fetch()
    }, [random])

    if (!response) return <ActivityIndicator size="large" color="#d21100" />

    return (
        <TicketsList
            onSearch={onSearch}
            response={response}
            loadMore={loadMore}
            refreshing={refreshing}
            onRefresh={onRefresh}
            statuses={statuses}
            currentLabel={currentLabel}
            setCurrentLabel={setCurrentLabel}
        />
    )
}

let mapStateToProps = (state) => ({
    statuses: state.status.statuses,
})

let mapDispatchToProps = () => {
    return {}
}

export const TicketsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
