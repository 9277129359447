import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import api from '../../services/api'
import { setProfile } from '../../store/actions/UserActions'
import { ProfileView } from '../../components/Profile/ProfileView'

const Container = ({ profile, getProfile, settings }) => {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        getProfile()
    }, [])

    let { addresses } = profile
    const { default_address_id } = profile

    if (default_address_id)
        addresses.map((item) => {
            item.is_default = item.id === default_address_id ? true : false
            return item
        })

    return (
        <ProfileView
            profile={profile}
            getProfile={getProfile}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            accounts={addresses}
            settings={settings}
        />
    )
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
    cities: state.user.cities,
    settings: state.user.settings,
})

let mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => {
            api.profile().then((response) => {
                dispatch(setProfile(response.data.data))
            })
        },
    }
}

export const ProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
