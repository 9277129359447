import { combineReducers } from 'redux'

import userReduser from './UserReducer'
import orderReducer from './OrderReducer'
import cartReducer from './CartReducer'
import statusReducer from './StatusReducer'

export default combineReducers({
    user: userReduser,
    order: orderReducer,
    cart: cartReducer,
    status: statusReducer,
})
