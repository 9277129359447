// import { createNavigationContainerRef } from '@react-navigation/native'
// export { createNavigationContainerRef }

const container = {
    isReady: () => {},
    navigate: () => {},
}
const createNavigationContainerRef = () => {
    return container
}

export { createNavigationContainerRef }
