import React from 'react'

export const RNPickerSelect = ({
    selectedValue,
    onValueChange,
    items,
    // placeholderObject,
    // pickerSelectStyles,
}) => {
    // console.log('placeholderObject', placeholderObject)
    // console.log('selectedValue', selectedValue)
    return (
        <select
            className="form-control"
            onChange={(event) => onValueChange(event.target.value)}
        >
            <option>-</option>
            {items.map((item, index) => {
                return (
                    <option
                        value={item.value}
                        selected={selectedValue && selectedValue === item.value}
                        key={index}
                    >
                        {item.label}
                    </option>
                )
            })}
        </select>
    )
}

export const ImagePicker = {
    launchImageLibraryAsync: () => {},
}

// selectedValue={selectedValue}
// onValueChange={onValueChange}
// items={items}
// placeholder={placeholderObject}
// style={pickerSelectStyles}
// doneText="Выбрать"
