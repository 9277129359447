import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { AboutContainer } from '../../containers/Contacts/AboutContainer'

export const AboutPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader back="/contacts" title="О компании" />
            <h1>О компании</h1>
            <AboutContainer {...props} />
        </div>
    )
}
