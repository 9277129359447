import React, { useState } from 'react'
import { connect } from 'react-redux'

import { RegistrationMobile } from '../../components/Registration/RegistrationMobile'
import { RegistrationMobileAccept } from '../../components/Registration/RegistrationMobileAccept'
import { RegistrationSetInfo } from '../../components/Registration/RegistrationSetInfo'
import { RegistrationSetAccount } from '../../components/Registration/RegistrationSetAccount'
import { RegistrationSetPassword } from '../../components/Registration/RegistrationSetPassword'

import { registrationUser } from '../../store/actions/UserActions'

import api from '../../services/api'
import { ErrorAlert, MessageAlert } from '../../helpers/apiMessageHelper'
import { onAuthLogin } from './LoginContainer'

/* 
Регистрация
Введите номер телефона

Регистрация
Вам поступит звонок, введите последние 6 цифр номера телефона из поступившего вызова

Регистрация
Введите ваш адрес

Регистрация
Введите номер лицевого счета и номер квартиры. Данные вы можете найти
на своей квитанции

Регистрация
Придумайте пароль для входа в приложение
*/

// const testToken =
//     'SBxcXYl65-mxQE--iJDUc-95BtFiw97jIm3hpQHiX9oIvRLNApA5RbcJvzpt8FN-RMSLht7tTn9dK1W11mqP9I6jOlWbZ4_DetgK8Dq01kEY_yrJeIzcrE4aycllIRxr3-xRxvosOuHDrX3pp61-Wx7ubnVkkeaxVwEwdR2Z0QSPkna5ksnPDlrhd7Gy0rfldSzDDalHA9PKGMZPRzUV1UO5j39K1dzP5cUUVL9RD4ZsxPDMLIL544pEVJy3h2eWjJxbZ2gnmtHJuE9x21cOksLoun4yatWORvhYc75KTduX'
const testToken = null

const Container = ({
    registration_token,
    onSubmit,
    onSubmitLogin,
    onSubmitTest,
}) => {
    const [step, setStep] = useState(1)
    const nextStep = () => setStep(step + 1)

    const beforeSubmit = (values) => {
        switch (step) {
            case 1:
                values.form = 'mobile'
                break
            case 2:
                values.form = 'mobile_accept'
                break
            case 3:
                values.form = 'set_info'
                break
            case 4:
                values.form = 'set_account'
                break
            case 5:
                values.form = 'set_password'
                break
            default:
                break
        }

        if (step === 5) {
            if ('password_repeat' in values) {
                if (values.password_repeat === values.password)
                    onSubmit(values, registration_token, onSubmitLogin)
                else MessageAlert('Ошибка', 'Пароли не совпадают')
            } else {
                onSubmit(values, registration_token, onSubmitLogin)
            }
        } else {
            if (testToken) {
                if (step === 1) onSubmitTest(nextStep)
                else onSubmit(values, registration_token, nextStep)
            } else {
                onSubmit(values, registration_token, nextStep)
            }
        }
    }

    const StepComponent = (propsComponent) => {
        switch (step) {
            case 1:
                return <RegistrationMobile {...propsComponent} />
            case 2:
                return <RegistrationMobileAccept {...propsComponent} />
            case 3:
                return <RegistrationSetInfo {...propsComponent} />
            case 4:
                return <RegistrationSetAccount {...propsComponent} />
            case 5:
                return <RegistrationSetPassword {...propsComponent} />
        }
    }

    return <StepComponent onSubmit={beforeSubmit} setStep={setStep} />
}

let mapStateToProps = (state) => ({
    registration_token: state.user.registration_token,
    cities: state.user.cities,
})

let mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmitTest: (onNextStep) => {
            dispatch(
                registrationUser({
                    registration_token: testToken,
                    registration_phone: '79061100524',
                })
            )
            onNextStep()
        },

        onSubmitLogin: (values) => {
            const { phone, password } = values

            if (phone && password) {
                onAuthLogin(
                    { username: phone, password: password },
                    ownProps.onLogin,
                    dispatch
                )
            }
        },

        onSubmit: (values, registration_token, onNextStep) => {
            api.registration(values, { registration_token })
                .then((response) => {
                    if (response.data.registration_token) {
                        let { phone } = values
                        dispatch(
                            registrationUser({
                                registration_token:
                                    response.data.registration_token,
                                registration_phone: phone,
                            })
                        )
                    }
                    onNextStep(values)
                })
                .catch((error) => {
                    ErrorAlert(error)
                })
        },
    }
}

export const RegistrationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
