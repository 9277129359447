import { Alert, Platform } from 'react-native'

export const ErrorAlert = (error) => {
    let messages = error ? error.response?.data?.errors?.messages : null,
        message = error ? error.response?.data?.errors?.message : null,
        result = 'Ошибка. Попробуйте позже.'

    if (messages) {
        for (var key in messages) {
            messages[key].map((item) => {
                result = item
            })
        }
    }

    if (message) result = message

    if (Platform.OS === 'web') {
        alert(result)
    } else {
        Alert.alert('Ошибка', result)
    }
}

export const MessageAlert = (subject, message) => {
    if (Platform.OS === 'web') {
        alert(message)
    } else {
        Alert.alert(subject, message)
    }
}

export const SuccessAlert = (message) => {
    if (!message) message = 'Успешно сохранено'

    if (Platform.OS === 'web') {
        alert(message)
    } else {
        Alert.alert('Успешно', message)
    }
}
