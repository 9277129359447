import React, { useState, useEffect } from 'react'
import { MarketplaceCategoryList } from '../../components/Marketplace/MarketplaceCategoryList'
import api from '../../services/api'
import { connect } from 'react-redux'
import { ActivityIndicator } from 'react-native'

import getEnv from '../../helpers/envHelper'
const { REACT_APP_PAGE_SIZE } = getEnv()

const Container = ({ city_id }) => {
    const [response, setResponse] = useState(null)
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    // const cityFilter = city_id ? { 'filter[city_id]': `EQ(${city_id})` } : {}

    const fetch = (filter = {}) => {
        api.get(`/collection/ticket-statuses`)

        api.fetch(`service-categories`, {
            'paginator[limit]': REACT_APP_PAGE_SIZE,
            'paginator[page]': 1,
            extend: 'services,service_count,preview_file,statistics_service_count',
            ...filter,
            // ...cityFilter,
        })
            .then((fetchResponse) => {
                // let responseData = fetchResponse.data,
                //     responseItems = response && response.items ? response.items : []
                // responseData.items = responseItems.concat(responseData.items)
                // setResponse(fetchResponse.data)
                setResponse(fetchResponse.data)
                setPageCount(fetchResponse.data._meta.pageCount)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    // useEffect(() => {
    //     fetch()
    // }, [])

    useEffect(() => {
        if (city_id) onSearch()
    }, [city_id])

    const onSearch = (formData) => {
        setPage(1)

        if (formData && formData.title)
            fetch({
                'filter[title]': `LIKE(${formData.title})`,
                'filter[services.city_id]': `EQ(${city_id})`,
            })
        else fetch({ 'filter[services.city_id]': `EQ(${city_id})` })
    }

    const loadMore = async () => {
        if (!isLoading) {
            let nextPage = page + 1
            if (nextPage > pageCount) return false
            setIsLoading(true)
            setPage(nextPage)
            await fetch({ page: nextPage })
        }
    }

    if (!response) return <ActivityIndicator size="large" color="#d21100" />

    return (
        <MarketplaceCategoryList
            onSearch={onSearch}
            response={response}
            loadMore={loadMore}
        />
    )
}

let mapStateToProps = (state) => ({
    city_id: state.user.city,
})

let mapDispatchToProps = () => {
    return {}
}

export const ServicesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
