import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { NewsContainer } from '../../containers/News/NewsContainer'

export const NewsPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader title="Новости" />
            <h1>Новости</h1>
            <NewsContainer {...props} />
        </div>
    )
}
