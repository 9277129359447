import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { FaqContainer } from '../../containers/Faq/FaqContainer'

export const FaqPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader title="Справка" />
            <h1>Справка</h1>
            <FaqContainer {...props} />
        </div>
    )
}
