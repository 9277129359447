import { SET_CART, SET_FILTERS, SET_ORDER_ID } from '../types/OrderTypes'

export const setServices = (payload) => ({
    type: SET_CART,
    payload: payload,
})

export const setFilters = (payload) => ({
    type: SET_FILTERS,
    payload: payload,
})

export const setOrderId = (payload) => ({
    type: SET_ORDER_ID,
    payload: payload,
})
