import React, { useState, useEffect } from 'react'
import { CashboxView } from '../../components/Cashbox/CashboxView'
import api from '../../services/api'
import { connect } from 'react-redux'
import { ActivityIndicator } from 'react-native'
import { ErrorAlert } from '../../helpers/apiMessageHelper'

const PAGE_SIZE = 100

const Container = ({ city_id, company }) => {
    const [response, setResponse] = useState(null)
    const [tab, setTab] = useState(0)
    const { city } = company

    const fetch = (filter = {}) => {
        // api.fetch(`cities`, {
        //     'paginator[limit]': PAGE_SIZE,
        //     'paginator[page]': 1,
        //     extend: 'cashboxes',
        //     ...filter,
        // }).then((response) => {
        //     setResponse(response.data)
        // })

        api.fetch(`cashboxes`, {
            'paginator[limit]': PAGE_SIZE,
            'paginator[page]': 1,
            extend: 'timings',
            ...filter,
        })
            .then((response) => {
                let cashboxes = response.data?.items
                if (!cashboxes) cashboxes = []

                let item = {
                    ...city,
                    cashboxes: cashboxes,
                }
                let preResponse = {
                    items: [item],
                }
                setResponse(preResponse)
            })
            .catch((e) => ErrorAlert(e))
    }

    useEffect(() => {
        // if (city_id)
        //     fetch({
        //         'filter[id]': `EQ(${city_id})`,
        //     })
        if (city_id)
            fetch({
                'filter[city_id]': `EQ(${city_id})`,
            })
    }, [city_id])

    // cashboxes?extend=timings,city,area
    if (!response) return <ActivityIndicator color="#d21100" />

    return <CashboxView response={response} tab={tab} setTab={setTab} />
}

let mapStateToProps = (state) => ({
    city_id: state.user.city,
    company: state.user.company,
})

let mapDispatchToProps = () => {
    return {}
}

export const CashboxContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
