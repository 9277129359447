import React from 'react'
import { View, Text } from 'react-native'
import { Formik } from 'formik'
import { InputField, SelectField } from '../Fields'
import { Btn } from '../Btn'
import { connect } from 'react-redux'

import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'
import api from '../../services/api'
import { ErrorAlert } from '../../helpers/apiMessageHelper'

const AccountAddForm = ({
    onSubmit,
    formValues = {
        city_id: null,
        // account_number: '',
        // apartment_number: '',
        account_id: '',
        address: '',
    },
    cities,
}) => {
    const onSubmitForm = (values) => {
        // api.post(`me/attach-account`, values)
        //     .then(() => {
        //         onSubmit()
        //     })
        //     .catch((error) => {
        //         ErrorAlert(error)
        //     })

        api.post(`me/addresses`, values)
            .then(() => {
                onSubmit()
            })
            .catch((error) => {
                ErrorAlert(error)
            })
    }

    const cityList = cities
        ? cities.map((item) => {
              return {
                  label: item.title,
                  value: item.id.toString(),
              }
          })
        : []

    return (
        <View style={{ width: '100%' }}>
            <Text style={[s.h3, style.h3, s.textCenter]}>Добавить адрес</Text>
            <Text style={[style.p, s.mb4, s.textCenter]}>
                Введите лицевой счет и номер квартиры
            </Text>

            <Formik initialValues={formValues} onSubmit={onSubmitForm}>
                {({ handleChange, handleBlur, handleSubmit, values }) => (
                    <View>
                        {cityList && cityList.length ? (
                            <SelectField
                                label="Город"
                                selectedValue={values.city_id}
                                items={cityList}
                                placeholder="Выберите город..."
                                onValueChange={handleChange('city_id')}
                            />
                        ) : null}

                        <InputField
                            label="Адрес"
                            value={values.address}
                            onChangeText={handleChange('address')}
                            onBlur={handleBlur('address')}
                        />

                        <InputField
                            label="Лицевой счет"
                            value={values.account_id}
                            onChangeText={handleChange('account_id')}
                            onBlur={handleBlur('account_id')}
                            type="number-pad"
                            name="account_id"
                        />

                        {/* <InputField
                            label="Номер квартиры"
                            value={values.apartment_number}
                            onChangeText={handleChange('apartment_number')}
                            onBlur={handleBlur('apartment_number')}
                            type="number-pad"
                        /> */}
                        <Btn onPress={handleSubmit} color="btnDanger">
                            Добавить
                        </Btn>
                    </View>
                )}
            </Formik>
        </View>
    )
}

// export default AccountAdd

let mapStateToProps = (state) => ({
    cities: state.user.cities,
})

let mapDispatchToProps = () => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountAddForm)
