import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import AsyncStorage from '../services/storage'
import api from '../services/api'
import { ActivityIndicator } from 'react-native'

import {
    setProfile,
    setCity,
    setCityList,
    setCompany,
    authUser,
    setSettings,
} from '../store/actions/UserActions'

const PreviewScreen = ({ getProfile, getCities, authRefresh }) => {
    // const fetch = async () => {
    //     const access_token = await AsyncStorage.getItem('@access_token')
    //     if (access_token) getProfile()
    //     // else navigation.navigate('Auth')
    // }

    const navigateAuth = async () => {
        // window.location.href = '/#/auth/login'
        await AsyncStorage.removeItem('@access_token')
        await AsyncStorage.removeItem('@refresh_token')
        window.location.href = '/#/auth/login'
    }

    const fetch = async () => {
        const access_token = await AsyncStorage.getItem('@access_token')
        const refresh_token = await AsyncStorage.getItem('@refresh_token')

        getCities()

        if (refresh_token) authRefresh(getProfile, navigateAuth)
        else if (access_token) getProfile()
        else navigateAuth()
    }

    useEffect(() => {
        fetch()
    }, [])

    return (
        <div
            className="preview-block"
            style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                // background: '#fff',
                width: '100%',
                // height: '100%',
                display: 'flex',
                zIndex: '99',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ActivityIndicator color="#d21100" />
        </div>
    )
}

let mapStateToProps = (state) => ({
    profile: state.user.profile,
})

let mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCities: () => {
            // список городов
            api.fetch(`cities`, {
                'per-page': 100,
            }).then((response) => {
                dispatch(setCityList(response.data.items))
            })
        },
        authRefresh: (onSuccess, onError) => {
            api.loginRefresh()
                .then(async (response) => {
                    await dispatch(authUser(response.data))
                    onSuccess()
                })
                .catch(() => {
                    onError()
                })
        },
        getProfile: () => {
            const { navigation } = ownProps

            api.profile()
                .then((response) => {
                    let cityId = response.data.data.city_id

                    dispatch(setProfile(response.data.data))
                    dispatch(setCity(cityId))

                    // список городов
                    api.fetch(`cities`, {
                        'paginator[limit]': 100,
                        'paginator[page]': 1,
                    }).then((response) => {
                        dispatch(setCityList(response.data.items))
                    })

                    // список настроек
                    api.fetch(`settings`, {
                        'per-page': 100,
                    }).then((response) => {
                        dispatch(setSettings(response.data.items))
                    })

                    // test компания
                    api.fetch(`companies`, {
                        'paginator[limit]': 1,
                        'paginator[page]': 1,
                        extend: 'timings,provider_type,city,company_detail',
                        'filter[city_id]': `EQ(${cityId})`,
                        // 'filter[city_id]' : `EQ(4)`
                    }).then((response) => {
                        const { items } = response.data

                        if (items && items.length)
                            dispatch(setCompany(items[0]))
                    })

                    if (navigation) navigation.navigate('Main')
                })
                .catch(() => {
                    dispatch(authUser(null))
                    window.location.href = '/#/auth/login'
                    // if (navigation) navigation.navigate('Auth')
                })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewScreen)
