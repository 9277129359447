import React from 'react'

import { MarketplaceCard } from '../Marketplace/MarketplaceCard'
import { TicketsFormContainer } from '../../containers/Tickets/TicketsFormContainer'

export const CartOrderView = ({
    // services,
    removeFromCart,
    changeQuantity,
    basket,
}) => {
    const navigation = {
        navigate: () => {
            history.push('/tickets')
        },
    }

    if (!basket) return null

    return (
        <div>
            <div className="service-list mb-5">
                {/* {services.map((service) => (
                    <MarketplaceCard
                        key={service.id.toString()}
                        item={service}
                        price={service.price}
                        quantity={service.count}
                        removeFromCart={removeFromCart}
                        changeQuantity={changeQuantity}
                    />
                ))} */}

                {basket &&
                    basket.map((item) => (
                        <MarketplaceCard
                            key={item.id.toString()}
                            item={item.service_option.service}
                            price={item.service_option.price}
                            quantity={item.unit_count}
                            removeFromCart={removeFromCart}
                            changeQuantity={(service, quantity) =>
                                changeQuantity(item, quantity)
                            }
                        />
                    ))}
            </div>

            <TicketsFormContainer navigation={navigation} services={basket} />
        </div>
    )
}
