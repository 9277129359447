import React, { useState } from 'react'
import { View, Text } from 'react-native'
import { Formik } from 'formik'
import { InputField, SelectField } from '../Fields'
import { Btn } from '../Btn'
import { connect } from 'react-redux'

import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'

import { AddressSuggestions } from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'

const RegistrationSetInfoForm = ({ cities, onSubmit, registration_phone }) => {
    const [valueAddress, setValueAddress] = useState()

    let formValues = {
        name: '',
        email: '',
        city_id: '',
        // street: '',
        // house_number: '',
        address: '',
        phone: registration_phone,
    }

    const dadataToken = process.env.REACT_APP_DADATA_TOKEN  // eslint-disable-line

    const cityList = cities
        ? cities.map((item) => {
              return {
                  label: item.title,
                  value: item.id.toString(),
              }
          })
        : []

    const onChangeDadata = (value, values) => {
        // const { data } = value
        // values.street = data.street
        // values.house_number = data.house

        values.address = value.value
        setValueAddress(value)
    }

    return (
        <View>
            <Text style={[style.p, s.textCenter]}>
                Введите ФИО, e-mail и ваш адрес
            </Text>
            <Formik initialValues={formValues} onSubmit={onSubmit}>
                {({ handleChange, handleBlur, handleSubmit, values }) => (
                    <View>
                        <InputField
                            label="ФИО"
                            value={values.name}
                            onChangeText={handleChange('name')}
                            onBlur={handleBlur('name')}
                        />
                        <InputField
                            label="E-mail"
                            value={values.email}
                            onChangeText={handleChange('email')}
                            onBlur={handleBlur('email')}
                            type="email-address"
                        />

                        {cityList && cityList.length && (
                            <SelectField
                                label="Город"
                                selectedValue={values.city_id}
                                items={cityList}
                                placeholder="Выберите город..."
                                onValueChange={handleChange('city_id')}
                            />
                        )}

                        <div className="mb-3 text-start">
                            <label>Адрес</label>
                            <AddressSuggestions
                                token={dadataToken}
                                value={valueAddress}
                                minChars={3}
                                inputProps={{
                                    className: 'form-control',
                                    placeholder: 'Введите адрес',
                                    onChange: handleChange('address'),
                                }}
                                onChange={(value) =>
                                    onChangeDadata(value, values)
                                }
                            />
                        </div>

                        {/* <InputField
                            label="Адрес"
                            value={values.address}
                            onChangeText={handleChange('address')}
                            onBlur={handleBlur('address')}
                        />
                        <InputField
                            label="Улица"
                            value={values.street}
                            onChangeText={handleChange('street')}
                            onBlur={handleBlur('street')}
                        />
                        <InputField
                            label="Дом"
                            value={values.house_number}
                            onChangeText={handleChange('house_number')}
                            onBlur={handleBlur('house_number')}
                        /> */}
                        <Btn onPress={handleSubmit} color="btnDanger">
                            Далее
                        </Btn>
                    </View>
                )}
            </Formik>
        </View>
    )
}

let mapStateToProps = (state) => ({
    cities: state.user.cities,
    registration_phone: state.user.registration_phone,
})

let mapDispatchToProps = () => {
    return {}
}

export const RegistrationSetInfo = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationSetInfoForm)
