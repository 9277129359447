import React, { useState, useEffect } from 'react'
import { NewsList } from '../../components/News/NewsList'
import api from '../../services/api'
import { ActivityIndicator } from 'react-native'

import getEnv from '../../helpers/envHelper'
const { REACT_APP_PAGE_SIZE } = getEnv()

export const NewsContainer = () => {
    const [response, setResponse] = useState(null)

    const fetch = () => {
        api.fetch(`news`, {
            'paginator[limit]': REACT_APP_PAGE_SIZE,
            'paginator[page]': 1,
            extend: 'preview_file',
        }).then((response) => {
            setResponse(response.data)
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    if (!response) return <ActivityIndicator size="large" color="#d21100" />

    return <NewsList response={response} />
}
