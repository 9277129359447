import React from 'react'
import { View, Text } from 'react-native'
import { Formik } from 'formik'
import { InputField } from '../Fields'
import { Btn } from '../Btn'

import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'

export const RecoveryPassword = ({ onSubmit }) => {
    let formValues = {
        password: '',
        password_repeat: '',
    }

    return (
        <View>
            <Text style={[style.p, s.textCenter]}>Введите новый пароль</Text>

            <Formik initialValues={formValues} onSubmit={onSubmit}>
                {({ handleChange, handleBlur, handleSubmit, values }) => (
                    <View>
                        <InputField
                            label="Пароль"
                            placeholder="••••••"
                            value={values.password}
                            onChangeText={handleChange('password')}
                            onBlur={handleBlur('password')}
                        />
                        <InputField
                            label="Повторить пароль"
                            placeholder="••••••"
                            value={values.password_repeat}
                            onChangeText={handleChange('password_repeat')}
                            onBlur={handleBlur('password_repeat')}
                        />
                        <Btn onPress={handleSubmit} color="btnDanger">
                            Изменить
                        </Btn>
                    </View>
                )}
            </Formik>
        </View>
    )
}
