import React from 'react'
import { useHistory } from 'react-router-dom'
import { MarketplaceCategoryCard } from '../Marketplace/MarketplaceCategoryCard'
import { Search } from '../../components/Elements/Search'

const colors = [
    '#FFEDE9',
    '#FFF4E0',
    '#FFE7DE',
    '#DAE1F5',
    '#F4ECEB',
    '#F4F7FF',
]

const RenderItem = ({ item, history }) => {
    const colorKey = item.id % 6

    return (
        <div className="service-category">
            <MarketplaceCategoryCard
                item={item}
                color={colors[colorKey]}
                size="lg"
                onPress={() => history.push(`/services/category/${item.id}`)}
            />
        </div>
    )
}

export const MarketplaceCategoryList = ({ response, onSearch }) => {
    const { items } = response
    const history = useHistory()

    return (
        <div>
            <div className="mb-5 search-block">
                <Search onSubmit={onSearch} placeholder="Выгул собак" />
            </div>

            <div className="mb-0">
                <h2>Категории</h2>
                <div className="service-category-list">
                    {items &&
                        items.map((item) => (
                            <RenderItem
                                item={item}
                                key={item.id.toString()}
                                history={history}
                            />
                        ))}
                </div>

                {/* <div className="mt-5">
                    <a onClick={loadMore} className="btn btn-danger">
                        Показать еще
                    </a>
                </div> */}
            </div>
        </div>
    )
}
