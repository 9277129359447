import {
    AUTH_USER,
    REGISTRATION_USER,
    RECOVERY_USER,
    SET_PROFILE,
    LOGOUT_USER,
    SET_CITY,
    SET_CITY_LIST,
    SET_COMPANY,
    SET_SETTINGS,
} from '../types/UserTypes'

import { store } from '../../App'
import { getCart } from './CartActions'
import { getStatusList, getOrderStatusList } from './StatusActions'

export const authUser = (payload) => ({
    type: AUTH_USER,
    payload: payload,
})

export const registrationUser = (payload) => ({
    type: REGISTRATION_USER,
    payload: payload,
})

export const recoveryUser = (payload) => ({
    type: RECOVERY_USER,
    payload: payload,
})

export const setProfile = (payload) => {
    store.dispatch(getCart())
    store.dispatch(getStatusList())
    store.dispatch(getOrderStatusList())

    return {
        type: SET_PROFILE,
        payload: payload,
    }
}

export const setCity = (payload) => ({
    type: SET_CITY,
    payload: payload,
})

export const setCityList = (payload) => ({
    type: SET_CITY_LIST,
    payload: payload,
})

export const setCompany = (payload) => ({
    type: SET_COMPANY,
    payload: payload,
})

export const logoutUser = (payload) => ({
    type: LOGOUT_USER,
    payload: payload,
})

export const setSettings = (payload) => ({
    type: SET_SETTINGS,
    payload: payload,
})
