import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { NotificationsContainer } from '../../containers/Notifications/NotificationsContainer'

export const NotificationsPage = (props) => {
    return (
        <div className="white-box">
            <h1>Уведомления</h1>
            <PageHeader title="Уведомления" />
            <NotificationsContainer {...props} />
        </div>
    )
}
