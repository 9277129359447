import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { ContactsContainer } from '../../containers/Contacts/ContactsContainer'

export const ContactsPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader title="Контакты" />
            <h1>Контакты</h1>
            <ContactsContainer {...props} />
        </div>
    )
}
