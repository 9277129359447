import React, { useState } from 'react'
import { connect } from 'react-redux'
import { RecoveryMobile } from '../../components/Recovery/RecoveryMobile'
import { RecoveryMobileAccept } from '../../components/Recovery/RecoveryMobileAccept'
import { RecoveryPassword } from '../../components/Recovery/RecoveryPassword'
import { recoveryUser } from '../../store/actions/UserActions'

import api from '../../services/api'
import { ErrorAlert, MessageAlert } from '../../helpers/apiMessageHelper'
/* 
Восстановление пароля
Введите номер телефона

Восстановление пароля
Вам поступит звонок, введите последние 6 цифр номера телефона из поступившего вызова

Восстановление пароля
Введите новый пароль 
*/

const Container = ({ remember_token, onSubmit, onLogin }) => {
    const [step, setStep] = useState(1)

    const onNextStep = () => setStep(step + 1)

    const beforeSubmit = (values) => {
        switch (step) {
            case 1:
                values.form = 'mobile'
                break
            case 2:
                values.form = 'mobile_accept'
                break
            case 3:
                values.form = 'set_password'
                break
            default:
                break
        }

        if (step === 3) {
            if ('password_repeat' in values) {
                if (values.password_repeat === values.password)
                    onSubmit(values, remember_token, onLogin)
                else MessageAlert('Ошибка', 'Пароли не совпадают')
            } else {
                onSubmit(values, remember_token, onLogin)
            }
        } else {
            onSubmit(values, remember_token, onNextStep)
        }
    }

    const StepComponent = (propsComponent) => {
        switch (step) {
            case 1:
                return <RecoveryMobile {...propsComponent} />
            case 2:
                return <RecoveryMobileAccept {...propsComponent} />
            case 3:
                return <RecoveryPassword {...propsComponent} />
        }
    }

    return <StepComponent onSubmit={beforeSubmit} setStep={setStep} />
}

let mapStateToProps = (state) => ({
    remember_token: state.user.remember_token,
})

let mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (values, remember_token, onNextStep) => {
            api.remember(values, { remember_token })
                .then((response) => {
                    if (response.data.remember_token)
                        dispatch(recoveryUser(response.data.remember_token))
                    onNextStep()
                })
                .catch((error) => {
                    ErrorAlert(error)
                })
        },
    }
}

export const RecoveryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
