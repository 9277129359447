export async function saveFileByUri(uri) {
    const anchor = document.createElement('a')
    anchor.href = uri
    anchor.download = 'kimi_act.docx'

    // Append to the DOM
    document.body.appendChild(anchor)

    // Trigger `click` event
    anchor.click()

    // Remove element from DOM
    document.body.removeChild(anchor)
}
