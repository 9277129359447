import React from 'react'
import { Linking } from 'react-native'
import { Link } from 'react-router-dom'
import { OpenPhone } from '../../helpers/linkHelper'
import { ExternalLinks } from './ExternalLinks'

export const ContactsView = ({ data }) => {
    return (
        <div>
            {data.dispatching_phone && (
                <div className="mb-4">
                    <div className="mw-custom flex-between mb-1">
                        <div>
                            <h4 className="mb-1">Диспетчерская служба:</h4>
                            <h3
                                onClick={() =>
                                    OpenPhone(data.dispatching_phone)
                                }
                            >
                                {data.dispatching_phone}
                            </h3>
                        </div>
                        <a onClick={() => OpenPhone(data.dispatching_phone)}>
                            <i className="icn icn-phone-white bg-danger"></i>
                        </a>
                    </div>
                    <div className="opacity-50">Круглосуточно</div>
                </div>
            )}

            {data.contact_email && (
                <p>
                    <a
                        href="/"
                        onClick={() =>
                            Linking.openURL(`mailto:${data.contact_email}`)
                        }
                    >
                        <i className="icn icn-mail-danger"></i>
                        {data.contact_email}
                    </a>
                </p>
            )}

            <ul className="custom-list">
                <li className="custom-list-item">
                    <Link to="/contacts/about">О компании</Link>
                </li>
                <li className="custom-list-item">
                    <Link to="/contacts/info">Контакты</Link>
                </li>
                {/* <li className="custom-list-item">
                    <Link to="/cashbox">Адреса участков</Link>
                </li> */}
                <li className="custom-list-item">
                    <Link to="/contacts/managment">Режим работы</Link>
                </li>
            </ul>

            <hr />

            <h4 className="mb-4">Социальные сети</h4>

            <div className="row">
                {data.social_instagram_link && (
                    <div className="col-auto">
                        <a
                            href={data.social_instagram_link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="icn icn-lg icn-instagram-danger"></i>
                        </a>
                    </div>
                )}

                {data.social_vk_link && (
                    <div className="col-auto">
                        <a
                            href={data.social_vk_link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="icn icn-lg icn-vk-danger"></i>
                        </a>
                    </div>
                )}

                {data.social_telegram_link && (
                    <div className="col-auto">
                        <a
                            href={data.social_telegram_link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="icn icn-lg icn-telegram-danger"></i>
                        </a>
                    </div>
                )}
            </div>

            <hr />

            <h4 className="mb-4">Документы</h4>
            <ExternalLinks items={data.infos} />
        </div>
    )
}
