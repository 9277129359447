import React from 'react'
import { AccountCard } from '../../components/Account/AccountCard'
import AccountAdd from '../../components/Account/AccountAdd'
import Modal from 'react-modal'
import { useHistory } from 'react-router'

export const ProfileView = ({
    getProfile,
    isVisible,
    setIsVisible,
    accounts,
    settings,
}) => {
    const history = useHistory()

    return (
        <div>
            <h2>Мои адреса</h2>

            <div className="row">
                {accounts && accounts.length
                    ? accounts.map((item, index) => (
                          <div
                              className="col-xl-4 col-12 mb-3"
                              key={item.id.toString()}
                          >
                              <AccountCard
                                  item={item}
                                  index={index}
                                  settings={settings}
                                  onPress={() => {
                                      if (item.account)
                                          history.push(
                                              `/house/${item.account.house_id}`
                                          )
                                  }}
                              />
                          </div>
                      ))
                    : null}

                <div className="col-xl-4 col-12 mb-3">
                    <div
                        className="card-address-add"
                        onClick={() => setIsVisible(true)}
                    >
                        <i className="fa-solid fa-plus"></i>
                    </div>
                </div>
            </div>

            {/* <hr /> */}
            {/* <a className="text-danger" onClick={() => setIsVisible(true)}>
                <b>+ Добавить адрес</b>
            </a> */}

            <Modal
                isOpen={isVisible}
                onRequestClose={() => setIsVisible(false)}
                ariaHideApp={false}
            >
                <div>
                    <a
                        onClick={() => setIsVisible(false)}
                        className="modal-close-link"
                    ></a>
                    <AccountAdd
                        onSubmit={() => {
                            setIsVisible(false)
                            getProfile()
                        }}
                    />
                </div>
            </Modal>
        </div>
    )
}
