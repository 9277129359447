import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { ServicesCategoryContainer } from '../../containers/Services/ServicesCategoryContainer'

export const ServicesCategoryPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader title="Витрина услуг" back="/services" />
            <h1>Витрина услуг</h1>
            <ServicesCategoryContainer {...props} />
        </div>
    )
}
