import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { Formik } from 'formik'
import { InputCode } from '../Fields'
import { Btn } from '../Btn'

import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'

export const RecoveryMobileAccept = ({ onSubmit }) => {
    let formValues = {
        phone_code: '',
    }

    return (
        <View>
            <Text style={[style.p, s.textCenter, s.mb4]}>
                Вам поступит звонок, введите последние 6 цифр номера телефона из
                поступившего вызова
            </Text>

            <View style={[styles.example, s.mb4]}>
                <Text style={[styles.exampleItem]}>+7 ( 999 ) 8</Text>
                <View style={[styles.exampleItem, s.textWhite, s.bgSecondary]}>
                    <Text style={styles.exampleText}>8</Text>
                </View>
                <View style={[styles.exampleItem, s.textWhite, s.bgSecondary]}>
                    <Text style={styles.exampleText}>8</Text>
                </View>
                <Text style={[styles.exampleItem]}>-</Text>
                <View style={[styles.exampleItem, s.textWhite, s.bgSecondary]}>
                    <Text style={styles.exampleText}>7</Text>
                </View>
                <View style={[styles.exampleItem, s.textWhite, s.bgSecondary]}>
                    <Text style={styles.exampleText}>7</Text>
                </View>
                <Text style={[styles.exampleItem]}>-</Text>
                <View style={[styles.exampleItem, s.textWhite, s.bgSecondary]}>
                    <Text style={styles.exampleText}>5</Text>
                </View>
                <View style={[styles.exampleItem, s.textWhite, s.bgSecondary]}>
                    <Text style={styles.exampleText}>5</Text>
                </View>
            </View>

            <Text style={[style.p, s.mb4, s.textCenter, style['opacity-50']]}>
                Последние 6 цифр
            </Text>

            <Formik initialValues={formValues} onSubmit={onSubmit}>
                {({ handleChange, handleBlur, handleSubmit, values }) => (
                    <View>
                        <InputCode
                            label="Код"
                            placeholder="_ _ _ _ _ _"
                            value={values.phone_code}
                            onChangeText={handleChange('phone_code')}
                            onBlur={handleBlur('phone_code')}
                        />
                        <Btn onPress={handleSubmit} color="btnDanger">
                            Далее
                        </Btn>
                    </View>
                )}
            </Formik>
        </View>
    )
}

const styles = StyleSheet.create({
    example: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    exampleItem: {
        paddingHorizontal: 4,
        paddingVertical: 2,
        borderRadius: 4,
        marginRight: 2,
        fontSize: 18,
    },
    exampleText: {
        fontSize: 18,
        color: '#FFF',
    },
})
