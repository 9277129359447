import React from 'react'
import { MarketplaceSteps } from '../../components/Marketplace/MarketplaceSteps'
import { ServicesViewContainer } from '../../containers/Services/ServicesViewContainer'

export const ServicesViewPage = (props) => {
    return (
        <>
            <div className="white-box mb-3">
                <ServicesViewContainer {...props} />
            </div>
            <div className="black-box">
                <MarketplaceSteps />
            </div>
        </>
    )
}
