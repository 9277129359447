import React from 'react'
import { View, Text } from 'react-native'
import { Formik } from 'formik'
import { InputField } from '../Fields'
import { Btn } from '../Btn'

import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'

export const RecoveryMobile = ({ onSubmit }) => {
    let formValues = {
        phone: '',
    }

    return (
        <View>
            <Text style={[style.p, s.textCenter]}>Введите номер телефона</Text>

            <Formik initialValues={formValues} onSubmit={onSubmit}>
                {({ handleChange, handleBlur, handleSubmit, values }) => (
                    <View>
                        <InputField
                            label="Телефон"
                            placeholder="+7 ( 9 _ _ ) _ _ _ - _ _ - _ _"
                            value={values.phone}
                            onChangeText={handleChange('phone')}
                            onBlur={handleBlur('phone')}
                            type="phone-pad"
                        />
                        <Btn onPress={handleSubmit} color="btnDanger">
                            Далее
                        </Btn>
                    </View>
                )}
            </Formik>
        </View>
    )
}
