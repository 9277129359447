import React from 'react'
import { YMaps, Map, Placemark } from 'react-yandex-maps'

export const CashboxMap = ({ response }) => {
    const { items } = response

    let markers = []

    let initialRegion = {
        latitude: 55.80666881834656,
        longitude: 38.986463677245965,
        latitudeDelta: 0.2,
        longitudeDelta: 0.2,
    }

    if (items)
        items.map((item) => {
            if (item.cashboxes) {
                item.cashboxes.map((cashbox) => {
                    if (cashbox.coords) {
                        let coords = cashbox.coords.split(', ')
                        let latitude = parseFloat(coords[0])
                        let longitude = parseFloat(coords[1])

                        if (latitude && longitude) {
                            markers.push({
                                title: cashbox.address,
                                // description: 'Тестовое описание',
                                latlng: { latitude, longitude },
                            })

                            // initialRegion.latitude = latitude
                            // initialRegion.longitude = longitude
                        }
                    }
                })
            }
        })

    return (
        <div className="cashbox-map">
            <YMaps
                query={{
                    apikey: `f811ea8d-c6aa-4243-bc26-9fc1038d6f18&lang=ru_RU`,
                    load: 'package.full',
                }}
                version={'2.1'}
            >
                <Map
                    defaultState={{
                        center: [
                            initialRegion.latitude,
                            initialRegion.longitude,
                        ],
                        zoom: 11,
                        controls: [],
                    }}
                >
                    {markers.map((marker, index) => (
                        <Placemark
                            key={index}
                            geometry={[
                                marker.latlng.latitude,
                                marker.latlng.longitude,
                            ]}
                            options={{
                                iconLayout: 'default#imageWithContent',
                                iconImageSize: [30, 43],
                                iconImageHref: '/images/map-pointer.png',
                                iconContentOffset: [-15, -22],
                            }}
                        />
                    ))}
                </Map>
            </YMaps>
        </div>
    )
}
