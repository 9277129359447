import React, { useState, useEffect } from 'react'
import { ActivityIndicator } from 'react-native'
import { NewsView } from '../../components/News/NewsView'
import api from '../../services/api'

export const NewsViewContainer = (props) => {
    const { match, route } = props
    const [response, setResponse] = useState({})
    const { id } = match ? match.params : route.params

    const fetch = () => {
        api.fetchById(`news`, id, {
            extend: 'preview_file',
        }).then((response) => {
            setResponse(response.data)
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    const { data } = response

    if (!data) return <ActivityIndicator color="#d21100" />
    return <NewsView data={data} {...props} />
}
