import React from 'react'
// import { FlatList } from 'react-native'
// import { style } from '../../styles/mainStyles'
import { CashboxCard } from './CashboxCard'

// const renderItem = ({ item }) => <CashboxCard item={item} />

export const CashboxList = ({ response }) => {
    const { items } = response

    return (
        <div
            className="box"
            style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        >
            {items.map((item, index) => (
                <CashboxCard key={index} item={item} />
            ))}
        </div>
    )

    // return (
    //     <div
    //         style={[
    //             style.box,
    //             { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
    //         ]}
    //     >
    //         {items.map((item, index) => (
    //             <CashboxCard key={index} item={item} />
    //         ))}
    //     </div>
    //     // <FlatList
    //     //     data={items}
    //     //     renderItem={renderItem}
    //     //     keyExtractor={(item) => item.id + ''}
    //     //     style={[
    //     //         style.box,
    //     //         { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
    //     //     ]}
    //     // />
    // )
}
