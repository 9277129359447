import React from 'react'
import { FaqCard } from './FaqCard'
import { useHistory } from 'react-router'

export const FaqList = ({ response }) => {
    const history = useHistory()
    const { items } = response

    return (
        <div>
            {items &&
                items.map((item) => (
                    <FaqCard
                        item={item}
                        key={item.id.toString()}
                        onPress={() => history.push(`/faq/${item.id}`)}
                    />
                ))}
        </div>
    )
}
