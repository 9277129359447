import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { CashboxContainer } from '../../containers/Cashbox/CashboxContainer'

export const CashboxPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader back="/contacts" title="Адреса участков" />
            <h1>Адреса участков</h1>
            <CashboxContainer {...props} />
        </div>
    )
}
