import React from 'react'
import { TicketsCard } from './TicketsCard'
import { useHistory } from 'react-router'
// import { SearchDropdown } from '../Elements/SearchDropdown'
// import { ticketStatuses } from '../../helpers/statusHelper'

// export const TicketsList = ({ response, loadMore, refreshing, onRefresh }) => {
export const TicketsList = ({ response }) => {
    const history = useHistory()

    const RenderItem = ({ item }) => {
        return (
            <TicketsCard
                item={item}
                onPress={() => history.push(`/tickets/${item.id}`)}
            />
        )
    }

    const items = response ? response.items : []

    // const ticketStatusesList = Object.keys(ticketStatuses).map(function (key) {
    //     return ticketStatuses[key]
    // })

    return (
        <div>
            {/* <div className="row mb-3">
                <div className="col-xl-4">
                    <SearchDropdown
                        label="Статус"
                        name="ticket_status_id"
                        onSubmit={onSearch}
                        items={ticketStatusesList}
                    />
                </div>
            </div> */}
            <div className="order-list">
                {items &&
                    items.map((item) => (
                        <RenderItem
                            key={item.id}
                            item={item}
                            history={history}
                        />
                    ))}
            </div>
        </div>
    )
}
