import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { TicketsViewContainer } from '../../containers/Tickets/TicketsViewContainer'

export const TicketsViewPage = (props) => {
    const { id } = props.match.params

    return (
        <div className="white-box">
            <PageHeader title={`Заявка №${id}`} back="/tickets" />
            <h1>Заявка №{id}</h1>
            <TicketsViewContainer {...props} />
        </div>
    )
}
