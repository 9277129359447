import * as React from 'react'
import { TicketsFormContainer } from '../../containers/Tickets/TicketsFormContainer'
import { useHistory } from 'react-router'
import { PageHeader } from '../../components/WebBlocks/PageHeader'

export function ServicesOrder({ id, data, option_id }) {
    const history = useHistory()

    const navigation = {
        navigate: () => {
            history.push('/tickets')
        },
    }

    // const pointsCount = 50

    let data_option = null
    const { unit_type, service_options } = data

    if (service_options && service_options.length && option_id) {
        data_option = service_options.filter(
            (opt) => opt.id === parseInt(option_id)
        )[0]
    }

    return (
        <div>
            <PageHeader
                title={`Заказ услуги ${data.title}`}
                back={`/services/${id}`}
            />

            <h1>{data.title}</h1>

            {data_option ? (
                <div></div>
            ) : (
                <div>
                    {data.old_per_unit_price ? (
                        <div className="mb-3">
                            <h5 className="mb-0 opacity-70 text-decoration-line-through">
                                {data.old_per_unit_price}₽
                            </h5>
                            <h4 className="text-danger mb-0">
                                от {data.per_unit_price}₽
                            </h4>
                            {unit_type && (
                                <small className="opacity-70">
                                    Стоимость за: {unit_type.title}
                                </small>
                            )}
                        </div>
                    ) : (
                        <div className="mb-3">
                            <h4 className="mb-0">от {data.per_unit_price}₽</h4>
                            {unit_type && (
                                <small className="opacity-70">
                                    Стоимость за: {unit_type.title}
                                </small>
                            )}
                        </div>
                    )}
                </div>
            )}

            <p>{data.description}</p>

            {/* <ul className="mb-4 list-group list-group-flush">
                <li className="list-group-item">
                    1 шт. от <b>{data.per_unit_price}₽</b>
                </li>
                <li className="list-group-item">
                    Вы получите{' '}
                    <b className="text-danger">+{pointsCount} баллов</b>
                </li>
            </ul> */}

            <hr className="mt-5 mb-5" />

            {data_option && (
                <div className="mb-5">
                    <h2>{data_option.name}</h2>
                    <div className="mb-3">
                        <h4 className="mb-0">{data_option.price}₽</h4>
                        {unit_type && (
                            <small className="opacity-70">
                                Стоимость за: {unit_type.title}
                            </small>
                        )}
                    </div>
                </div>
            )}

            <TicketsFormContainer
                service_id={id}
                service_option_id={option_id}
                navigation={navigation}
            />
        </div>
    )
}
