import React from 'react'
// import { ScrollView } from 'react-native'
// import { s } from '../../helpers/style'
import { NewsCard } from './NewsCard'
import { useHistory } from 'react-router'

export const NewsBlock = ({ items }) => {
    const history = useHistory()

    return (
        <div className="news-index">
            <h2 className="text-center">Новости</h2>

            {/* <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
            > */}
            <div className="news-list">
                {items &&
                    items.map((item, key) => (
                        // <View key={key}>
                        <NewsCard
                            key={key}
                            item={item}
                            onPress={() => history.push(`/news/${item.id}`)}
                        />
                        // </View>
                    ))}
            </div>

            {/* </ScrollView> */}
        </div>
    )
}
