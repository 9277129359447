import * as React from 'react'
import {
    Text,
    Pressable,
    TouchableOpacity,
    StyleSheet,
    Image,
} from 'react-native'
import { s } from '../helpers/style'
import { Icn } from './Elements/Icn'

import icnTrash from '../assets/icons/danger/trash.png'
import icnEdit from '../assets/icons/danger/edit.png'

export const Btn = ({
    onPress,
    children,
    color,
    icon,
    iconColor = 'white',
}) => (
    <TouchableOpacity
        activeOpacity={0.7}
        style={[styles.base, s.textCenter, color ? s[color] : s.btnSecondary]}
        onPress={onPress}
    >
        {icon && <Icn icon={icon} color={iconColor} />}

        <Text style={[styles.text, s.textWhite, icon ? styles.withIcon : '']}>
            {children}
        </Text>
    </TouchableOpacity>
)

export const BtnLink = ({ onPress, children, icon, iconColor = 'white' }) => (
    <Pressable style={[styles.base, s.textCenter]} onPress={onPress}>
        {icon && <Icn icon={icon} color={iconColor} />}

        <Text style={[styles.text, s.textDanger, icon ? styles.withIcon : '']}>
            {children}
        </Text>
    </Pressable>
)

export const BtnOutline = ({ onPress, children, size }) => (
    <Pressable
        style={[
            s.border,
            styles.baseOutline,
            s.borderDanger,
            s.textCenter,
            size ? styles[size] : {},
        ]}
        onPress={onPress}
    >
        <Text style={[styles.text, s.textDanger]}>{children}</Text>
    </Pressable>
)

export const BtnRemove = ({ onPress }) => (
    <Pressable onPress={onPress}>
        <Image style={styles.icon} source={icnTrash} />
    </Pressable>
)

export const BtnEdit = ({ onPress }) => (
    <Pressable onPress={onPress}>
        <Image style={styles.iconEdit} source={icnEdit} />
    </Pressable>
)

const styles = StyleSheet.create({
    base: {
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 57,
        borderRadius: 57,
        paddingHorizontal: 16,
        paddingVertical: 16,
        flexDirection: 'row',
    },
    baseOutline: {
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 31,
        borderRadius: 31,
        paddingHorizontal: 4,
        paddingVertical: 4,
        flexDirection: 'row',
        borderWidth: 1.2,
    },
    lg: {
        minHeight: 55,
        borderRadius: 55,
    },
    text: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    withIcon: {
        marginLeft: 5,
    },
    icon: {
        width: 18,
        height: 18,
    },
    iconEdit: {
        width: 15,
        height: 15,
    },
})
