import React from 'react'
import { AccountEditCard } from '../../components/Account/AccountEditCard'
import { ChangeFieldRow } from '../../components/WebBlocks/ChangeFieldRow'
import ChangePassword from '../../components/Profile/ChangePassword'
import Modal from 'react-modal'

export const ProfileEdit = ({
    accounts,
    changeFields,
    getProfile,
    onChangeDefaultAccount,
    isVisible,
    setIsVisible,
}) => {
    return (
        <div>
            {changeFields.map((item, index) => (
                <ChangeFieldRow key={index} item={item} />
                // <div key="index">{index}</div>
            ))}

            <div className="mt-3 mb-3 row">
                {accounts && accounts.length
                    ? accounts.map((item, index) => (
                          <div
                              className="col-xl-4 col-12 mb-3"
                              key={item.id.toString()}
                          >
                              <AccountEditCard
                                  onSubmit={(submitData) => {
                                      onChangeDefaultAccount(submitData)
                                      getProfile()
                                  }}
                                  item={item}
                                  index={index}
                              />
                          </div>
                      ))
                    : null}
            </div>

            <hr />
            <a
                onClick={() => setIsVisible(true)}
                className="text-center text-danger"
            >
                Изменить пароль
            </a>

            <Modal
                isOpen={isVisible}
                onRequestClose={() => setIsVisible(false)}
                ariaHideApp={false}
            >
                <div>
                    <a
                        onClick={() => setIsVisible(false)}
                        className="modal-close-link"
                    ></a>
                    <ChangePassword
                        onSubmit={() => {
                            setIsVisible(false)
                        }}
                    />
                </div>
            </Modal>
        </div>
    )
}
