import React from 'react'
import { View, Text } from 'react-native'
import { s } from '../../helpers/style'
import { style } from '../../styles/mainStyles'
import { DataTimeFormat } from '../../helpers/dataHelper'

export const TimingItem = ({ item }) => {
    const days = {
        mon: 'Пн',
        tue: 'Вт',
        wed: 'Ср',
        thu: 'Чт',
        fri: 'Пт',
        sat: 'Сб',
        sun: 'Вск',
    }

    const daysKeys = Object.keys(days)

    let list = daysKeys.map((d) => {
        if (!item[`is_${d}`]) return null

        var timeStart = DataTimeFormat(item[`${d}_start`]),
            timeEnd = DataTimeFormat(item[`${d}_end`]),
            dinnerStart = DataTimeFormat(item[`${d}_dinner_start`]),
            dinnerEnd = DataTimeFormat(item[`${d}_dinner_end`])

        let time = `с ${timeStart} по ${timeEnd}`
        let dinner = item[`has_${d}_dinner`]
            ? `${dinnerStart} – ${dinnerEnd}`
            : null

        return {
            day: days[d],
            time,
            dinner,
        }
    })

    list = list.filter((item) => item !== null)

    if (!list || !list.length) return null

    return (
        <View style={s.mb3}>
            <Text style={[style.h5, s.mb3]}>{item.title}:</Text>

            {list.map((t, indx) => (
                <View
                    key={indx}
                    style={[
                        { flex: 1, alignSelf: 'stretch', flexDirection: 'row' },
                        s.p3,
                        indx % 2 ? s.bgWhite : s.bgLight,
                    ]}
                >
                    <View
                        style={[
                            { flex: 1, alignSelf: 'stretch' },
                            s.pl1,
                            s.pr1,
                        ]}
                    >
                        <Text>{t.day}</Text>
                    </View>
                    <View
                        style={[
                            { flex: 3, alignSelf: 'stretch' },
                            s.pl1,
                            s.pr1,
                        ]}
                    >
                        <Text>{t.time}</Text>
                    </View>
                    <View
                        style={[
                            { flex: 3, alignSelf: 'stretch' },
                            s.pl1,
                            s.pr1,
                        ]}
                    >
                        <Text>обед {t.dinner}</Text>
                    </View>
                </View>
            ))}
        </View>
    )
}
