import React from 'react'

import Modal from 'react-modal'
import { TicketsFiles } from '../../components/Tickets/TicketsFiles'
import { Btn } from '../../components/Btn'
import { OpenPhone } from '../../helpers/linkHelper'
import { StarSelect } from '../../components/Elements/StarSelect'
import ReviewsForm from '../../components/Reviews/ReviewsForm'
import TicketsChangeForm from './TicketsChangeForm'
import { SwitchField } from '../Fields'
import { MarketplaceCard } from '../Marketplace/MarketplaceCard'
import { useHistory } from 'react-router-dom'
import { BtnEdit } from '../Btn'
import { TicketsCancelModal } from './TicketsCancelModal'
// import { TicketsFormContainer } from '../../containers/Tickets/TicketsFormContainer'

export const TicketsView = ({
    id,
    service,
    status,
    rate,
    setRate,
    modalReview,
    setModalReview,
    modalEdit,
    setModalEdit,
    data,
    // period,
    onPressPay,
    onPressPayMaster,
    onPressCancel,
    profile,
    reviews,
    agreePay,
    setAgreePay,
    startDate,
    endDate,
    onUpdate,
    setOrderId,
    cancelModal,
    setCancelModal,
    onPressConfirmation,
    onPressAct,
}) => {
    const review = reviews && reviews.length ? reviews[0] : null
    const { service_option, ticket_items } = data
    const paymentPrice = data.payment_price ? data.payment_price : data.sum
    const history = useHistory()

    const addTicketItem = () => {
        setOrderId(id)

        history.push('/services')
    }

    return (
        <div className="ticket-view">
            {service ? <h4 className="mb-0">{service.title}</h4> : null}

            {ticket_items ? (
                <div className="service-list mb-5">
                    {ticket_items.map((item) => (
                        <MarketplaceCard
                            key={item.id.toString()}
                            item={item?.service_option?.service}
                            price={item.per_unit_price}
                            quantity={item.unit_count}
                        />
                    ))}
                </div>
            ) : null}

            {/* Добавление услуги к заявке */}
            {status && status.canAddCart ? (
                <div className="mb-3">
                    <button
                        className="btn btn-danger"
                        onClick={() => addTicketItem()}
                    >
                        Добавить услугу
                    </button>
                </div>
            ) : null}

            {status && (
                <div className={`mt-3 order-card-status ${status.colorWeb}`}>
                    {/* <span className={`ellipse ${status.colorWeb}`}></span> */}
                    <b className={status.textWeb ? status.textWeb : ``}>
                        {status.title}
                    </b>
                </div>
            )}

            {status && status.canReview ? (
                <div className="row mt-4">
                    <div className="col-xl-4 col-12">
                        {review ? (
                            <div className="p-3 bg-light">
                                <small className="mb-1 opacity-50">
                                    {review.created_at}
                                </small>
                                <div>{review.text}</div>
                            </div>
                        ) : (
                            <div className="ticket-view-review">
                                <div className="mb-4">
                                    <StarSelect
                                        onChange={(val) => setRate(val)}
                                    />
                                </div>

                                <Btn
                                    icon="chat"
                                    color="bgDanger"
                                    onPress={() => setModalReview(true)}
                                >
                                    Оставить отзыв: {rate}
                                    {modalReview}
                                </Btn>

                                <Modal
                                    isOpen={modalReview}
                                    onRequestClose={() => setModalReview(false)}
                                >
                                    <div>
                                        <a
                                            onClick={() =>
                                                setModalReview(false)
                                            }
                                            className="modal-close-link"
                                        ></a>
                                        <ReviewsForm
                                            ticketId={id}
                                            rate={rate}
                                            onClose={(isUpdate = false) => {
                                                setModalReview(false)
                                                if (isUpdate) {
                                                    onUpdate()
                                                }
                                            }}
                                        />
                                    </div>
                                </Modal>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}

            <div className="mt-4 mb-4">
                <ul className="order-points-list list-group list-group-flush">
                    <li className="order-points-item list-group-item">
                        <div>
                            <p className="opacity-50 mb-3">
                                Обращаем ваше внимание, что во время работы
                                мастер может выявить скрытые повреждения, ремонт
                                которых может быть обязательным или
                                рекомендуемым. Если вы принимаете решение
                                устранить неполадки, то итоговая стоимость работ
                                изменится. Например, если при замене смесителя
                                выявляется неисправность в канализационной
                                системе, и ее важно устранить, то ремонт
                                канализационной системы обсуждается и
                                оплачивается отдельно. Мастер поможет дополнить
                                заказ или создать новый.
                            </p>
                            <SwitchField
                                value={agreePay}
                                label="Мне понятно"
                                onValueChange={(value) => setAgreePay(value)}
                            />
                        </div>
                    </li>
                    {service_option ? (
                        <li className="order-points-item list-group-item">
                            {service_option ? `${service_option.name} ` : ``}
                            {data.unit_count} шт.
                            <div className="order-points-price">
                                {/* <b>
                                {service_option
                                    ? service_option.price * data.unit_count
                                    : data.service.per_unit_price *
                                      data.unit_count}
                            </b>{' '} */}
                                руб.
                            </div>
                        </li>
                    ) : (
                        <li className="order-points-item list-group-item">
                            Услуг в заказе
                            <div className="order-points-price">
                                x
                                {ticket_items.reduce(
                                    (partialSum, a) =>
                                        partialSum + a.unit_count,
                                    0
                                )}
                            </div>
                        </li>
                    )}
                    <li className="order-points-item list-group-item">
                        <b>Стоимость без учета материалов:</b>

                        <div className="order-points-price">
                            {data.promocode_percent && (
                                <div className="text-decoration-line-through opacity-50">
                                    {(paymentPrice * 100) /
                                        (100 - data.promocode_percent)}{' '}
                                    руб.
                                </div>
                            )}
                            <b>{paymentPrice}</b> руб.
                        </div>
                    </li>
                </ul>
            </div>

            {/* <TicketsFormContainer order={data} /> */}

            <div className="row mb-3">
                <div className="col-12 col-md-6 col-xl-4">
                    <div className="mb-4">
                        <h5>Желаемая дата и время оказания:</h5>
                        <div className="order-start-date">
                            <p>
                                <i className="icn icn-calendar-danger"></i>
                                <b>{startDate}</b>
                            </p>

                            {/* Изменение даты и времени оказания услуги */}
                            {status && status.canEdit ? (
                                <div className="order-start-date-edit">
                                    <BtnEdit
                                        onPress={() => setModalEdit(true)}
                                    />

                                    <Modal
                                        isOpen={modalEdit}
                                        onRequestClose={() =>
                                            setModalEdit(false)
                                        }
                                    >
                                        <div>
                                            <a
                                                onClick={() =>
                                                    setModalEdit(false)
                                                }
                                                className="modal-close-link"
                                            ></a>

                                            <TicketsChangeForm
                                                ticketId={id}
                                                data={data}
                                                onClose={(isUpdate = false) => {
                                                    setModalEdit(false)
                                                    if (isUpdate) {
                                                        onUpdate()
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Modal>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                    <div className="mb-4">
                        <h5>Дата и время оказания услуги:</h5>
                        <p>
                            <i className="icn icn-calendar-danger"></i>
                            <b>{endDate}</b>
                        </p>
                    </div>
                </div>

                {data.address && (
                    <div className="col-12 col-md-6 col-xl-4">
                        <div className="mb-4">
                            <h5>Адрес оказания услуги</h5>
                            <p>
                                <b>{data.address.address}</b>
                            </p>
                            {data.city && (
                                <p className="opacity-75">{data.city.title}</p>
                            )}
                        </div>
                    </div>
                )}

                <div className="col-12 col-md-6 col-xl-4">
                    <div className="mb-4">
                        <h5>Заявитель</h5>
                        <p>
                            <b>{profile.name}</b>
                        </p>
                        <p onClick={() => OpenPhone(profile.phone)}>
                            <i className="icn icn-phone-danger"></i>
                            <b>{profile.phone}</b>
                        </p>
                    </div>
                </div>

                {data.master ? (
                    <div className="col-12 col-md-6 col-xl-4">
                        <div className="mb-4">
                            <h5>Мастер</h5>
                            <p>
                                <b>{`${data.master.second_name} ${data.master.first_name}`}</b>
                            </p>
                        </div>
                    </div>
                ) : null}

                {data.contact_phone !== profile.phone && (
                    <div className="col-12 col-md-6 col-xl-4">
                        <div className="mb-4">
                            <h5>Контактное лицо</h5>
                            <p>
                                <b>{data.contact_name}</b>
                            </p>
                            <p onClick={() => OpenPhone(data.contact_phone)}>
                                <i className="icn icn-phone-danger"></i>
                                <b>{data.contact_phone}</b>
                            </p>
                        </div>
                    </div>
                )}
            </div>

            <div className="mb-4">
                <h5>Подробное описание</h5>
                <p>{data.description}</p>
            </div>

            <div className="mb-4">
                <TicketsFiles ticketId={data.id} />
            </div>

            {/* Оплата заявки */}
            {status && status.canPay ? (
                <div className="mb-4 row">
                    <div className="col-6 col-xl-4">
                        <Btn
                            onPress={() => onPressPay(data.id)}
                            color="bgDanger"
                        >
                            Оплатить
                        </Btn>
                    </div>
                </div>
            ) : null}

            {/* Оплата выезда мастера */}
            {status && status.canPayMaster ? (
                <div className="mb-4 row">
                    <div className="col-6 col-xl-4">
                        <Btn
                            onPress={() => onPressPayMaster(data.id)}
                            color="bgDanger"
                        >
                            Оплатить выезд мастера
                        </Btn>
                    </div>
                </div>
            ) : null}

            {/* Подтвердить работы */}
            {status && status.canConfirmation ? (
                <div className="mb-4 row">
                    <div className="col-6 col-xl-4">
                        <Btn
                            onPress={() => onPressConfirmation(data.id)}
                            color="bgSuccess"
                        >
                            Подтвердить работы
                        </Btn>
                    </div>
                </div>
            ) : null}

            {/* Посмотреть акт */}
            {status && status.canAct ? (
                <div className="mb-4 row">
                    <div className="col-6 col-xl-4">
                        <Btn
                            onPress={() => onPressAct(data.id)}
                            color="bgPrimary"
                        >
                            Скачать акт
                        </Btn>
                    </div>
                </div>
            ) : null}

            {/* Отмена заявки */}
            {status && status.canCancel ? (
                <div className="mb-4 row">
                    <div className="col-6 col-xl-4">
                        <Btn
                            onPress={() => onPressCancel(data.id)}
                            color="bgDark"
                            icon2="close-outline"
                            textColor="textDark"
                            iconColor="dark"
                        >
                            Отменить заявку
                        </Btn>
                    </div>

                    <Modal
                        isOpen={cancelModal}
                        onRequestClose={() => setCancelModal(false)}
                    >
                        <TicketsCancelModal />
                    </Modal>
                </div>
            ) : null}
        </div>
    )
}
