import React, { useEffect, useRef } from 'react'
// import { View, ScrollView } from 'react-native'
// import { s } from '../../helpers/style'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { MarketplaceCategoryCard } from './MarketplaceCategoryCard'

import Swiper from 'swiper'
import 'swiper/swiper.min.css'

export const MarketplaceBlock = ({ items }) => {
    const history = useHistory()
    const swiper = useRef(null)
    useEffect(() => {
        swiper.current = new Swiper('.swiper-container', {
            slidesPerView: 'auto',
            spaceBetween: 20,
        })
    }, [])

    return (
        <div className="">
            <h1 className="text-center">Витрина услуг</h1>

            <div className="mb-4 service-category-block">
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        {items &&
                            items.map((item, key) => (
                                <div key={key} className="swiper-slide">
                                    <MarketplaceCategoryCard
                                        item={item}
                                        itemKey={key}
                                        key={key}
                                        onPress={() => {
                                            history.push(
                                                `/services/category/${item.id}`
                                            )
                                        }}
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            {/* <div className="mb-4 custom-scroll">
                <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={true}
                >
                    {items &&
                        items.map((item, key) => (
                            <View key={key} style={s.pr3}>
                                <MarketplaceCategoryCard
                                    item={item}
                                    itemKey={key}
                                    key={key}
                                    onPress={() => {
                                        history.push(
                                            `/services/category/${item.id}`
                                        )
                                    }}
                                />
                            </View>
                        ))}
                </ScrollView>
            </div> */}

            <div className="text-center">
                <NavLink to="/services" className="btn btn-lg btn-danger">
                    Посмотреть все услуги
                </NavLink>
            </div>
        </div>
    )
}
