import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { TicketsContainer } from '../../containers/Tickets/TicketsContainer'

export const TicketsPage = (props) => {
    return (
        <div className="white-box">
            <h1>Заявки</h1>
            <PageHeader title="Заявки" />
            <TicketsContainer {...props} />
        </div>
    )
}
