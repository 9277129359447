import React from 'react'
import { connect } from 'react-redux'

export const FooterComponent = () => {
    return (
        <div className="footer">
            <p>
                СКАЧАЙТЕ ПРИЛОЖЕНИЕ KIMI
                <br />В приложении вы можете заказать 1000+ услуг. От мала до
                велика. Установите сейчас.
            </p>
            {/* <a
                href="http://ui.nks.rdbx24.ru/app/kimi-f4861b1e98664908bd715efafb90f363-signed.apk"
                target="_blank"
                rel="noreferrer"
                className="footer-badge"
            >
                <img src="/images/google-play-badge.png" alt="" />
            </a> */}
            <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.uslugidom.kimi"
                className="footer-badge"
                rel="noreferrer"
            >
                <img src="/images/google-play-badge.png" alt="" />
            </a>
            <a
                target="_blank"
                href="https://apps.apple.com/ru/app/kimi-%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8-%D0%B4%D0%BB%D1%8F-%D0%B4%D0%BE%D0%BC%D0%B0/id1590111903"
                className="footer-badge"
                rel="noreferrer"
            >
                <img src="/images/app-store-badge.png" alt="" />
            </a>
        </div>
    )
}

let mapStateToProps = (state) => ({
    company: state.user.company,
})

let mapDispatchToProps = () => {
    return {}
}
const Footer = connect(mapStateToProps, mapDispatchToProps)(FooterComponent)
export { Footer }
