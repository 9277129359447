import React from 'react'
// import { MapBlockContainer } from '../../containers/Services/MapBlockContainer'
import { ServicesContainer } from '../../containers/Services/ServicesContainer'

export const ServicesPage = (props) => {
    return (
        <div className="white-box">
            <h1>Витрина услуг</h1>
            {/* <div className="mb-4">
                <MapBlockContainer />
            </div> */}
            <ServicesContainer {...props} />
        </div>
    )
}
