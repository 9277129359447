import * as React from 'react'
import { View } from 'react-native'
import { s } from '../../helpers/style'
// import { style } from '../../styles/mainStyles'
// import { Icn } from '../Elements/Icn'

const Item = ({ item }) => {
    return (
        <div className="cashbox-card">
            <i className="fa-solid fa-location-dot text-danger"></i>
            <p>{item.address}</p>
        </div>
        // <Pressable
        //     style={[
        //         style['custom-list-item'],
        //         style.row,
        //         s.justifyContentBetween,
        //         s.mt3,
        //     ]}
        // >
        //     <Text style={[s.fontWeightBold]}>{item.address}</Text>
        //     <Icn icon="pointer2" color="danger"></Icn>
        // </Pressable>
    )
}

export const CashboxCard = ({ item }) => {
    const { cashboxes } = item

    return (
        <View style={s.mb4}>
            {/* <Text style={[s.h4, style.h4]}>
                {item.title ? item.title : item.address}
            </Text> */}

            <h5>
                <b>{item.title ? item.title : item.address}</b>
            </h5>

            {cashboxes &&
                cashboxes.map((item, index) => (
                    <Item key={index} item={item} />
                ))}
        </View>
    )
}
