import * as React from 'react'
import moment from 'moment'
import { getTicketStatusById } from '../../helpers/statusHelper'
import { NavLink } from 'react-router-dom'

export const TicketsCard = ({ item, onPress }) => {
    const status = getTicketStatusById(item.ticket_status_id)

    return (
        <div className="order-card" onClick={onPress}>
            <div
                className={`order-card-bg ${status ? status.colorWeb : ``}`}
            ></div>

            <NavLink
                to={`/tickets/${item.id}`}
                className="order-card-link"
            ></NavLink>

            <div className="order-card-header">
                <h5>Заявка №{item.id}</h5>
                <span className="order-card-date">
                    {moment(item.created_at).format('DD.MM.YYYY, H:mm')}
                </span>
            </div>

            <div className="order-card-body">
                {item.service ? <p>{item.service.title}</p> : null}

                {item.address && (
                    <p className="opacity-70">{item.address.address}</p>
                )}
            </div>

            <div className="order-card-footer">
                {status ? (
                    <div className={`order-card-status ${status.colorWeb}`}>
                        {/* <span className={`ellipse ${status.colorWeb}`}></span> */}
                        <b className={status.textWeb ? status.textWeb : ``}>
                            {status.title}
                        </b>
                    </div>
                ) : null}
            </div>
        </div>
    )
}
