import { SET_CART, SET_FILTERS, SET_ORDER_ID } from './types/OrderTypes'

const INITIAL_STATE = {
    cart: [],
    filters: {},
    orderId: null,
}

const orderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CART:
            return {
                ...state,
                cart: action.payload,
            }

        case SET_FILTERS:
            return {
                ...state,
                filters: action.payload,
            }

        case SET_ORDER_ID:
            return {
                ...state,
                orderId: action.payload,
            }

        default:
            return state
    }
}

export default orderReducer
