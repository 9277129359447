import React, { useState, useEffect } from 'react'
import api from '../../services/api'
import { FaqList } from '../../components/Faq/FaqList'

import getEnv from '../../helpers/envHelper'
const { REACT_APP_PAGE_SIZE } = getEnv()

export const FaqContainer = () => {
    const [response, setResponse] = useState({})

    const fetch = () => {
        api.fetch(`faqs`, {
            'paginator[limit]': REACT_APP_PAGE_SIZE,
            'paginator[page]': 1,
            // 'extend' : 'preview_file'
        }).then((response) => {
            setResponse(response.data)
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    return <FaqList response={response} />
}
