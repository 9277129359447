import React from 'react'
import { useHistory } from 'react-router-dom'
import { MarketplaceCard } from '../Marketplace/MarketplaceCard'
import { BannerMarketplace } from '../../components/Banner/BannerMarketplace'
import { Search } from '../../components/Elements/Search'

export const MarketplaceList = ({ category, response, onSearch }) => {
    const history = useHistory()
    const { items } = response

    return (
        <div>
            <h4>{category.title}</h4>

            <div className="mb-4 search-block">
                <Search onSubmit={onSearch} placeholder="Выгул собак" />
            </div>

            <div className="mb-4 row">
                <div className="col-12">
                    <BannerMarketplace />
                </div>
            </div>

            <div className="service-list">
                {items &&
                    items.map((item) => (
                        <MarketplaceCard
                            key={item.id.toString()}
                            item={item}
                            onPress={() => history.push(`/services/${item.id}`)}
                        />
                    ))}
            </div>
        </div>
    )
}
