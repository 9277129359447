import * as React from 'react'
import { Image, StyleSheet } from 'react-native'
import { s } from '../../helpers/style'
import { icons } from '../../helpers/icons'
import { View } from 'react-native'

export const Icn = ({
    icon = 'question',
    color = 'white',
    size = 'base',
    background,
}) => {
    let icnPath = icons[color][icon]

    if (typeof icnPath === 'object') {
        icnPath = icnPath.default
    }

    if (background) {
        return (
            <View style={[styles.bg, s[background]]}>
                <Image style={styles[size]} source={icnPath} />
            </View>
        )
    }

    return <Image style={styles[size]} source={icnPath} />
}

// export const IcnEllipse = ({ color = 'bgWhite', size = 'base' }) => {
//     return (
//         <Image
//             style={styles[size]}
//             source={icnPath}
//         />
//     );
// };

const styles = StyleSheet.create({
    base: {
        width: 18,
        height: 18,
    },
    sm: {
        width: 14,
        height: 14,
    },
    tab: {
        width: 21,
        height: 21,
        marginBottom: 3,
    },
    lg: {
        width: 18 * 1.5,
        height: 18 * 1.5,
    },
    bg: {
        padding: 8,
        borderRadius: 600,
    },
})
