import * as React from 'react'
import api from '../../services/api'
// import { SwitchField } from '../Fields'

export const AccountEditCard = ({ item, onSubmit }) => {
    const onSubmitRemove = () => {
        if (confirm('Вы точно хотите удалить адрес?')) {
            // api.post(`/me/detach-account`, {
            //     account_number: item.id,
            // }).then(() => {
            //     onSubmit()
            // })

            api.delete(`/me/addresses/${item.id}`).finally(() => {
                onSubmit()
            })
        }
    }

    return (
        <div className="card-address">
            <div className="row mb-3">
                <div className="col-6">
                    {/* {item.account_id && (
                        <small className="text-opacity">
                            Л/С {item.account_id ? item.account_id : `-`}
                        </small>
                    )} */}

                    {/* {!item.account_id && item.city && ( */}
                    {item.city && (
                        <small className="text-opacity">
                            {item.city.title}
                        </small>
                    )}
                </div>
                <div className="col-3">
                    {item.is_default && (
                        <span className="badge-default">Основной</span>
                    )}
                </div>
                <div className="col-3 text-end">
                    {/* <i
                        className="fa-solid fa-trash-can text-danger"
                        onClick={onSubmitRemove}
                    ></i> */}

                    <i
                        className="icn icn-trash-danger"
                        onClick={onSubmitRemove}
                    ></i>
                </div>
            </div>

            <div className="card-address-header">
                <h5 className="card-address-title">{item.address}</h5>
            </div>
            {/* <div className="card-address-body">
            <small className="text-opacity">Л/С {item.id}</small>
        </div> */}
            {/* <div className="card-address-footer row">
                <div className="col-auto text-opacity">
                    <i className="icn icn-fire"></i>0 ГВС
                </div>
                <div className="col-auto text-opacity">
                    <i className="icn icn-water"></i>0 ХВС
                </div>
            </div> */}

            <div className="card-address-footer">
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value={item.id}
                        defaultChecked={item.is_default ? true : false}
                        checked={item.is_default ? true : false}
                        id={`isMainAddress${item.id}`}
                        onChange={(event) => {
                            onSubmit({
                                default_address_id: event.target.checked
                                    ? event.target.value
                                    : null,
                            })
                        }}
                    />
                    <label
                        className="form-check-label"
                        htmlFor={`isMainAddress${item.id}`}
                    >
                        Основной адрес
                    </label>
                </div>
            </div>
        </div>
    )
}
