import axios from 'axios'
import qs from 'qs'

// import AsyncStorage from '@react-native-async-storage/async-storage'
// import { createNavigationContainerRef } from '@react-navigation/native'

import AsyncStorage from '../services/storage'
import { createNavigationContainerRef } from './navigation'

import { authUser } from '../store/actions/UserActions'
import { store } from '../App'

const baseURL = `https://api.uslugi-dom.ru/api`
// const baseURL = `https://api.uslugidom.com/api`
// const baseURL = `https://api.nks.rdbx.dev/api`
const apiVersion = `1.0`
const apiEnv = `cabinet`

const navigationRef = createNavigationContainerRef()

const makeRequest = (isUpload = false) => {
    let headers = {
        'x-api-version': apiVersion,
        'x-api-environment': apiEnv,
    }

    if (isUpload) headers['Content-Type'] = 'multipart/form-data'

    // const token = await AsyncStorage.getItem('@access_token')
    // const token = localStorage.getItem('access_token');

    // if (token)
    //     headers['Authorization'] = `Bearer ${token}`;

    const instance = axios.create({
        baseURL: baseURL,
        headers,
    })

    instance.interceptors.request.use(
        async (config) => {
            const token = await AsyncStorage.getItem('@access_token')
            if (token) config.headers.Authorization = 'Bearer ' + token
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    instance.interceptors.response.use(
        (response) => {
            return response
        },
        async (error) => {
            let status = error.response?.status

            // if (error.response?.data?.errors) {
            //     const { message } = error.response.data.errors
            //     if (message && message === 'Wrong number of segments') {
            //         status = 403
            //     }
            // }

            if (status && (status === 403 || status === 401)) {
                AsyncStorage.removeItem('@access_token')

                if (navigationRef.isReady()) navigationRef.navigate('Auth')
                else {
                    await store.dispatch(authUser(null))
                    window.location.href = '/#/auth/login'
                }
            }

            return Promise.reject(error)
        }
    )

    return instance
}

const makeRequestRefresh = (isUpload = false) => {
    let headers = {
        'x-api-version': apiVersion,
        'x-api-environment': apiEnv,
    }

    if (isUpload) headers['Content-Type'] = 'multipart/form-data'

    const instance = axios.create({
        baseURL: baseURL,
        headers,
    })

    instance.interceptors.request.use(
        async (config) => {
            const token = await AsyncStorage.getItem('@refresh_token')
            if (token) config.headers.Authorization = 'Bearer ' + token
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    return instance
}

export default {
    fetch: async (type, params) =>
        await makeRequest().get(`/${type}`, {
            params,
        }),
    fetchById: async (type, id, params) =>
        await makeRequest().get(`/${type}/${id}`, {
            params,
        }),
    login: async (data, params) =>
        await makeRequest().post(`/auth/login/jwt`, qs.stringify(data), {
            params,
        }),
    loginRefresh: async (data, params) =>
        await makeRequestRefresh().post(
            `/auth/login/jwt/refresh`,
            qs.stringify(data),
            {
                params,
            }
        ),
    logout: async (data, params) =>
        await makeRequest().post(`/auth/logout`, qs.stringify(data), {
            params,
        }),
    remember: async (data, params) =>
        await makeRequest().post(`/auth/remember`, qs.stringify(data), {
            params,
        }),
    registration: async (data, params) =>
        await makeRequest().post(`/auth/registration`, qs.stringify(data), {
            params,
        }),
    profile: async (params) =>
        await makeRequest().get(`/me`, {
            params: {
                extend: 'new_notifications_count,default_address,addresses,addresses.account,addresses.account.house,addresses.city,is_accept_push',
                ...params,
            },
        }),
    get: async (url, params) =>
        await makeRequest().get(`${url}`, {
            params,
        }),
    post: async (url, data, params) =>
        await makeRequest().post(`${url}`, qs.stringify(data), {
            params,
        }),
    put: async (url, data, params) =>
        await makeRequest().put(`${url}`, qs.stringify(data), {
            params,
        }),
    patch: async (url, data, params) =>
        await makeRequest().patch(`${url}`, qs.stringify(data), {
            params,
        }),
    delete: async (url, data, params) =>
        await makeRequest().delete(`${url}`, qs.stringify(data), {
            params,
        }),
    upload: async (data, params) =>
        await makeRequest(true).post(`/tools/upload-files`, data, {
            params,
        }),
}

// import axios from 'axios'
// import qs from 'qs'
// import AsyncStorage from '@react-native-async-storage/async-storage'
// import { createNavigationContainerRef } from '@react-navigation/native'
// import { authUser } from '../store/actions/UserActions'
// import { store } from '../App'

// const baseURL = process.env.REACT_APP_API // eslint-disable-line
// const apiVersion = `1.0`
// const apiEnv = `cabinet`

// const navigationRef = createNavigationContainerRef()

// const makeRequest = (isUpload = false) => {
//     let headers = {
//         'x-api-version': apiVersion,
//         'x-api-environment': apiEnv,
//     }

//     if (isUpload) headers['Content-Type'] = 'multipart/form-data'

//     // const token = await AsyncStorage.getItem('@access_token')
//     // const token = localStorage.getItem('access_token');

//     // if (token)
//     //     headers['Authorization'] = `Bearer ${token}`;

//     const instance = axios.create({
//         baseURL: baseURL,
//         headers,
//     })

//     instance.interceptors.request.use(
//         async (config) => {
//             const token = await AsyncStorage.getItem('@access_token')
//             if (token) config.headers.Authorization = 'Bearer ' + token
//             return config
//         },
//         (error) => {
//             return Promise.reject(error)
//         }
//     )

//     instance.interceptors.response.use(
//         (response) => {
//             return response
//         },
//         async (error) => {
//             let status = error.response.status

//             if (error.response.data.errors) {
//                 const { message } = error.response.data.errors
//                 if (message && message === 'Wrong number of segments') {
//                     status = 401
//                 }
//             }

//             if (status === 403 || status === 401) {
//                 await AsyncStorage.removeItem('@access_token')
//                 if (navigationRef.isReady()) navigationRef.navigate('Auth')
// else {
//     await store.dispatch(authUser(null))
//     // window.location.href = '/#/auth/login'
// }
//             }
//             return Promise.reject(error)
//         }
//     )

//     return instance
// }

// // eslint-disable-next-line
// export default {
//     fetch: async (type, params) =>
//         await makeRequest().get(`/${type}`, {
//             params,
//         }),
//     fetchById: async (type, id, params) =>
//         await makeRequest().get(`/${type}/${id}`, {
//             params,
//         }),
//     login: async (data, params) =>
//         await makeRequest().post(`/auth/login/jwt`, qs.stringify(data), {
//             params,
//         }),
//     logout: async (data, params) =>
//         await makeRequest().post(`/auth/logout`, qs.stringify(data), {
//             params,
//         }),
//     remember: async (data, params) =>
//         await makeRequest().post(`/auth/remember`, qs.stringify(data), {
//             params,
//         }),
//     registration: async (data, params) =>
//         await makeRequest().post(`/auth/registration`, qs.stringify(data), {
//             params,
//         }),
//     get: async (url, params) =>
//         await makeRequest().get(`${url}`, {
//             params,
//         }),
//     post: async (url, data, params) =>
//         await makeRequest().post(`${url}`, qs.stringify(data), {
//             params,
//         }),
//     put: async (url, data, params) =>
//         await makeRequest().put(`${url}`, qs.stringify(data), {
//             params,
//         }),
//     upload: async (data, params) =>
//         await makeRequest(true).post(`/tools/upload-files`, data, {
//             params,
//         }),
// }
