import React, { useState, useEffect } from 'react'
import { ActivityIndicator } from 'react-native'
import { ServicesView } from '../../components/Services/ServicesView'
import api from '../../services/api'
import { connect } from 'react-redux'
import { addToCart, getCart } from '../../store/actions/CartActions'
// import { ErrorAlert } from '../../helpers/apiMessageHelper'

const Container = (props) => {
    const { match, route, addToCartStore, orderId, addToOrder, getCart } = props
    const [response, setResponse] = useState({})
    const { id } = match ? match.params : route.params

    const fetch = () => {
        api.fetchById(`services`, id, {
            extend: 'preview_file,service_category,unit_type,rate,rate_count,service_options',
        }).then((resp) => {
            setResponse(resp.data)
        })
    }

    const addToCart = async (payload, onSubmitSuccess) => {
        payload = Array.isArray(payload) ? payload : [payload]

        if (orderId) {
            payload.map(async (option) => {
                await addToOrder(orderId, { ...option })
            })

            if (onSubmitSuccess) {
                onSubmitSuccess()
            }
        } else {
            payload.map((option) => {
                addToCartStore({
                    ...option,
                    title: option.name,
                    short_description: response?.data?.short_description,
                    preview_file: response?.data?.preview_file,
                })
            })
            getCart()
        }
    }

    useEffect(() => {
        fetch()
    }, [])

    const { data } = response

    if (!data) return <ActivityIndicator size="large" color="#d21100" />

    return <ServicesView data={data} addToCart={addToCart} {...props} />
}

let mapStateToProps = (state) => ({
    orderId: state.order.orderId,
    basket: state.cart.basket,
})

let mapDispatchToProps = (dispatch) => {
    return {
        getCart: () => {
            dispatch(getCart())
        },
        addToCartStore: (item) => {
            dispatch(addToCart(item))
        },
        addToOrder: (orderId, item) => {
            api.post(`tickets/${orderId}/items`, {
                service_option_id: item.id,
                unit_count: 1,
            })
        },
    }
}

export const ServicesViewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Container)
