import * as React from 'react'

const colors = [
    '#FFEDE9',
    '#FFF4E0',
    '#FFE7DE',
    '#DAE1F5',
    '#F4ECEB',
    '#F4F7FF',
]

export const MarketplaceCategoryCard = ({
    item,
    size,
    itemKey,
    color,
    onPress,
}) => {
    color = '#f6f6f7'

    return (
        <div className="service-category" onClick={onPress}>
            <div
                className="service-category-preview"
                style={{ backgroundColor: color ? color : colors[itemKey] }}
            >
                {item.preview_file ? (
                    <div
                        className="service-category-img"
                        style={{
                            backgroundImage: `url(${item.preview_file.path})`,
                        }}
                    >
                        {/* {item.preview_file && (
                            <img src={item.preview_file.path} alt="" />
                        )} */}
                    </div>
                ) : null}

                <div className="service-category-title">{item.title}</div>

                {size === 'lg' && (
                    <div className="service-category-caption">
                        <b>{item.base_price}₽</b>
                    </div>
                )}

                {size === 'lg' && (
                    <div className="service-category-count">
                        {item.service_count ? item.service_count : 0}
                    </div>
                )}
            </div>
        </div>
    )
}
