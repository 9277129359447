import React from 'react'
import { CashboxList } from '../../components/Cashbox/CashboxList'
import { CashboxMap } from '../../components/Cashbox/CashboxMap'

export const CashboxView = ({ response, tab, setTab }) => {
    return (
        <div>
            <div className="mb-4">
                <ul className="nav nav-pills nav-fill">
                    <li className="nav-item">
                        <a
                            className={`nav-link ${tab === 0 ? 'active' : ''}`}
                            onClick={() => setTab(0)}
                        >
                            Список
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${tab === 1 ? 'active' : ''}`}
                            onClick={() => setTab(1)}
                        >
                            На карте
                        </a>
                    </li>
                </ul>
            </div>

            {tab === 0 && <CashboxList response={response} />}
            {tab === 1 && <CashboxMap response={response} />}
        </div>
    )
}
