import React from 'react'

export const ExternalLinks = ({ items }) => {
    // const items = [
    //     {
    //         title: 'Реквизиты',
    //         link: 'https://uslugidom.com/requisites',
    //     },
    //     {
    //         title: 'Условия оплаты',
    //         link: 'https://uslugidom.com/pay',
    //     },
    //     {
    //         title: 'Политика конфиденциальности',
    //         link: 'https://uslugidom.com/privacy',
    //     },
    // ]

    if (!items || !items.length) {
        return <div></div>
    }

    return (
        <ul className="custom-list">
            {items.map((item, index) => (
                <li key={index} className="custom-list-item">
                    <a
                        href={item.link}
                        target="_blank"
                        className="custom-list-link"
                        rel="noreferrer"
                    >
                        {item.title}
                        <i className="fa-solid fa-up-right-from-square"></i>
                    </a>
                </li>
            ))}
        </ul>
    )
}
