import React from 'react'
import { PageHeader } from '../../components/WebBlocks/PageHeader'
import { ManagmentContainer } from '../../containers/Contacts/ManagmentContainer'

export const ManagmentPage = (props) => {
    return (
        <div className="white-box">
            <PageHeader back="/contacts" title="Режим работы" />
            <h1>Режим работы</h1>
            <ManagmentContainer {...props} />
        </div>
    )
}
